<template>
  <div class="c-footer-old" :class="rootClasses" v-if="fields">
    <div class="c-footer-old__subscribe">
      <jss-rich-text class="c-footer-old__subscribe-title" :field="fields.subscribeTitle" tag="div" />
      <jss-rich-text class="c-footer-old__subscribe-body" :field="fields.subscribeBody" tag="div" />
      <site-button v-bind="fields.subscribeButton" />
    </div>
    <div class="c-footer-old__categories">
      <div class="c-footer-old__category" v-for="cate in fields.categories" :key="cate.id">
        <div class="c-footer-old__category-header" @click="toggleCategory(cate)">
          <jss-text class="c-footer-old__category-title" :field="cate.fields.title" tag="div" />
          <accordion-toggle :active="openedIds.includes(cate.id)" />
        </div>
        <div class="c-footer-old__menus" :class="[{ 'two-columns': cate.fields.towColumns.value, active: openedIds.includes(cate.id) }]">
          <div class="c-footer-old__menu" v-for="menu in cate.fields.menus" :key="menu.id">
            <site-link :link="menu.fields.link" />
          </div>
        </div>
      </div>
    </div>
    <div class="c-footer-old__region">
      <icon name="location" size="small" @click="openRegionModal" />
      <div class="c-footer-old__region-name" @click="openRegionModal">
        <span class="c-footer-old__region-area">Global</span>
        <span class="c-footer-old__region-lang">EN</span>
      </div>
    </div>
    <div class="c-footer-old__social-medias">
      <social-medias :items="fields.socialMedias" />
    </div>
    <jss-text class="c-footer-old__copyright" :field="fields.copyright" tag="div" />
    <div></div>
  </div>
</template>

<script>
/**
 * @typedef FooterFields
 * @property {GlobalSettingEntry} theme
 * @property {Array<FooterCategory>} categories
 * @property {Array<SocialMedia>} socialMedias
 * @property {SimpleField} subscribeTitle
 * @property {SimpleField} subscribeBody
 * @property {ButtonField} subscribeButton
 * @property {SimpleField} copyright
 * */
/**
 * @typedef FooterCategory
 * @property {{
 *   title: SimpleField,
 *   menus: Array<FooterMenu>,
 *   towColumns: CheckField
 * }} fields
 * */
/**
 * @typedef FooterMenu
 * @property {{
 *   link: LinkField
 * }} fields
 * */
import { computed, inject, reactive, toRefs } from 'vue';
import { themeClass } from '@/utils/site-utils';
import bus from '@/utils/bus';
import BUS_EVENTS from '@/utils/bus-events';
export default {
  name: 'Footer',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const $bus = inject('$bus');
    const state = reactive({
      openedIds: []
    });
    const computes = {
      rootClasses: computed(() => [themeClass(props, 'night-blue')])
    };
    const methods = {
      toggleCategory(cate) {
        const index = state.openedIds.findIndex((x) => x === cate.id);
        if (index >= 0) {
          state.openedIds.splice(index, 1);
        } else {
          state.openedIds.push(cate.id);
        }
      },
      openRegionModal() {
        $bus.emit(BUS_EVENTS.OPEN_REGION_MODAL);
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-footer-old {
  $c: &;
  @include component-themes;
  padding: grid-width(2);
  &__subscribe {
    &-title {
      @include h2;
    }
    &-body {
      @include h9;
      margin-top: 20px;
    }
    .e-site-button {
      margin-top: 20px;
    }
  }
  &__category {
    margin-top: 20px;
    &-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-title {
      @include h6;
    }
  }
  &__menus {
    margin-top: 20px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s linear;
    &.active {
      max-height: 800px;
    }
  }
  &__menu {
    + #{$c}__menu {
      margin-top: 10px;
    }
  }
  &__region {
    margin-top: grid-width(2);
    display: flex;
    align-items: center;
    .e-icon {
      cursor: pointer;
    }
    &-name {
      @include h6;
      line-height: 1;
      margin-left: 20px;
      display: flex;
      cursor: pointer;
    }
    &-area {
      position: relative;
      padding-right: 10px;
      margin-right: 10px;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 15px;
        width: 1px;
        background: currentColor;
      }
    }
  }
  &__social-medias {
    margin-top: grid-width(1);
  }
  &__copyright {
    border-top: 1px solid currentColor;
    padding: $space-18 $space-30;
    margin-top: grid-width(2);
  }
  @include desktop {
    @include grid-container;
    padding: grid-width(2) 0 0;
    &__subscribe {
      @include grid-block(4, 6, 1);
    }
    &__categories {
      @include grid-block(11, 12, 1, 2);
      margin-top: grid-width(2);
      display: flex;
      flex-wrap: wrap;
    }
    &__category {
      &-header {
        .e-accordion-toggle {
          display: none;
        }
      }
    }
    &__menus {
      width: toRem(200);
      overflow: visible;
      max-height: unset;
      transition: none;
      &.active {
        max-height: unset;
      }
      &.two-columns {
        width: toRem(420);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: toRem(20);
        grid-row-gap: 10px;
        #{$c}__menu {
          + #{$c}__menu {
            margin-top: 0;
          }
        }
      }
    }
    &__region {
      @include grid-block(4, 6, 2);
    }
    &__social-medias {
      @include grid-block(4, 6, 3);
      margin-top: grid-width(0.25);
    }
    &__copyright {
      @include grid-block(3, 20, 4);
    }
  }
}
</style>
