<template>
  <div class="c-gradient-background" :class="[{ 'with-animation': !fields.static.value }]" ref="rootEl">
    <placeholder :rendering="rendering" name="gradient-background" />
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import { settingValue } from '@/utils/site-utils';
import { isGrey, isSimilarColor, mixColor, reverseColor } from '@/utils/colors-utils';
/**
 * @typedef GradientBackgroundFields
 * @property {CheckField} static
 * */
export default {
  name: 'GradientBackground',
  props: {
    /**@type GradientBackgroundFields*/
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  setup(props) {
    let scrollListened = false;
    const themes = {
      white: {
        bgColor: '#ffffff',
        color: '#000000'
      },
      black: {
        bgColor: '#000000',
        color: '#ffffff'
      },
      yellow: {
        bgColor: '#fdef00',
        color: '#000000'
      }
    };
    const state = reactive({
      /**@type HTMLDivElement*/
      rootEl: null
    });
    const _methods = {
      onScroll(e) {
        _methods.calculateBackground();
      },
      onResize(e) {
        _methods.calculateBackground();
      },
      calculateBackground() {
        const componentEls = state.rootEl.children;
        const components = props.rendering.placeholders['gradient-background'];
        if (props.fields.static.value) {
          for (let i = 0, len = components.length; i < len; i++) {
            const component = components[i];
            const componentEl = componentEls.item(i);
            const rect = componentEl.getBoundingClientRect();
            const styles = window.getComputedStyle(componentEl);
            const paddingBottom = parseInt(styles.paddingBottom);
            const gradientMap = [];
            if (i < len - 1) {
              const nextComponent = components[i + 1];
              gradientMap.push({ offset: '0%', color: themes[settingValue(component.fields?.theme, 'white')].bgColor });
              gradientMap.push({ offset: '75%', color: themes[settingValue(component.fields?.theme, 'white')].bgColor });
              gradientMap.push({ offset: '100%', color: themes[settingValue(nextComponent.fields?.theme, 'white')].bgColor });
            }
            if (gradientMap.length > 0) {
              componentEl.style.backgroundImage = `linear-gradient(${gradientMap.map((x) => `${x.color} ${x.offset}`).join(',')})`;
              if (paddingBottom < 100) {
                componentEl.style.paddingBottom = 100 + 'px';
              }
            } else {
              componentEl.style.backgroundColor = themes[settingValue(component.fields?.theme, 'white')].bgColor;
            }
          }
        } else {
          let gradientMap = [];
          let totalDistance = 0;
          for (let i = 0, len = components.length; i < len; i++) {
            const component = components[i];
            const componentEl = componentEls.item(i);
            if (!componentEl) continue;
            const comRect = componentEl.getBoundingClientRect();
            const middle = (comRect.top + comRect.bottom) / 2;
            const winHeight = window.innerHeight;
            const judgeHeight = winHeight / 2;
            if (comRect.top <= winHeight && comRect.bottom >= 0) {
              let distance = window.innerHeight - Math.abs(middle - judgeHeight);
              gradientMap.push({
                theme: settingValue(component.fields?.theme, 'white'),
                distance: distance
              });
              totalDistance += distance;
            }
            if (comRect.top > winHeight) {
              break;
            }
          }
          const bgColorSet = gradientMap.map((x) => ({
            color: themes[x.theme].bgColor,
            weight: x.distance / totalDistance
          }));
          const colorSet = gradientMap.map((x) => ({
            color: themes[x.theme].color,
            weight: x.distance / totalDistance
          }));
          const bgColor = mixColor(...bgColorSet);
          let foreColor = mixColor(...colorSet);
          state.rootEl.style.backgroundColor = bgColor;
          if (isSimilarColor(bgColor, foreColor)) {
            foreColor = reverseColor(bgColor);
          }
          if (isGrey(foreColor)) {
            foreColor = '#000000';
          }
          // state.rootEl.style.color = foreColor;
        }
      }
    };
    onMounted(() => {
      window.addEventListener('resize', _methods.onResize);
      if (!props.fields.static.value) {
        scrollListened = true;
        window.addEventListener('scroll', _methods.onScroll);
      }
      _methods.calculateBackground();
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', _methods.onResize);
      if (scrollListened) {
        window.removeEventListener('scroll', _methods.onScroll);
      }
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss">
.c-gradient-background {
  &.with-animation {
    transition: all 10ms;
    > * {
      background: transparent !important;
      // color: currentColor !important;
      transition: all 10ms;
    }
  }
}
</style>
