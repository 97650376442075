<script setup>
/**
 * @typedef {NewsletterFields} KmiUsFields
 * */
import { ref, computed, reactive, nextTick, inject, onMounted, watch } from 'vue';
import services from '@/services';
import { getGlobalConfigs, getPageAlpha2Code, loadGMap, scrollElToTop, settingValue } from '@/utils/site-utils';
import api from '@/api';
import { merge, debounce } from 'lodash';
import { countryNeedDoubleOptin } from '@/services/siteService';
import { formatDate } from '@/utils/date-utils';
import { gtmFormView, gtmFormStart, gtmFormStep, gtmFormSubmit } from '@/utils/gtm-utils';
import { getPossibleData, isNullOrEmpty } from '@/utils/obj-utils';
import { qtUtils } from '@/utils/ali-tracker-utils';
import { scrollToFirstFormError } from '@/utils/dom-utils';
import useAppStore from '@/store/appStore';
import onScrollToView from '@/hooks/onScrollToView';

const props = defineProps({
  page: {
    type: Object
  },
  /**@type KmiUsFields*/
  fields: {
    type: Object
  }
});
const appStore = useAppStore();
const { formType } = props.page.fields;
const loading = inject('loading');
const toast = inject('toast');
const result = ref(null);
const total = ref(2);
const step = ref(1);
const submitted = ref(false);
const needDoubleOptin = ref(false);
const selectedModels = ref([]);
const overallRef = ref();
const layoutRef = ref();
/**@type Ref<DynamicForm>*/
const formRef = ref();
/**@type Ref<DynamicForm>*/
const agentFormRef = ref();
/**@type Ref<DynamicForm>*/
const consentFormRef = ref();
const formConfig = reactive({
  email: {
    trigger: 'blur'
  }
});
const countryCode = ref(getPageAlpha2Code(props.page));
const zipcode = ref(null);
const selectedDealer = ref(null);
const [gmapKey, contactUsLink] = getGlobalConfigs(props.page, 'gmapKey', 'contactUsLink');
const layoutFields = computed(() => (submitted.value ? props.fields.successLayout?.fields : props.fields.layout?.fields) ?? props.fields.layout?.fields);
const formChanged = ref(false);

const _qt = {
  trackSubmit(isSucceed, isSubscribed, formData) {
    const eventParams = {
      is_success: isSucceed,
      is_subscribe: isSubscribed
    };
    if (!isNullOrEmpty(formData)) {
      const { firstName, lastName, email, country } = formData;
      merge(eventParams, {
        email,
        first_name: firstName,
        last_name: lastName,
        country: country.code
      });
    }
    qtUtils.trackBtnClickWithCode(props.page, props.fields.submitButton, 'keep_me_informed_pg_clk', eventParams);
  }
};
const onFormChange = (name, value) => {
  if (name === 'country') {
    agentFormRef?.value?.setCountry(value.code);
    countryCode.value = value.code;
    agentFormRef?.value?.updateItem('zipCode', {
      controlProps: {
        value: ''
      }
    });
  }
};
const onAgentFormChange = async (name, value) => {
  if (name === 'zipCode') {
    zipcode.value = value;
    if (!agentFormRef?.value?.getItem('dealer')) return;
    const gmaps = await loadGMap(props.page);
    if (!gmaps) {
      return;
    }
    const geocoder = new gmaps.Geocoder();
    geocoder.geocode(
      {
        componentRestrictions: {
          postalCode: value,
          country: countryCode.value
        }
      },
      async function(results, status) {
        if (status === gmaps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          const options = await services.agent.getNormalAgentOptions(props.page, countryCode.value, {
            latitude: lat,
            longitude: lng,
            storeType: null
          });
          // loading.hide();
          if (options?.length) {
            const selectedOption = options.find((x) => x.code === selectedDealer.value?.code);
            selectedDealer.value = selectedOption;
            agentFormRef?.value?.updateItem('dealer', {
              controlProps: {
                options,
                selectedOption
              }
            });
          }
        } else {
          // loading.hide();
        }
      }
    );
    // const [resG, exG] = api.gMap.geocode({});
  }
  if (name === 'dealer') {
    selectedDealer.value = value;
    // agentFormRef.value.updateItem('zipCode', {
    //   controlProps: {
    //     value: value?.data?.extra?.storeZipCode
    //   }
    // });
  }
};
const onModelSelectChange = (model) => {
  if (!formChanged.value) {
    formChanged.value = true;
    gtmFormStart(formType?.value, props.fields.form.id);
  }
  const { code } = model.fields;
  const index = selectedModels.value.findIndex((x) => x === code.value);
  if (index < 0) {
    selectedModels.value.push(code.value);
  } else {
    selectedModels.value.splice(index, 1);
  }
};
const onPrev = () => {
  step.value -= 1;
};
const onNext = () => {
  step.value += 1;
  scrollElToTop(overallRef.value?.rootEl);
};
const onSubmit = debounce(async () => {
  loading.show();
  const { channel } = props.fields;
  const alpha2Code = getPageAlpha2Code(props.page);
  const [formValid, formData] = await formRef.value.validate();
  const [agentFormValid, agentFormData] = await agentFormRef?.value?.validate();
  const [consentFormValid, consentFormData] = await consentFormRef.value.validate();
  const formLeadTypes = formRef.value.getLeadTypes();
  const consentLeadTypes = consentFormRef.value.getLeadTypes();
  const leadTypes = [...formLeadTypes, ...consentLeadTypes];
  if (formValid && agentFormValid && consentFormValid) {
    const { firstName, middleName, lastName, email, mobile, country, externalData } = formData;
    const isAboveTwenty = formData.isAboveTwenty ?? agentFormData?.isAboveTwenty ?? consentFormData?.isAboveTwenty;
    const dealer = getPossibleData('dealer', formData, agentFormData);
    const getBehindTheWheel = getPossibleData('getBehindTheWheel', formData, agentFormData, consentFormData);
    const consentNos = [...new Set([...formRef.value.getVerifiedConsents(), ...agentFormRef?.value?.getVerifiedConsents(), ...consentFormRef.value.getVerifiedConsents()])];
    needDoubleOptin.value = await countryNeedDoubleOptin(alpha2Code);
    const body = merge(
      {
        vehicleModelOfInterest: selectedModels.value.join(','),
        firstName: firstName,
        middleName: middleName ?? '',
        lastName: lastName,
        email: email,
        company: '',
        title: '',
        countryRegion: country.code,
        zipCode: agentFormData?.zipCode ?? formData.zipCode ?? zipcode.value,
        storeCode: dealer?.data.storeCode ?? '',
        needDoubleOptin: needDoubleOptin.value,
        testDriveInterest: getBehindTheWheel ?? false,
        termsAndConditions: consentNos.map((x) => ({
          revisionNumber: x,
          title: window.document.title,
          formUrl: window.location.href,
          effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
        })),
        channel: settingValue(channel, 'Official Website'),
        formScId: formData.formScId,
        gRecaptchaToken: formData.gRecaptchaToken ?? null
      },
      externalData,
      agentFormData?.externalData,
      consentFormData?.externalData
    );
    if (!isNullOrEmpty(mobile)) {
      merge(body, {
        phone: `${mobile.area.code} ${mobile.number}`
      });
    }
    if (!isNullOrEmpty(isAboveTwenty)) {
      merge(body, { isAboveTwenty });
    }
    const [res, ex] = await api.crm.newsletter.subscribe(null, body);
    loading.hide();
    if (ex) {
      await toast.showEx(ex);
      _qt.trackSubmit(false, formData.checkbox, formData);
      return;
    }
    const formDetails = {
      selected_model: selectedModels?.value?.join(','),
      selected_location_name: null,
      selected_location_type: null,
      selected_location_date: null,
      selected_location_time: null,
      customer_type: null,
      dealer_name: dealer?.data?.storeName ?? null
    };
    const leadsDetails = {};
    if (leadTypes?.length) {
      leadTypes.forEach((l) => {
        leadsDetails[l.leadType] = res ?? null;
      });
    }
    gtmFormSubmit(
      formType?.value,
      props.fields.form.id,
      {
        email: email,
        mobileAreaCode: mobile.area.code,
        phone: mobile.number
      },
      formDetails,
      leadsDetails
    );
    result.value = res;
    submitted.value = true;
    await nextTick();
    scrollElToTop(overallRef.value?.rootEl);
    _qt.trackSubmit(true, formData.checkbox, formData);
  } else {
    await scrollToFirstFormError();
    loading.hide();
  }
}, 50);
onScrollToView({
  getContainer() {
    return layoutRef.value.rootEl;
  },
  callback() {
    gtmFormView(formType?.value, props.fields.form.id);
  }
});
watch(
  () => step,
  (v) => {
    let stepName = '';
    switch (v) {
      case 1:
        {
          stepName = 'model_select';
        }
        break;
      case 2:
        {
          stepName = 'personal_details';
        }
        break;
      default:
        break;
    }
    gtmFormStep(formType?.value, props.fields.form.id, appStore?.loginInfo, v.value, stepName, null);
  },
  { immediate: true }
);
onMounted(async () => {
  if (props.fields?.carModels.length === 0) {
    step.value = 2;
  }
  const alpha2Code = getPageAlpha2Code(props.page);
  let options = await services.agent.getNormalAgentOptions(props.page, alpha2Code);
  if (options?.length > 0) {
    agentFormRef?.value?.updateItem('dealer', {
      controlProps: {
        options
      }
    });
  }
});
</script>

<template>
  <overall-settings :fields="fields" ref="overallRef">
    <form-layout :fields="props.fields.layout.fields" :class="['c-kmi-us__layout', { submitted }]" ref="layoutRef">
      <template v-slot:image-content>
        <icon class="c-kmi-us__logo" :field="fields.logo" />
        <div class="c-kmi-us__image-content" v-if="!submitted">
          <heading class="c-kmi-us__image-title" :field="fields.imageTitle" rich />
          <jss-rich-text class="c-kmi-us__image-body" :field="fields.imageBody" />
        </div>
      </template>
      <div class="c-kmi-us" v-if="!submitted">
        <div class="c-kmi-us__header">
          <a class="c-kmi-us__return" :href="`/${page.itemLanguage}`">{{ $t('Return to home') }}</a>
          <div class="c-kmi-us__steps">{{ $formatString(fields.stepText?.value, { step, total: 2 }) }}</div>
        </div>
        <div class="c-kmi-us__section" v-show="step === 1">
          <jss-rich-text class="c-kmi-us__model-title" :font-family="null" :field="fields.modelTitle" tag="div" />
          <jss-rich-text class="c-kmi-us__model-description" :field="fields.modelDescription" tag="div" />
          <div class="c-kmi-us__models">
            <div class="c-kmi-us__model" :class="[{ selected: selectedModels.includes(model.fields?.code.value) }]" v-for="model in fields.carModels" :key="model.id">
              <checkbox class="c-kmi-us__model-check" :value="selectedModels.includes(model.fields?.code.value)" @change="onModelSelectChange(model)" />
              <div class="c-kmi-us__model-image-wrapper" :class="[{ 'no-image': $isNullOrEmpty(model.fields?.image.value?.src) }]" @click="onModelSelectChange(model)">
                <jss-rich-text class="c-kmi-us__model-name" :field="model.fields.name" tag="div" />
                <img class="c-kmi-us__model-image" v-bind="model.fields?.image.value" v-if="!$isNullOrEmpty(model.fields?.image.value?.src)" />
              </div>
            </div>
          </div>
        </div>
        <div class="c-kmi-us__section" v-show="step === 2">
          <heading class="c-kmi-us__title" :field="fields.layout?.fields.title" font-family="overpass" rich />
          <jss-rich-text class="c-kmi-us__body" :field="fields.body" tag="div" />
          <dynamic-form :form="fields.form" :data="formConfig" @change="onFormChange" ref="formRef" />
          <jss-text class="c-kmi-us__dealer-title fs-14" :field="fields.agentTitle" tag="div" />
          <dynamic-form :form="fields.agentForm" ref="agentFormRef" @change="onAgentFormChange">
            <template #dealerOption="scope">
              <div class="c-kmi-us__dealer-option" :class="[{ 'with-mile': !!scope.option.data.metre }]">
                <div class="c-kmi-us__dealer-option-name">{{ scope.option.text }}</div>
                <div class="c-kmi-us__dealer-option-metre" v-if="scope.option.data.metre">
                  {{ Math.floor(scope.option.data.metre / (scope.option.data.metre > 1000 ? 1000 : 1)) }}{{ scope.option.data.metre > 1000 ? 'km' : 'm' }}
                </div>
              </div>
            </template>
          </dynamic-form>
          <dealer-card :dealer="selectedDealer?.data" />
          <jss-text class="c-kmi-us__consent-title" :field="fields.consentTitle" tag="div" />
          <dynamic-form :form="fields.consentForm" ref="consentFormRef" />
        </div>
        <div class="c-kmi-us__toolbar">
          <div>
            <site-button class="c-kmi-us__submit-btn" v-bind="fields.prevButton" @click="onPrev" v-if="step > 1" />
          </div>
          <div>
            <site-button class="c-kmi-us__submit-btn" v-bind="fields.nextButton" :disabled="selectedModels.length === 0" @click="onNext" v-if="step === 1" />
            <site-button class="c-kmi-us__submit-btn" v-bind="fields.submitButton" @click="onSubmit" v-if="step === 2" />
          </div>
        </div>
      </div>
      <div class="c-kmi-us__result" v-if="submitted">
        <heading class="c-kmi-us__result-title" :field="fields.successLayout?.fields.title" rich />
        <jss-rich-text class="c-kmi-us__result-body" v-if="needDoubleOptin && result?.isFirstSubmit" :field="fields.successNotice" />
        <jss-rich-text class="c-kmi-us__result-body" v-else :field="fields.successNotice2" />
        <site-button class="c-kmi-us__result-btn" v-bind="fields.successButton" />
      </div>
    </form-layout>
  </overall-settings>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-kmi-us {
  $c: &;
  $l: s-form-layout;
  &__logo {
    margin-left: grid-width-m(1);
    margin-top: grid-width-m(1);
  }
  &__image-content {
    position: absolute;
    bottom: grid-width-m(1);
    left: grid-width-m(1);
  }
  &__image-body {
    margin-top: $space-40;
  }
  &__title {
    @include h5;
    margin-bottom: 54px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $space-40;
  }
  &__return {
    text-transform: uppercase;
    font-weight: bold;
  }
  &__model-title {
    @include h5;
    margin-bottom: $space-40;
  }
  &__models {
    margin-top: $space-40;
  }
  &__model {
    display: flex;
    + #{$c}__model {
      margin-top: $space-24;
    }
    &.selected {
      #{$c}__model-image-wrapper {
        &:before {
          width: 120px;
          background: $yellow;
        }
        &.no-image {
          &:before {
            display: none;
          }
        }
      }
    }
  }
  &__model-image {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    &-wrapper {
      position: relative;
      margin-left: $space-16;
      padding-bottom: $space-16;
      cursor: pointer;
      &.no-image {
        &:before {
          display: none;
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 20px;
        height: 100%;
        background: $grey-light;
        transition: all 400ms cubic-bezier(0.355, 0.005, 0.26, 1);
      }
    }
  }
  &__model-name {
    position: absolute;
  }
  &__toolbar {
    height: 60px;
    margin-top: $space-40;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__consent-title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 8px;
  }
  &__result {
    &-title {
      @include h5;
      margin-bottom: $space-40;
    }
    &-btn {
      margin-top: 40px;
    }
  }
  &__layout {
    .#{$l}__side-image {
      display: block;
      color: $white;
    }
    .s-form-layout__image {
      .e-background-image__content {
        height: 100%;
      }
    }
    &.submitted.s-form-layout {
      position: relative;
      @include height-except-header;
      .s-form-layout__image-wrapper {
        height: 100%;
      }
      .s-form-layout__image {
        height: 100%;
      }
      .s-form-layout__main {
        position: relative;
        z-index: 1;
        padding-top: 56px;
      }
      .s-form-layout__side-image {
        position: absolute;
        display: block;
        width: 100%;
        height: 100vh;
        .e-background-image__img {
          height: 100%;
          background-color: #f8ed6e;
          background-position: bottom center !important;
          background-size: 100% auto;
          background-repeat: no-repeat;
        }
      }
    }
  }
  &__dealer-option {
    padding: 0 16px;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: center;
    gap: 24px;
    &-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-metre {
      font-size: 12px;
      color: $grey-neutral;
    }
    &.with-mile {
      justify-content: space-between;
    }
    &:hover {
      font-weight: bold;
    }
  }
  .selected {
    #{$c}__dealer-option {
      &-metre {
        color: $white;
      }
    }
  }
  @include tablet-landscape {
    &__logo {
      margin-left: grid-width(2);
      margin-top: grid-width(1);
    }
    &__image-content {
      position: absolute;
      bottom: grid-width(2);
      left: grid-width(2);
    }
    &__layout {
      &.submitted.s-form-layout {
        position: static;
        .s-form-layout__side-image {
          position: static;
          height: auto;
        }
      }
    }
  }
}
</style>
