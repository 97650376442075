<template>
  <OverallSettings v-bind="$props">
    <div class="c-image-bower" v-if="fields">
      <div class="c-image-bower__content" v-grid-layout="fields.gridLayout">
        <div class="c-image-bower__content-item" v-for="item in fields.imageGroup" :key="item.id">
          <BackgroundImage class="c-image-bower__content-item-image" :image="item.fields.image" />
          <div class="c-image-bower__content-item-detail" :class="getPosition(item)">
            <JssRichText class="c-image-bower__content-item-detail-title" :field="item.fields.title" />
            <SiteButton class="c-image-bower__content-item-detail-btn" v-bind="item.fields.button" />
          </div>
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { computed } from 'vue';
export default {
  name: 'ImageBower',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const methods = {
      getPosition: (i) => [`align-${i.fields.align?.fields.phrase.value ?? 'center'}`, `valign-${i.fields.valign?.fields.phrase.value ?? 'middle'}`]
    };
    return { ...methods };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/color.scss';
@import '../styles/rte/fontSize.scss';
.c-image-bower {
  @include component-overall-settings;
  @include component-themes;
  @include grid-container;
  height: 100vh;
  &__content {
    display: flex;
    flex-direction: column;
    &-item {
      height: 50%;
      width: 100%;
      position: relative;
      &-image {
        height: 100%;
      }
      &-detail {
        position: absolute;
        &-title {
          margin-bottom: $space-20;
        }
        &.align {
          &-left {
            left: grid-width(1);
          }
          &-center {
            left: 0;
            right: 0;
            text-align: center;
          }
          &-right {
            right: grid-width(1);
            text-align: end;
          }
        }
        &.valign {
          &-top {
            top: grid-width(1);
          }
          &-middle {
            top: 50%;
            transform: translateY(-50%);
          }
          &-bottom {
            bottom: grid-width(1);
          }
        }
      }
    }
    @include tablet-landscape {
      flex-direction: row;
      &-item {
        width: grid-width(12);
      }
      &.grid-layout-2-20 {
        justify-content: space-between;
        .c-image-bower__content-item {
          width: grid-width(9);
          margin: grid-width(1) 0;
        }
      }
    }
  }
}
</style>
