<template>
  <OverallSettings v-bind="$props">
    <div class="c-hotspot" ref="rootEl" v-if="fields">
      <div class="c-hotspot__tabs">
        <div class="c-hotspot__tabs-item" :class="{ active: selectedTab?.id === item.id }" v-for="item in fields.multipleTags" :key="item.id" @click="selectTab(item)">
          <JssRichText :field="item.fields.title" />
        </div>
      </div>
      <div class="c-hotspot__tip show" ref="tipEl" v-if="!largeThanTabletLandscape()">
        <Icon name="left" />
        <div class="c-hotspot__tip-text">{{ fields.dragText?.value }}</div>
        <Icon name="right" />
      </div>
      <div class="c-hotspot__main" v-if="selectedTab" ref="mainEl">
        <div class="c-hotspot__main-content" :style="{ width: largeThanTabletLandscape() ? '100vw' : `${imageWidth}px` }">
          <adaptive-image class="c-hotspot__main-image" v-if="showImage" :field="selectedTab.fields.adaptiveImage" :fallback-image="selectedTab.fields.image" :isBackground="false" ref="imageEl">
            <template v-if="imageEl">
              <div
                class="c-hotspot__point"
                :class="{ active: selectedPoint?.id === item.id }"
                v-for="(item, index) in selectedTab.fields.items"
                :key="item.id"
                :style="pointStyle(item, false, index)"
                @click.prevent="changePoint(item)"
              >
                <Icon name="point-back" />
                <div class="c-hotspot__point-item"></div>
              </div>
            </template>
          </adaptive-image>
          <div class="c-hotspot__btn">
            <SiteButton v-bind="selectedTab.fields.button" />
          </div>
        </div>
      </div>
      <div class="c-hotspot__details" :style="largeThanTabletLandscape() && pointStyle(selectedPoint, true)" v-if="selectedPoint" ref="desEl">
        <div class="c-hotspot__details-close" @click="hideDes">
          <Icon name="close" />
        </div>
        <div class="c-hotspot__details-main">
          <Icon class="c-hotspot__details-icon" :class="`__c-${selectedPoint.fields.iconColor?.fields.phrase.value}`" :svg="selectedPoint.fields.icon.value.svgCode" />
          <JssRichText class="c-hotspot__details-title" :field="selectedPoint.fields.title" />
          <JssRichText class="c-hotspot__details-body" :field="selectedPoint.fields.body" />
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { toRefs, onMounted, reactive, nextTick, onBeforeUnmount, inject, watch } from 'vue';
import { largeThanTabletLandscape } from '@/utils/dom-utils';
import { getAdaptiveTextField } from '@/utils/site-utils';
import { equalString } from '@/utils/string-utils';
export default {
  name: 'Hotspot',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    let timer;
    const { deviceState } = inject('device-common');
    const state = reactive({
      rootEl: null,
      tipEl: null,
      mainEl: null,
      imageEl: null,
      desEl: null,
      selectedTab: null,
      selectedPoint: null,
      imageHeight: null,
      imageWidth: null,
      showImage: true,
      flag: false,
      inScreen: false
    });
    const methods = {
      largeThanTabletLandscape,
      getCoordinatesPix: (str) => {
        const pixes = str?.split(',');
        return [parseFloat(pixes[0]), parseFloat(pixes[1])];
      },
      pointStyle: (i, f) => {
        const coordinatesField = getAdaptiveTextField(deviceState, i.fields.coordinates, i.fields.mobileCoordinates);
        const coordinates = methods.getCoordinatesPix(coordinatesField?.value);
        const position = i.fields.position?.fields.phrase?.value ?? 'leftBottom';
        let tmp = {
          left: null,
          top: null
        };
        if (f) {
          if (state.desEl) {
            const desOffset = state.desEl.getBoundingClientRect();
            if (position === 'RightBottom') {
              tmp = {
                left: (state.imageWidth * coordinates[1]) / 100 + 22 + 'px',
                top: (state.imageHeight * coordinates[0]) / 100 + 22 + 'px'
              };
              if (state.imageWidth - (state.imageWidth * coordinates[1]) / 100 + 22 < desOffset.width) {
                tmp.left = state.imageWidth - desOffset.width + 'px';
              }
              if (state.imageHeight - (state.imageHeight * coordinates[0]) / 100 + 22 < desOffset.height) {
                tmp.top = state.imageHeight - desOffset.height + 'px';
              }
            } else if (position === 'RightTop') {
              tmp = {
                left: (state.imageWidth * coordinates[1]) / 100 + 22 + 'px',
                top: (state.imageHeight * coordinates[0]) / 100 - desOffset.height - 22 + 'px'
              };
              if (state.imageWidth - (state.imageWidth * coordinates[1]) / 100 + 22 < desOffset.width) {
                tmp.left = state.imageWidth - desOffset.width + 'px';
              }
              if ((state.imageHeight * coordinates[0]) / 100 - 22 < desOffset.height + 360) {
                tmp.top = '360px';
              }
            } else if (position === 'LeftBottom') {
              tmp = {
                left: (state.imageWidth * coordinates[1]) / 100 - desOffset.width - 22 + 'px',
                top: (state.imageHeight * coordinates[0]) / 100 + 22 + 'px'
              };
              if ((state.imageWidth * coordinates[1]) / 100 - 22 < desOffset.width) {
                tmp.left = '0px';
              }
              if (state.imageHeight - (state.imageHeight * coordinates[0]) / 100 + 22 < desOffset.height) {
                tmp.top = state.imageHeight - desOffset.height + 'px';
              }
            } else {
              tmp = {
                left: (state.imageWidth * coordinates[1]) / 100 - desOffset.width - 22 + 'px',
                top: (state.imageHeight * coordinates[0]) / 100 - desOffset.height - 22 + 'px'
              };
              if ((state.imageWidth * coordinates[1]) / 100 - 22 < desOffset.width) {
                tmp.left = '0px';
              }
              if ((state.imageHeight * coordinates[0]) / 100 - 22 < desOffset.height) {
                tmp.top = '0px';
              }
            }
            return tmp;
          }
        } else {
          tmp.left = (state.imageWidth * coordinates[1]) / 100 - 19 + 'px';
          tmp.top = (state.imageHeight * coordinates[0]) / 100 - 19 + 'px';
          return tmp;
        }
      },
      changePoint: async (i) => {
        state.selectedPoint = null;
        if (state.selectedPoint !== i) {
          await nextTick();
          state.selectedPoint = i;
        }
      },
      selectTab: (i) => {
        state.showImage = false;
        state.selectedTab = i;
        state.selectedPoint = JSON.parse(JSON.stringify(i.fields.active));
        setTimeout(() => {
          state.showImage = true;
          methods.showPoints();
        }, 20);
      },
      hideDes: (e) => {
        state.selectedPoint = null;
        methods.onscroll();
      },
      onscroll: () => {
        const rootOffset = state.rootEl?.getBoundingClientRect();
        state.inScreen = rootOffset.top < window.innerHeight && rootOffset.bottom > 50;
        const isShow = state.tipEl?.classList.value.indexOf('show') >= 0;
        if (state.inScreen && isShow) {
          setTimeout(() => {
            state.tipEl?.classList.remove('show');
            state.tipEl?.classList.add('hide');
          }, 1400);
        }
        timer && clearInterval(timer);
        timer = setInterval(() => {
          const isHide = state.tipEl?.classList.value.indexOf('hide') >= 0;
          if (isHide) {
            state.tipEl?.classList.remove('hide');
            state.tipEl?.classList.add('show');
          }
        }, 60000);
      },
      showPoints: async () => {
        await nextTick();
        if (!state.imageEl) return;
        const offset = state.imageEl.$el.getBoundingClientRect();
        let h = (((window.innerWidth * 20) / 24) * 9) / 16 - 64;
        if (!largeThanTabletLandscape()) {
          h = window.innerHeight - 54;
        }
        if (offset.top <= h) {
          window.removeEventListener('scroll', methods.showPoints);
          const points = document.querySelectorAll('.c-hotspot__point');
          setTimeout(() => {
            points.forEach((p) => {
              p.style.display = 'inline-block';
            });
          }, 1200);
        }
      }
    };
    watch(
      () => state.imageEl,
      (v) => {
        if (v) {
          let timer = setInterval(() => {
            const imgEl = state.mainEl.querySelector('img');
            if (imgEl) {
              clearInterval(timer);
              const offset = imgEl.getBoundingClientRect();
              state.imageHeight = offset.height;
              state.imageWidth = offset.width;
              state.mainEl.scrollLeft = (state.imageWidth - window.innerWidth) / 2;
            }
          }, 100);
        }
      },
      { immediate: true, deep: true }
    );
    onMounted(() => {
      const activeTabIndex = props.fields.selectedTab ? props.fields.multipleTags.findIndex((t) => equalString(t.id, props.fields.selectedTab.id)) : 0;
      const activeTab = props.fields.multipleTags[activeTabIndex];
      const activePoint = activeTab?.fields?.active;
      state.selectedTab = activeTab ? JSON.parse(JSON.stringify(activeTab)) : null;
      state.selectedPoint = activePoint ? JSON.parse(JSON.stringify(activePoint)) : null;
      window.addEventListener('scroll', methods.onscroll);
      document.addEventListener('click', methods.onscroll, true);
      window.addEventListener('scroll', methods.showPoints);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', methods.onscroll);
      document.removeEventListener('click', methods.onscroll, true);
      window.removeEventListener('scroll', methods.showPoints);
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/color.scss';
@import '../styles/rte/fontSize.scss';
.c-hotspot {
  @include grid-container;
  width: 100%;
  height: 100vh;
  &__tabs {
    @include grid-block(2, 10, 1);
    height: fit-content;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    padding-top: 40px;
    &::after {
      display: block;
      content: '';
      height: 1px;
      width: 100%;
      background: white;
      opacity: 0.2;
      position: absolute;
      bottom: -17px;
      left: 0;
    }
    &-item {
      cursor: pointer;
      position: relative;
      transition: all 0.3s;
      opacity: 0.7;
      &::after {
        display: block;
        content: '';
        height: 1px;
        background: white;
        position: absolute;
        bottom: -17px;
        left: 0;
        width: 0;
        transition: all 0.3s;
      }
      &.active {
        opacity: 1;
        &::after {
          display: block;
          content: '';
          height: 1px;
          background: white;
          position: absolute;
          bottom: -17px;
          left: 0;
          width: 100%;
        }
      }
    }
  }
  &__tip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 40px;
    background: radial-gradient(60.41% 90% at 49.82% 140%, rgba(46, 46, 46, 0.4) 0%, rgba(0, 0, 0, 0.5) 100%);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    &.show {
      animation: fade-in 0.8s forwards linear;
      z-index: 2;
    }
    &.hide {
      animation: fade-out 0.8s forwards linear;
      z-index: -1;
    }
    &-text {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 2px;
    }
    > .e-icon {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__main {
    @include grid-block(1, 12, 1);
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    &-content {
      height: 100%;
      width: fit-content;
    }
    &-image {
      height: 100%;
      width: fit-content;
      > .e-background-image__img {
        height: 100%;
        width: fit-content;
        > img {
          height: 100%;
          width: fit-content;
        }
      }
    }
  }
  &__btn {
    width: fit-content;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
  }
  &__point {
    position: absolute;
    width: 38px;
    height: 38px;
    border-radius: 19px;
    fill: radial-gradient(60.41% 90% at 49.82% 140%, rgba(79, 79, 79, 0.4) 0%, rgba(0, 0, 0, 0.5) 100%);
    backdrop-filter: blur(6px);
    display: none;
    cursor: pointer;
    &-item {
      position: absolute;
      top: 13px;
      left: 13px;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      stroke-width: 1px;
      stroke: $yellow-neutral;
      background-color: $yellow-neutral;
      &:after {
        content: ' ';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 6px;
        border: 1px solid $yellow-neutral;
        transition: all 0.3s ease-in;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &.active {
      .c-hotspot__point-item {
        box-shadow: 0 0 8px rgba(#fff645, 0.7);
        &:after {
          width: 36px;
          height: 36px;
          top: -13px;
          left: -13px;
          border-radius: 19px;
        }
      }
    }
  }
  &__details {
    width: grid-width-m(10);
    position: absolute;
    z-index: 2;
    padding: $space-20;
    border-radius: 1px;
    background: radial-gradient(60.41% 90% at 49.82% 140%, rgba(46, 46, 46, 0.4) 0%, rgba(0, 0, 0, 0.5) 100%);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    left: grid-width-m(1);
    bottom: grid-width-m(1);
    padding: 12px 12px 22px 24px;
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    justify-content: space-between;
    &-close {
      cursor: pointer;
      > .e-icon svg {
        width: 16px;
        height: 16px;
      }
    }
    &-main {
      cursor: default;
      padding-top: 4px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &-icon {
      &.e-icon > svg {
        height: 20px;
        width: 20px;
      }
    }
    &-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
    &-body {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: $grey-taubmans;
    }
  }
  @include tablet-landscape {
    &__tabs {
      @include grid-block(10, 6, 1);
      padding-top: 72px;
    }
    &__main {
      @include grid-block(1, 24, 1);
    }
  }
  @include desktop {
    &__details {
      width: 415px;
      bottom: unset;
    }
    &__point {
      position: absolute;
      cursor: pointer;
    }
    &__main {
      @include grid-block(1, 24, 1);
      &-content {
        width: 100%;
      }
      &-image {
        width: 100%;
        > .e-background-image__img {
          width: 100%;
          > img {
            width: 100%;
          }
        }
      }
    }
    &__btn {
      bottom: $space-56;
      > .e-site-button {
        min-width: grid-width(3);
      }
    }
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
html.rtl {
  .c-hotspot {
    &__point:after {
      transform: translate(14px, -14px);
      @include tablet-landscape {
        transform: translate(18px, -18px);
      }
    }
    &__tabs-item {
      border-right: 2px solid $white;
      &:first-child {
        border: none;
      }
    }
  }
}
</style>
