<script setup>
import { sitecoreComponentProps } from '@/utils/constants';
import { computed, inject, nextTick, onMounted, ref } from 'vue';
import { countryNeedDoubleOptin } from '@/services/siteService';
import { formatDate } from '@/utils/date-utils';
import { getPageAlpha2Code, settingValue } from '@/utils/site-utils';
import { gtmPush, gtmFormView, gtmFormStart, gtmFormSubmit } from '@/utils/gtm-utils';
import { getOffsetTop } from '@/utils/dom-utils';
import { merge, debounce } from 'lodash';
import api from '@/api';
import { getAgentList } from '@/services/agentService';
import { appendQuery, getQueryStrings } from '@/utils/uri-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { useRoute, useRouter } from 'vue-router';
import { toFistLetterUpper } from '@/utils/string-utils';

const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
const { formType } = props.page.fields;
const loading = inject('loading');
const toast = inject('toast');
const route = useRoute();
const router = useRouter();
const dealer = ref(null);
const submitted = ref(false);
const layoutRef = ref();
const contactFormRef = ref();
const formRef = ref();
const consentFormRef = ref();
const dealerSelectModalRef = ref();
const formChanged = ref(false);
const layoutFields = computed(() => (submitted.value ? props.fields.successLayout?.fields : props.fields.layout?.fields) ?? props.fields.layout?.fields);
const fData = computed(() => ({
  series: toFistLetterUpper(route.query.series),
  dealer: dealer.value?.storeName
}));
const backButton = computed(() => {
  if (!props.fields?.backButton?.fields) return null;
  const { link } = props.fields?.backButton.fields;
  const href = link.value?.href;
  if (isNullOrEmpty(href)) return props.fields?.backButton;
  return {
    fields: {
      ...props.fields?.backButton.fields,
      link: {
        value: {
          ...link.value,
          href: appendQuery(href, {
            series: route.query.series,
            storeId: route.query.storeId
          })
        }
      }
    }
  };
});
const onChangeDealer = async () => {
  dealer.value = await dealerSelectModalRef.value.open(dealer.value);
};
const onBack = () => {
  if (!isNullOrEmpty(route.redirectedFrom?.fullPath)) {
    router.back();
  } else {
    router.replace({ path: `/${props.page.itemLanguage}` });
  }
};
const onSubmit = debounce(async (e) => {
  let contactValid = true,
    contactData = null;
  loading.show();
  const alpha2Code = getPageAlpha2Code(props.page);
  const [valid, formData] = await formRef.value.validate();
  if (props.fields?.contactForm?.fields) {
    [contactValid, contactData] = await contactFormRef.value.validate();
  }
  const [consentValid, consentData] = await consentFormRef.value.validate();
  if (valid && contactValid && consentValid) {
    const { firstName, middleName, lastName, email, mobile, description, externalData } = formData;
    const { channel } = props.fields;
    const formLeadTypes = formRef?.value.getLeadTypes();
    const consentLeadTypes = consentFormRef?.value.getLeadTypes();
    const leadTypes = [...formLeadTypes, ...consentLeadTypes];
    const consents = Array.from(new Set([...formRef.value.getVerifiedConsents(), ...consentFormRef.value.getVerifiedConsents()]));
    const needDoubleOptin = await countryNeedDoubleOptin(alpha2Code);
    const subscribeBody = {
      vehicleModelOfInterest: toFistLetterUpper(route.query.series) ?? '',
      countryRegion: alpha2Code,
      language: props.page.itemLanguage,
      needDoubleOptin,
      termsAndConditions: consents.map((x) => ({
        revisionNumber: x,
        title: window.document.title,
        formUrl: window.location.href,
        effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
      })),
      firstName,
      middleName: middleName ?? '',
      lastName,
      email,
      description,
      storeCode: dealer.value?.storeCode ?? '',
      channel: settingValue(channel, 'Official Website'),
      preferredContactMethod:
        contactData?.methods
          ?.filter((x) => x.checked)
          .map((x) => x.code)
          .join(';') ?? '',
      formScId: consentData.formScId,
      gRecaptchaToken: consentData.gRecaptchaToken ?? null
    };
    if (!isNullOrEmpty(mobile?.number)) {
      merge(subscribeBody, {
        phone: `${mobile?.area.code}${mobile?.number}`
      });
    }
    merge(subscribeBody, externalData ?? {});
    const [resNewsletter, exNewsletter] = await api.crm.newsletter.subscribe(null, subscribeBody);
    if (exNewsletter) {
      await toast.showEx(exNewsletter);
      loading.hide();
      return;
    }
    let leadsDetails = {};
    if (leadTypes?.length) {
      leadTypes.forEach((l) => {
        leadsDetails[l.leadType] = resNewsletter?.leadId ?? null;
      });
    }
    gtmPush({
      event: 'newsletter_subscribe',
      form_name: 'get_in_touch',
      form_step_name: 'success',
      form_step_number: 2,
      lead_id: resNewsletter?.lead_id
    });
    gtmFormSubmit(
      formType?.value,
      props.fields.verifySection?.fields?.accountForm?.id,
      {
        email: formData.email ?? '',
        mobileAreaCode: formData.mobile?.area?.code ?? '',
        phone: formData.mobile?.number ?? ''
      },
      {
        selected_model: null,
        selected_location_name: null,
        selected_location_type: null,
        selected_location_date: null,
        selected_location_time: null,
        customer_type: null,
        dealer_name: dealer?.value?.storeName ?? ''
      },
      leadsDetails
    );
    submitted.value = true;
    await nextTick();
    const offsetTop = getOffsetTop(layoutRef.value.rootEl);
    window.scrollTo(0, offsetTop);
  }
  loading.hide();
}, 100);
const onFormChange = (key, value) => {
  if (!formChanged.value) {
    formChanged.value = true;
    gtmFormStart(formType?.value, props.fields.form.id);
  }
};
onMounted(async () => {
  const alpha2Code = getPageAlpha2Code(props.page);
  const [storeId] = getQueryStrings('storeId');
  const dealerList = await getAgentList(props.page, alpha2Code, {
    storeType: null
  });
  dealerSelectModalRef.value.setDealers(dealerList);
  dealer.value = dealerList.find((x) => x.id === storeId);
  gtmFormView(formType?.value, props.fields.form.id);
});
</script>

<template>
  <overall-settings v-bind="$props">
    <form-layout
      :fields="layoutFields"
      :class="['c-get-in-touch__layout', { submitted, 'show-image-on-mobile': $deviceComputes.isMobileDevice.value && fields.showImageOnMobile?.value }]"
      ref="layoutRef"
    >
      <template #image-content>
        <div class="c-get-in-touch__image-content">
          <div class="c-get-in-touch__image-title mg-b-16" v-html="$formatString(fields.imageTitle.value, fData)" v-if="!$isNullOrEmpty(fields.imageTitle.value)" />
          <div class="c-get-in-touch__image-body" v-html="$formatString(fields.imageBody.value, fData)" v-if="!$isNullOrEmpty(fields.imageBody.value)" />
        </div>
      </template>
      <div class="c-get-in-touch" v-if="!submitted">
        <div class="c-get-in-touch__header">
          <site-button v-bind="backButton" @click="onBack" />
        </div>
        <heading class="c-get-in-touch__title mg-b-40 tl-mg-b-80" :field="fields.title" :type="fields.titleType" />
        <jss-rich-text class="c-get-in-touch__subtitle" :field="fields.subtitle" tag="div" />
        <jss-rich-text class="c-get-in-touch__body" :field="fields.body" tag="div" />
        <jss-rich-text class="fs-26 mg-b-24" :field="fields.contactTitle" />
        <jss-rich-text class="mg-b-24" :field="fields.contactBody" />
        <dynamic-form ref="contactFormRef" :form="fields.contactForm" />
        <dynamic-form :form="fields.form" ref="formRef" @change="onFormChange" />
        <dealer-selector v-bind="fields.dealerSelector" :dealer="dealer" @change="onChangeDealer" />
        <dynamic-form :form="fields.consentForm" ref="consentFormRef" />
        <div class="c-get-in-touch__toolbar">
          <site-button class="c-get-in-touch__back-btn" v-bind="backButton" @click="onBack" />
          <site-button class="c-get-in-touch__submit-btn" v-bind="fields.submitButton" @click="onSubmit" />
        </div>
      </div>
      <div class="c-get-in-touch__success" v-if="submitted">
        <div class="c-get-in-touch__success-title" v-html="$formatString(fields.successTitle.value, fData)" v-if="!$isNullOrEmpty(fields.successTitle.value)" />
        <div class="c-get-in-touch__success-subtitle" v-html="$formatString(fields.successSubtitle.value, fData)" v-if="!$isNullOrEmpty(fields.successSubtitle.value)" />
        <site-button class="c-get-in-touch__success-btn" v-bind="fields.successButton" />
      </div>
    </form-layout>
    <dealer-select-modal v-bind="fields.dealerSelectModal" :dealer="dealer" ref="dealerSelectModalRef" />
  </overall-settings>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-get-in-touch {
  $c: &;
  $l: '.s-form-layout';
  padding-bottom: $space-40;
  &__header {
    .e-site-button {
      font-weight: 400;
    }
  }
  &__layout {
    &.submitted {
      #{$l}__image-wrapper {
        z-index: 1;
      }
    }
    &.show-image-on-mobile {
      #{$l}__side-image {
        display: block;
        @include grid-block(1, 12, 1);
        height: 100vw;
      }
      #{$l}__image-wrapper {
        height: 100vw;
      }
      #{$l}__image {
        height: 100vw;
      }
      #{$l}__main {
        @include grid-block(2, 10, 2);
      }
    }
  }
  &__image-content {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: $white;
    padding: 24px;
  }
  &__image-title {
    font-size: 32px;
  }
  &__image-body {
    font-size: 18px;
  }
  &__title {
    @include h6;
  }
  &__subtitle {
    font-size: 26px;
    margin-bottom: 16px;
  }
  &__body {
    margin-bottom: 40px;
  }
  &__toolbar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .e-site-button {
      width: calc(50% - 12px);
    }
  }
  &__back-btn {
    display: none !important;
    font-weight: 400;
  }
  &__success-title {
    @include h5;
    padding-top: $space-56;
    margin-bottom: $space-24;
  }
  &__success-subtitle {
    padding-top: $space-16;
    margin-bottom: $space-24;
  }
  &__success-btn {
    @include h5;
  }
  @include tablet-landscape {
    &__image-content {
      position: absolute;
      padding: 64px grid-width(1);
    }
    &__image-title {
      font-size: 56px;
    }
    &__header {
      display: none;
    }
    &__toolbar {
      justify-content: space-between;
      &-prev {
        display: block;
      }
    }
    &__back-btn {
      display: block;
    }
  }
}
</style>
