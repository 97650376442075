<template>
  <overall-settings v-bind="$props">
    <div :class="rootClasses" v-if="fields" ref="rootEl">
      <div class="c-image-slider-horizontal__header" v-if="fields.slides.length > 1 && !centerFlag" ref="headerEl">
        <Icon :svg="fields.prevIcon?.value.svgCode" class="c-image-slider-horizontal__header-left" size="small" v-show="!!currentIndex" />
        <div class="c-image-slider-horizontal__pagination" ref="topPaginationEl" />
        <Icon :svg="fields.nextIcon?.value.svgCode" class="c-image-slider-horizontal__header-right" size="small" v-show="currentIndex < fields.slides.length - 1" />
      </div>
      <div class="c-image-slider-horizontal__swiper-main" :class="{ center: centerFlag }">
        <div class="swiper" ref="swiperEl">
          <div class="swiper-wrapper">
            <div class="swiper-slide" :class="`grid-${sildeWidth}`" v-for="(slide, index) in fields.slides" :key="slide.id">
              <AdaptiveMedia class="c-image-slider-horizontal__slide-image" :field="slide.fields.media" />
              <template v-if="!centerFlag || !largeThanTablet()">
                <JssRichText class="c-image-slider-horizontal__slide-title" :field="slide.fields.title" />
                <div class="c-image-slider-horizontal__slide-desc" @click="toggleDisc(index)" @mouseenter="showDisc(index)" @mouseleave="hideDisc(index)">
                  <JssRichText :field="slide.fields.description" tag="span" />
                  <template v-if="slide.fields.disclaimerNew">
                    <Icon :field="slide.fields.disclaimerNew?.fields?.icon" />
                    <div class="c-image-slider-horizontal__slide-disclaimer" ref="disclaimerEl">
                      <JssRichText :field="slide.fields.disclaimerNew?.fields?.text" />
                    </div>
                  </template>
                  <template v-else-if="!fields.discIcon?.value && slide.fields.disclaimer && slide.fields.hasDisclaimer">
                    <Icon :svg="fields.discIcon?.value.svgCode" />
                    <div class="c-image-slider-horizontal__slide-disclaimer" ref="disclaimerEl">
                      <JssRichText :field="slide.fields.disclaimer" />
                    </div>
                  </template>
                </div>
                <site-button class="c-image-slider-horizontal__slide-btn" v-bind="slide.fields.button" />
              </template>
            </div>
          </div>
          <template v-if="centerFlag && largeThanTablet() && !centerPagination">
            <Icon name="left" class="c-image-slider-horizontal__nav c-image-slider-horizontal__nav-left" v-show="!!currentIndex" />
            <Icon name="right" class="c-image-slider-horizontal__nav c-image-slider-horizontal__nav-right" v-show="currentIndex < fields.slides.length - 1" />
          </template>
        </div>
      </div>
      <div class="c-image-slider-horizontal__center" v-if="fields.slides.length > 1 && centerFlag && centerPagination" ref="centerPaginationEl">
        <Icon name="left" class="c-image-slider-horizontal__nav c-image-slider-horizontal__nav-left" :class="{ clickable: !!currentIndex }" />
        <div class="c-image-slider-horizontal__pagination c-image-slider-horizontal__pagination-center" ref="bottomPaginationEl" />
        <Icon name="right" class="c-image-slider-horizontal__nav c-image-slider-horizontal__nav-right" :class="{ clickable: currentIndex < fields.slides.length - 1 }" />
      </div>
      <div class="c-image-slider-horizontal__description" :class="{ down: centerPagination }" v-if="centerFlag && largeThanTablet()">
        <JssRichText class="c-image-slider-horizontal__description-title" :field="fields.slides[currentIndex].fields.title" />
        <div class="c-image-slider-horizontal__description-desc" @click="toggleDisc(0)" @mouseenter="showDisc(0)" @mouseleave="hideDisc(0)">
          <JssRichText :field="fields.slides[currentIndex].fields.description" tag="span" />
          <template v-if="fields.slides[currentIndex].fields.disclaimerNew">
            <Icon :field="fields.slides[currentIndex].fields.disclaimerNew?.fields?.icon" />
            <div class="c-image-slider-horizontal__description-disclaimer" ref="disclaimerEl">
              <JssRichText :field="fields.slides[currentIndex].fields.disclaimerNew?.fields?.text" />
            </div>
          </template>
          <template v-else-if="!fields.discIcon?.value && fields.slides[currentIndex].fields.hasDisclaimer && fields.slides[currentIndex].fields.disclaimer">
            <Icon :svg="fields.discIcon?.value.svgCode" v-if="fields.slides[currentIndex].fields.hasDisclaimer?.value" />
            <div class="c-image-slider-horizontal__description-disclaimer" v-if="fields.slides[currentIndex].fields.hasDisclaimer?.value" ref="disclaimerEl">
              <JssRichText :field="fields.slides[currentIndex].fields.disclaimer" />
            </div>
          </template>
        </div>
        <site-button class="c-image-slider-horizontal__description-btn" v-bind="fields.slides[currentIndex].fields.button" v-if="fields.slides[currentIndex].fields.button" />
      </div>
      <div class="c-image-slider-horizontal__footer" v-if="fields.slides.length > 1 && centerFlag && !centerPagination">
        <div class="c-image-slider-horizontal__pagination c-image-slider-horizontal__pagination-footer" ref="bottomPaginationEl" />
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { computed, nextTick, onMounted, onBeforeUnmount, reactive, toRefs } from 'vue';
import { gridWidth, settingValue, themeClass } from '@/utils/site-utils';
import { largeThanTablet, canUseDOM } from '@/utils/dom-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import Swiper from 'swiper';
/**
 * @typedef ImageSliderHorizontalFields
 * @property {GlobalSettingEntry} imageRatio
 * @property {Array<ImageSliderHorizontalSlide>} slides
 * @property {ImageField} prevIcon
 * @property {ImageField} nextIcon
 * */
/**
 * @typedef ImageSliderHorizontalSlide
 * @property {{
 *   image: ImageField,
 *   title: SimpleField,
 *   description: SimpleField,
 *   button: ButtonField
 * }} fields
 * */
export default {
  name: 'ImageSliderHorizontal',
  props: {
    /**@type {ImageSliderHorizontalFields|{}}*/
    fields: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    let swiper = null;
    const state = reactive({
      /**@type {HTMLElement}*/
      rootEl: null,
      swiperEl: null,
      headerEl: null,
      centerPaginationEl: null,
      /**@type {HTMLElement}*/
      topPaginationEl: null,
      /**@type {HTMLElement}*/
      bottomPaginationEl: null,
      disclaimerEl: null,
      currentIndex: 0
    });
    const computes = {
      imageRatio: computed(() => settingValue(props.fields?.imageRatio, '16_9')),
      centerPagination: computed(() => props.fields?.centerPagination?.value ?? false),
      rootClasses: computed(() => ['c-image-slider-horizontal', themeClass(props), `ratio-${computes.imageRatio.value}`, { 'center-pagination': computes.centerPagination.value }]),
      centerFlag: computed(() => props.fields?.centerLayout?.value ?? false),
      sildeWidth: computed(() => settingValue(props.fields?.slideWidth, '12')),
      descriptionHeight: computed(() => {
        let tmp = 0;
        const desList = state.rootEl.querySelector('.c-image-slider-horizontal__description');
        console.log(desList);
        return tmp;
      })
    };
    const methods = {
      largeThanTablet,
      isNullOrEmpty,
      buildSwiper: () => {
        if (swiper) {
          swiper.destroy(true, true);
        }
        const options = {
          slidesPerView: largeThanTablet() ? 'auto' : computes.centerFlag?.value ? 1 : 'auto',
          spaceBetween: largeThanTablet() ? (computes.imageRatio.value === '12_5' ? '16' : gridWidth(1)) : computes.centerFlag?.value ? 16 : gridWidth(1),
          centeredSlides: true,
          pagination: {
            el: computes.centerFlag?.value ? state.bottomPaginationEl : state.topPaginationEl,
            bulletElement: 'div',
            clickable: true
          },
          navigation: computes.centerFlag.value
            ? computes.centerPagination.value
              ? {
                  prevEl: state.centerPaginationEl?.querySelector('.c-image-slider-horizontal__nav-left'),
                  nextEl: state.centerPaginationEl?.querySelector('.c-image-slider-horizontal__nav-right')
                }
              : {
                  prevEl: state.swiperEl?.querySelector('.c-image-slider-horizontal__nav-left'),
                  nextEl: state.swiperEl?.querySelector('.c-image-slider-horizontal__nav-right')
                }
            : {
                prevEl: state.headerEl?.querySelector('.c-image-slider-horizontal__header-left'),
                nextEl: state.headerEl?.querySelector('.c-image-slider-horizontal__header-right')
              },
          on: {
            slideChange() {
              state.currentIndex = swiper?.realIndex ?? 0;
            }
          }
        };
        swiper = new Swiper(state.swiperEl, options);
      },
      toggleDisc: (i) => {
        if (!largeThanTablet()) {
          if (computes.centerFlag.value) {
            if (state.disclaimerEl) {
              const display = state.disclaimerEl.style.display;
              if (display === 'block') {
                state.disclaimerEl.style.display = 'none';
              } else {
                state.disclaimerEl.style.display = 'block';
              }
            }
          } else {
            if (state.disclaimerEl[i]) {
              const display = state.disclaimerEl[i].style.display;
              if (display === 'block') {
                state.disclaimerEl[i].style.display = 'none';
              } else {
                state.disclaimerEl[i].style.display = 'block';
              }
            }
          }
        }
      },
      showDisc: (i) => {
        if (largeThanTablet()) {
          if (computes.centerFlag.value) {
            if (state.disclaimerEl) {
              state.disclaimerEl.style.display = 'block';
            }
          } else {
            if (state.disclaimerEl[i]) {
              state.disclaimerEl[i].style.display = 'block';
            }
          }
        }
      },
      hideDisc: (i) => {
        if (largeThanTablet()) {
          if (computes.centerFlag.value) {
            if (state.disclaimerEl) {
              state.disclaimerEl.style.display = 'none';
            }
          } else {
            if (state.disclaimerEl[i]) {
              state.disclaimerEl[i].style.display = 'none';
            }
          }
        }
      }
    };
    onMounted(() => {
      if (canUseDOM()) {
        window.addEventListener('resize', methods.buildSwiper);
        nextTick(() => {
          methods.buildSwiper();
        });
      }
    });
    onBeforeUnmount(() => {
      swiper && swiper.destroy(true, true);
      window.removeEventListener('resize', methods.buildSwiper);
    });
    return {
      ...computes,
      ...methods,
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
$c: c-image-slider-horizontal;
.c-image-slider-horizontal {
  @include grid-container;
  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: grid-width(1);
    padding-top: $space-12;
    padding-bottom: $space-30;
  }
  &__header-left {
    transition: all 0.2s cubic-bezier(0.1, 0.1, 0.1, 1);
    transform: scale(1);
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  &__header-right {
    transition: all 0.2s cubic-bezier(0.1, 0.1, 0.1, 1);
    transform: scale(1);
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  &__swiper-main {
    overflow: hidden;
  }
  &__pagination {
    display: flex;
    padding: 0 $space-12;
    gap: 12px;
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: $grey-neutral;
      cursor: pointer;
      &-active {
        background: $yellow;
      }
    }
    &-footer {
      margin: 0 auto;
    }
  }
  .swiper {
    position: relative;
    overflow: hidden;
    .swiper-wrapper {
      .swiper-slide {
        opacity: 0.9;
        &-active {
          opacity: 1;
        }
      }
    }
  }
  &__slide-image {
    cursor: pointer;
  }
  &__slide-title {
    margin-top: $space-20;
    word-break: break-word;
    text-align: center;
  }
  &__slide-desc {
    position: relative;
    margin-top: $space-20;
    text-align: center;
    min-height: 50px;
    .e-icon {
      margin-left: 3px;
      > svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  &__slide-disclaimer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transform: translateY(-100%);
    background-color: #f5f5f5;
    padding: 40px grid-width-m(1);
    text-align: start;
    display: none;
  }
  &__slide-btn {
    margin-top: $space-30;
  }
  &.center-pagination {
    &.ratio-16_9 {
      .#{$c}__swiper-main {
        &.center {
          .#{$c}__slide-image {
            height: grid-width-m(math.div(10 * 4, 3));
          }
        }
      }
    }
    &.ratio-12_5 {
      .#{$c}__swiper-main {
        &.center {
          .#{$c}__slide-image {
            height: grid-width-m(math.div(10 * 4, 3));
          }
        }
      }
    }
  }
  &__center {
    @include grid-block(2, 10, 3);
    display: flex;
    justify-content: center;
    align-items: center;
    height: grid-width(1);
    grid-row-start: 3;
    .#{$c}__nav {
      color: #ccc;
      &.clickable {
        color: #222;
      }
    }
  }
  &__description {
    @include grid-block(2, 10, 3);
    text-align: center;
    color: $grey-neutral;
    &.down {
      @include grid-block(2, 10, 4);
    }
    &-title {
      margin-top: $space-20;
    }
    &-desc {
      min-height: 50px;
      position: relative;
      margin-top: $space-20;
      .e-icon {
        margin-left: 3px;
        > svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    &-btn {
      margin-top: $space-24;
    }
    &-disclaimer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      transform: translateY(-100%);
      background-color: #f5f5f5;
      text-align: start;
      display: none;
      > div {
        padding: 40px grid-width-m(1);
      }
    }
  }
  &__footer {
    @include grid-block(2, 10, 4);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: grid-width(1);
    grid-row-start: 4;
    padding-top: $space-40;
    padding-bottom: $space-30;
  }
  &.theme- {
    &yellow {
      .#{$c}__pagination {
        .swiper-pagination-bullet {
          &-active {
            background: $white;
          }
        }
      }
    }
  }
  &.ratio-16_9 {
    .#{$c}__header {
      @include grid-block(2, 10, 1);
    }
    .#{$c}__swiper-main {
      @include grid-block(2, 11, 2);
      .swiper {
        .swiper-slide {
          width: grid-width-m(8);
        }
      }
      .#{$c}__slide-image {
        height: grid-width-m(math.div(8 * 9, 16));
      }
      &.center {
        @include grid-block(2, 10, 2);
        .swiper {
          .swiper-slide {
            width: grid-width-m(10);
          }
        }
        .#{$c}__slide-image {
          height: grid-width-m(math.div(10 * 9, 16));
        }
      }
    }
  }
  &.ratio-12_5 {
    .#{$c}__header {
      @include grid-block(2, 10, 1);
    }
    .#{$c}__swiper-main {
      @include grid-block(2, 11, 2);
      .swiper {
        .swiper-slide {
          width: grid-width-m(8);
        }
      }
      .#{$c}__slide-image {
        height: grid-width-m(math.div(8 * 5, 12));
      }
      &.center {
        @include grid-block(2, 10, 2);
        .swiper {
          .swiper-slide {
            width: grid-width-m(10);
          }
        }
        .#{$c}__slide-image {
          height: grid-width-m(math.div(10 * 5, 12));
        }
      }
    }
  }
  &.ratio-3_2 {
    .#{$c}__header {
      @include grid-block(2, 10, 1);
      margin-right: grid-width-m(2);
    }
    .swiper-slide {
      width: grid-width-m(6);
    }
    .#{$c}__slide-image {
      height: grid-width-m(math.div(6 * 2, 3));
    }
    .#{$c}__swiper-main {
      @include grid-block(2, 10, 2);
      &.center {
        @include grid-block(2, 10, 2);
        .swiper {
          .swiper-slide {
            width: grid-width-m(10);
          }
        }
        .#{$c}__slide-image {
          height: grid-width-m(math.div(10 * 2, 3));
        }
      }
    }
  }
  @include tablet-landscape {
    &__footer {
      @include grid-block(1, 24, 4);
    }
    &__slide {
      &-desc {
        cursor: default;
      }
      &-disclaimer {
        width: grid-width(12);
        left: unset;
        padding: 43px 38px 33px 68px;
      }
    }
    &.center-pagination {
      &.ratio-16_9 {
        .#{$c}__swiper-main {
          &.center {
            .#{$c}__slide-image {
              height: grid-width(math.div(12 * 9, 16));
            }
          }
        }
      }
      &.ratio-12_5 {
        .#{$c}__swiper-main {
          &.center {
            .#{$c}__slide-image {
              height: grid-width(math.div(18 * 5, 12));
            }
          }
        }
      }
    }
    &__center {
      @include grid-block(1, 24, 3);
    }
    &__description {
      @include grid-block(7, 12, 3);
      font-size: 16px;
      &.down {
        @include grid-block(7, 12, 4);
      }
      text-align: center;
      &-desc {
        cursor: default;
      }
      &-disclaimer {
        left: unset;
        > div {
          padding: 43px 38px 33px 68px;
        }
      }
    }
    .center {
      .swiper {
        .#{$c}__nav {
          width: 48px;
          height: 48px;
          border: 1px solid $yellow;
          color: $white;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          cursor: pointer;
          > svg {
            display: block;
            margin: 0 auto;
            transform: translateY(50%);
          }
          &-left {
            left: grid-width(3);
          }
          &-right {
            right: grid-width(3);
          }
        }
      }
    }
    &.ratio-16_9 {
      .#{$c}__header {
        @include grid-block(3, 19, 1);
      }
      .#{$c}__swiper-main {
        @include grid-block(3, 22, 2);
        .swiper {
          .swiper-slide {
            width: grid-width(16);
          }
        }
        .#{$c}__slide-image {
          height: grid-width(math.div(16 * 9, 16));
        }
        &.center {
          @include grid-block(1, 24, 2);
          .swiper {
            .swiper-slide {
              width: grid-width(12);
              .#{$c}__slide-image {
                height: grid-width(math.div(12 * 9, 16));
              }
              &.grid-14 {
                width: grid-width(14);
                .#{$c}__slide-image {
                  height: grid-width(math.div(14 * 9, 16));
                }
              }
              &.grid-16 {
                width: grid-width(16);
                .#{$c}__slide-image {
                  height: grid-width(math.div(16 * 9, 16));
                }
              }
              &.grid-18 {
                width: grid-width(18);
                .#{$c}__slide-image {
                  height: grid-width(math.div(18 * 9, 16));
                }
              }
            }
          }
        }
      }
    }
    &.ratio-12_5 {
      .#{$c}__header {
        @include grid-block(3, 19, 1);
      }
      .#{$c}__swiper-main {
        @include grid-block(1, 24, 2);
        .swiper {
          .swiper-slide {
            width: grid-width(20);
          }
        }
        .#{$c}__slide-image {
          height: grid-width(math.div(18 * 5, 12));
        }
        &.center {
          @include grid-block(1, 24, 2);
          .swiper {
            .swiper-slide {
              width: grid-width(20);
            }
          }
          .#{$c}__slide-image {
            height: grid-width(math.div(18 * 5, 12));
          }
        }
      }
    }
    &.ratio-3_2 {
      .#{$c}__header {
        @include grid-block(3, 19, 1);
        margin-right: grid-width(3);
      }
      .swiper-slide {
        width: grid-width(12);
      }
      .#{$c}__slide-image {
        height: grid-width(math.div(12 * 2, 3));
      }
      .#{$c}__swiper-main {
        @include grid-block(3, 22, 2);
        &.center {
          @include grid-block(1, 24, 2);
          .swiper {
            .swiper-slide {
              width: grid-width(12);
              .#{$c}__slide-image {
                height: grid-width(math.div(12 * 2, 3));
              }
            }
            &.grid-14 {
              width: grid-width(14);
              .#{$c}__slide-image {
                height: grid-width(math.div(14 * 2, 3));
              }
            }
            &.grid-16 {
              width: grid-width(16);
              .#{$c}__slide-image {
                height: grid-width(math.div(16 * 2, 3));
              }
            }
            &.grid-18 {
              width: grid-width(18);
              .#{$c}__slide-image {
                height: grid-width(math.div(18 * 2, 3));
              }
            }
          }
        }
      }
    }
  }
}
</style>
