<template>
  <OverallSettings v-bind="$props">
    <div class="c-image-poster" v-if="fields">
      <div class="c-image-poster__poster">
        <BackgroundImage :image="fields.backgroundImage" />
        <div class="c-image-poster__title">
          <JssRichText :field="fields.title" />
        </div>
      </div>
      <div class="c-image-poster__body">
        <JssRichText :field="fields.body" />
      </div>
    </div>
  </OverallSettings>
</template>
<script>
export default {
  name: 'ImagePoster',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-image-poster {
  &__poster {
    position: relative;
  }
  .e-background-image {
    height: grid-width-m(16);
    width: grid-width-m(8);
    margin: 0 auto;
  }
  &__title {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    text-align: center;
  }
  &__body {
    width: grid-width-m(10);
    margin: $space-40 auto;
  }
  @include tablet-landscape {
    .e-background-image {
      height: grid-width(14);
      width: grid-width(8);
      margin: 0 auto;
    }
    &__body {
      width: grid-width(10);
      margin-top: $space-60;
      margin-left: grid-width(11);
    }
  }
}
</style>
