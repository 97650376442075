<template>
  <div class="c-image-gallery" v-if="fields" :class="{ reverse: fields.reverse.value }" ref="wrapperEl">
    <div class="c-image-gallery__up" ref="upEL">
      <BackgroundImage class="c-image-gallery__image" :style="getWidth(item)" v-for="item in fields.imageListUp" :key="item.id" :image="item.fields.image" />
    </div>
    <div class="c-image-gallery__down" ref="downEL">
      <BackgroundImage class="c-image-gallery__image" :style="getWidth(item)" v-for="item in fields.imageListDown" :key="item.id" :image="item.fields.image" />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from 'vue';
import { settingValue, loadScrollMagic } from '@/utils/site-utils';
import { isDesktop } from '@/utils/dom-utils';
import gsap from 'gsap';
export default {
  name: 'ImageGallery',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let ScrollMagic, controller, scene, tl;
    const state = reactive({
      wrapperEl: null,
      upEL: null,
      downEL: null,
      documentHeight: null
    });
    const methods = {
      imageRatio: (r) => settingValue(r, '16_9'),
      getWidth: (i) => {
        const ratio = i.fields.imageRatio?.fields.phrase.value;
        let h = 0;
        if (isDesktop()) {
          h = state.documentHeight / 2;
        } else {
          h = 135;
        }
        let w = '';
        if (ratio === '1_1') {
          w = h + 'px';
        } else if (ratio === '3_4') {
          w = (h * 3) / 4 + 'px';
        } else if (ratio === '4_3') {
          w = (h * 4) / 3 + 'px';
        } else if (ratio === '3_2') {
          w = (h * 3) / 2 + 'px';
        } else {
          w = (h * 16) / 9 + 'px';
        }
        return { width: w };
      }
    };
    onMounted(() => {
      state.documentHeight = document.documentElement.clientHeight;
      ScrollMagic = loadScrollMagic();
      controller = new ScrollMagic.Controller();
      tl = gsap.timeline();
      if (props.fields.reverse.value) {
        tl.to(
          state.upEL,
          {
            x: -150
          },
          'first'
        ).to(
          state.downEL,
          {
            x: 150
          },
          'first'
        );
      } else {
        tl.to(
          state.upEL,
          {
            x: 150
          },
          'first'
        ).to(
          state.downEL,
          {
            x: -150
          },
          'first'
        );
      }
      scene = new ScrollMagic.Scene({
        triggerElement: state.wrapperEl,
        triggerHook: 0,
        duration: state.wrapperEl.offsetHeight
      })
        .setTween(tl)
        // .setPin(state.wrapperEl)
        .addTo(controller);
    });
    onUnmounted(() => {
      scene.destroy(true);
      controller.destroy(true);
    });
    return {
      ...methods,
      ...toRefs(state)
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-image-gallery {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  > div {
    height: 135px;
    white-space: nowrap;
    .e-background-image {
      height: 100%;
      display: inline-block;
    }
  }

  &__up {
    transform: translateX(-50%);
  }

  &.reverse {
    .c-image-gallery__up {
      transform: translateX(0);
    }

    .c-image-gallery__down {
      transform: translateX(-50%);
    }
  }

  @include tablet-landscape {
    height: 100vh;
    > div {
      flex: 1;
    }
  }
}
</style>
