<template>
  <OverallSettings v-bind="$props">
    <div class="c-image-water-flow" v-if="fields">
      <div v-for="(item, index) in items" :key="index" class="c-image-water-flow__screen-group">
        <div class="c-image-water-flow__screen-group-item" :class="{ reverse: fields.reverse.value }" v-for="side in item" :key="side.id">
          <div class="c-image-water-flow__screen-group-item-image">
            <BackgroundImage :image="side.fields.image" />
          </div>
          <div class="c-image-water-flow__screen-group-item-time">
            <JssRichText :field="side.fields.time" tag="div" />
          </div>
          <div class="c-image-water-flow__screen-group-item-body">
            <JssRichText :field="side.fields.body" tag="div" />
          </div>
          <div class="c-image-water-flow__screen-group-item-btn">
            <SiteButton v-bind="side.fields.button" />
          </div>
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { computed } from 'vue';
export default {
  name: 'ImageWaterFlow',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      items: computed(() => {
        let array = [];
        let tmp = [];
        props.fields.items.forEach((c, i) => {
          tmp.push(c);
          if (i % 2) {
            array.push(tmp);
            tmp = [];
          } else if (i === props.fields.items.length - 1) {
            array.push(tmp);
          }
        });
        return array;
      })
    };
    return {
      ...computes
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-image-water-flow {
  &__screen-group {
    @include grid-container;
    position: relative;
    padding-top: 200px;
    &-item {
      @include grid-block(3, 20);
      &-image {
        width: 100%;
        height: grid-width(math.div(20 * 2, 3));
        margin-bottom: $space-24;
        > div {
          height: 100%;
        }
      }
      &-time {
        font-size: 14px;
        margin-bottom: $space-24;
      }
      &-body {
        @include h5;
        margin-bottom: $space-24;
      }
    }
    @include tablet-landscape {
      width: 100%;
      height: 100vh;
      &-item {
        width: grid-width(9);
        position: absolute;
        &:nth-child(odd) {
          top: 0;
          left: 0;
          &.reverse {
            top: auto;
            bottom: 0;
          }
        }
        &:nth-child(even) {
          bottom: 0;
          right: 0;
          &.reverse {
            top: 0;
            bottom: auto;
          }
        }
        &-image {
          height: grid-width(6);
        }
      }
    }
  }
}
</style>
