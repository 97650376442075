<template>
  <overall-settings class="c-frame-by-frame__overall" :class="[{ overlap: fields.overlap.value }]" v-bind="$props">
    <div class="c-frame-by-frame" ref="rootEl">
      <div class="c-frame-by-frame__body" ref="bodyEl">
        <div class="c-frame-by-frame__wrapper" ref="wrapEl">
          <div :class="['c-frame-by-frame__item', set.animationType]" v-for="(set, index) in frameSet" :key="index" :ref="(el) => (itemEl[index] = el)" :style="{ 'z-index': 100 - index }">
            <img class="c-frame-by-frame__item-image" :src="set.frames[set.frameIndex]" alt="" />
            <div class="c-frame-by-frame__item__content" :ref="(el) => (contentEl[index] = el)">
              <icon class="c-frame-by-frame__item__brand-logo" :field="set.brand_logo" />
              <div class="c-frame-by-frame__item__sub-text" v-html="set.sub_text_first" :ref="(el) => (subTextEl[index] = el)" />
              <div class="c-frame-by-frame__item__start-price" v-html="set.start_price" v-show="index === 0" />
              <div class="c-frame-by-frame__item__primary-text" v-html="set.primary_text" :ref="(el) => (primaryTextEl[index] = el)" />
              <div class="c-frame-by-frame__item__last-text" v-html="set.sub_text_second" v-show="index === 1" ref="lastTextEl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { loadScrollMagic, settingValue } from '@/utils/site-utils';
import { nextTick, onMounted, reactive, toRefs } from 'vue';
import gsap from 'gsap';
import JSZip from 'jszip';

export default {
  name: 'FrameByFrame',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields?.items?.length) return;
    let ScrollMagic, controller, scene, tl;
    const _methods = {
      buildScrollMagic() {
        if (props.fields.overlap.value) {
          state.bodyEl.style.height = `${(state.frameSet.length + 1) * 100 + 40}vh`;
        }
        ScrollMagic = loadScrollMagic();
        controller = new ScrollMagic.Controller();
        tl = gsap.timeline();
        let position = 0;
        for (let i = 0; i < state.frameSet.length; i++) {
          const set = state.frameSet[i];
          const frames = set.frames;
          const frameObj = { frameIndex: 0 };
          const appendFrameAni = (targetFrame, position) => {
            tl.to(
              frameObj,
              {
                ease: 'none',
                frameIndex: targetFrame,
                onUpdate() {
                  state.frameSet[i].frameIndex = parseInt(frameObj.frameIndex);
                }
              },
              position
            );
          };
          switch (set.animationType) {
            case 'middle-center-slide-up':
              {
                appendFrameAni(Math.ceil(frames.length / 2 - 1), 0);
                tl.to(
                  state.contentEl[i],
                  {
                    y: '-40vh',
                    opacity: 0.6
                  },
                  0
                );
                appendFrameAni(frames.length - 1, 1);
                tl.to(
                  state.contentEl[i],
                  {
                    y: '-80vh',
                    opacity: 0.3
                  },
                  ++position
                ).to(
                  state.itemEl[i],
                  {
                    opacity: 0,
                    duration: 1
                  },
                  ++position
                );
              }
              break;
            case 'top-right-fade-in':
              {
                appendFrameAni(Math.ceil(frames.length / 2 - 1), ++position);
                tl.to(
                  state.subTextEl[i],
                  {
                    x: '-10px',
                    opacity: 1
                  },
                  position
                )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: '5px',
                      opacity: 1
                    },
                    position
                  )
                  .to(
                    state.lastTextEl[i],
                    {
                      x: '10px',
                      opacity: 1
                    },
                    position
                  )
                  .to(
                    state.subTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    ++position
                  )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    position
                  )
                  .to(
                    state.lastTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    position
                  );
                appendFrameAni(frames.length - 1, 3);
                tl.to(
                  state.itemEl[i],
                  {
                    opacity: 0,
                    duration: 1
                  },
                  ++position
                );
              }
              break;
            case 'bottom-left-fade-in':
              {
                appendFrameAni(Math.ceil(frames.length / 2 - 1), ++position);
                tl.to(
                  state.subTextEl[i],
                  {
                    x: '10px',
                    opacity: 1
                  },
                  position
                )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: '-10px',
                      opacity: 1
                    },
                    position
                  )
                  .to(
                    state.subTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    ++position
                  )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    position
                  );
                appendFrameAni(frames.length - 1, position);
                tl.to(
                  state.itemEl[i],
                  {
                    opacity: 0,
                    duration: 1
                  },
                  ++position
                );
              }
              break;
            case 'top-left-fade-in':
              {
                appendFrameAni(Math.ceil(frames.length / 2 - 1), ++position);
                tl.to(
                  state.subTextEl[i],
                  {
                    x: '10px',
                    opacity: 1
                  },
                  position
                )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: '-10px',
                      opacity: 1
                    },
                    position
                  )
                  .to(
                    state.subTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    ++position
                  )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    position
                  );
                appendFrameAni(frames.length - 1, position);
                tl.to(
                  state.itemEl[i],
                  {
                    opacity: 0,
                    duration: 1
                  },
                  ++position
                );
              }
              break;
            case 'middle-right-fade-in':
              {
                appendFrameAni(Math.ceil(frames.length / 2 - 1), ++position);
                tl.to(
                  state.subTextEl[i],
                  {
                    x: '-10px',
                    opacity: 1
                  },
                  position
                )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: '10px',
                      opacity: 1
                    },
                    position
                  )
                  .to(
                    state.subTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    ++position
                  )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: 0,
                      opacity: 0
                    },
                    position
                  );
                appendFrameAni(frames.length - 1, position);
                tl.to(
                  state.itemEl[i],
                  {
                    opacity: 0
                  },
                  ++position
                );
              }
              break;
            case 'middle-left-fade-in':
              {
                appendFrameAni(Math.ceil(frames.length / 2 - 1), ++position);
                tl.to(
                  state.subTextEl[i],
                  {
                    x: '10px',
                    opacity: 1
                  },
                  position
                )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: '-10px',
                      opacity: 1
                    },
                    position
                  )
                  .to(
                    state.subTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    ++position
                  )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: 0,
                      opacity: 0
                    },
                    position
                  );
                appendFrameAni(frames.length - 1, position);
                tl.to(
                  state.itemEl[i],
                  {
                    opacity: 0
                  },
                  ++position
                );
              }
              break;
            case 'middle-center-slide-in':
              {
                appendFrameAni(Math.ceil(frames.length / 2 - 1), ++position);
                tl.to(
                  state.subTextEl[i],
                  {
                    x: '10px',
                    opacity: 1
                  },
                  position
                )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: '-10px',
                      opacity: 1
                    },
                    position
                  )
                  .to(
                    state.subTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    ++position
                  )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: 0,
                      opacity: 0
                    },
                    ++position
                  );
                appendFrameAni(frames.length - 1, position);
                tl.to(
                  state.itemEl[i],
                  {
                    opacity: 0
                  },
                  ++position
                );
              }
              break;
            case 'middle-center-fade-in':
              {
                appendFrameAni(frames.length - 1, ++position);
                tl.to(
                  state.subTextEl[i],
                  {
                    x: '0',
                    opacity: 1
                  },
                  position
                ).to(
                  state.primaryTextEl[i],
                  {
                    x: '0',
                    opacity: 1
                  },
                  position
                );
                if (props.fields.overlap.value) {
                  tl.to(
                    state.itemEl[i],
                    {
                      opacity: 0
                    },
                    ++position
                  );
                }
              }
              break;
            case 'bottom-right-slide-in':
              {
                appendFrameAni(Math.ceil(frames.length / 2 - 1), ++position);
                tl.to(
                  state.subTextEl[i],
                  {
                    x: '-10px',
                    opacity: 1
                  },
                  position
                )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: '10px',
                      opacity: 1
                    },
                    position
                  )
                  .to(
                    state.subTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    ++position
                  )
                  .to(
                    state.primaryTextEl[i],
                    {
                      x: '0',
                      opacity: 0
                    },
                    position
                  );
                appendFrameAni(frames.length - 1, position);
                tl.to(
                  state.itemEl[i],
                  {
                    opacity: 0,
                    duration: 1
                  },
                  ++position
                );
              }
              break;
            default:
              break;
          }
        }
        scene = new ScrollMagic.Scene({
          triggerElement: state.bodyEl,
          triggerHook: 0,
          duration: `${state.frameSet.length + 1}00%`
        })
          .setTween(tl)
          .setPin(state.wrapEl)
          .addTo(controller);
      }
    };
    const methods = {};
    const state = reactive({
      rootEl: null,
      bodyEl: null,
      wrapEl: null,
      itemEl: [],
      contentEl: [],
      subTextEl: [],
      primaryTextEl: [],
      lastTextEl: null,
      /**@type Array<Object>*/
      frameSet: []
    });
    const computes = {};
    onMounted(() => {
      const promises = [];
      for (let item of props.fields.items) {
        const { imageZip } = item.fields || {};
        if (!imageZip.value) continue;
        const fetchPromise = fetch(imageZip.value)
          .then(function(response) {
            // 2) filter on 200 OK
            if (response.status === 200 || response.status === 0) {
              return Promise.resolve(response.blob());
            } else {
              return Promise.resolve(new Error(response.statusText));
            }
          })
          .then(JSZip.loadAsync) // 3) chain with the zip promise
          .then(function(zip) {
            const readPromises = [];
            const fileNames = Object.keys(zip.files);
            for (let fileName of fileNames) {
              readPromises.push(zip.file(fileName).async('uint8array'));
            }
            return Promise.all(readPromises);
          })
          .then((data) => {
            const images = [];
            for (let buffer of data) {
              const blob = new Blob([buffer]);
              images.push(window.URL.createObjectURL(blob));
            }
            return images;
          });
        promises.push(fetchPromise);
      }
      Promise.all(promises).then((/**@type Array<Array<String>>*/ frameSet) => {
        const { items } = props.fields;
        for (let i = 0, len = items.length; i < len; i++) {
          let item = items[i];
          const { brand_logo, primary_text, sub_text_first, sub_text_second, start_price, imageZip, animationType } = item.fields || {};
          if (!imageZip.value) continue;
          state.frameSet.push({
            brand_logo: brand_logo,
            animationType: settingValue(animationType, 'middle-right-fade-in'),
            primary_text: primary_text?.value ?? '',
            sub_text_first: sub_text_first?.value ?? '',
            sub_text_second: sub_text_second?.value ?? '',
            start_price: start_price?.value ?? '',
            frames: frameSet[i],
            frameIndex: 0
          });
        }
        nextTick(() => {
          if (state.frameSet.length > 0 && state.frameSet[0].animationType === 'middle-center-slide-up') {
            gsap.fromTo(
              state.contentEl[0],
              {
                yPercent: 0,
                opacity: 0
              },
              {
                yPercent: -50,
                opacity: 1,
                duration: 1.2,
                onComplete() {
                  _methods.buildScrollMagic();
                }
              }
            );
          } else {
            _methods.buildScrollMagic();
          }
        });
      });
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/fontFamily.scss';

.c-frame-by-frame {
  $c: &;
  &__wrapper {
    height: 100vh;
    position: relative;
    background: $black;
  }
  &__item {
    $item: &;
    width: 100vw;
    height: 100vh;
    letter-spacing: 3px;
    position: absolute;
    top: 0;
    left: 0;

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__content {
      position: absolute;
      padding-top: 60px;
      #{$item}__sub-text,
      #{$item}__last-text {
        font-size: 4.5vw;
        color: $white;
      }

      #{$item}__primary-text {
        font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
        font-size: 10vw;
        line-height: 1;
        color: $yellow;
        &:empty {
          display: none;
        }
      }
    }
    &.middle-center-slide-up {
      #{$item}__brand-logo {
        color: $yellow;
        margin-bottom: 20px;
        svg {
          width: 268px;
          height: auto;
        }
      }
      #{$item}__sub-text {
        font-family: lotusFontFamily('Overpass Light'), sans-serif;
        margin-bottom: 20px;
      }
      #{$item}__content {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        #{$item}__start-price {
          font-size: 16px;
          color: $white;
        }
      }
    }

    &.top-right-fade-in {
      #{$item}__content {
        right: 4px;
        top: grid-width-m(2);
        text-align: end;

        #{$item}__sub-text {
          text-align: start;
          transform: translateX(-20px);
          opacity: 0.05;
        }

        #{$item}__primary-text {
          text-align: center;
          opacity: 0.2;
          transform: translateX(10px);
        }

        #{$item}__last-text {
          display: inline-block;
          color: $white;
          transform: translateX(20px);
          text-align: end;
          opacity: 0;
        }
      }
    }

    &.bottom-left-fade-in {
      #{$item}__content {
        left: grid-width-m(1);
        bottom: grid-width-m(1);
        text-align: end;

        #{$item}__sub-text {
          text-align: start;
          transform: translateX(20px);
          opacity: 0;
        }

        #{$item}__primary-text {
          text-align: center;
          opacity: 0.5;
          transform: translateX(-20px);
        }
      }
    }

    &.top-left-fade-in {
      #{$item}__content {
        left: grid-width-m(1);
        top: grid-width-m(2);

        #{$item}__sub-text {
          transform: translateX(20px);
          opacity: 0;
        }

        #{$item}__primary-text {
          text-align: center;
          opacity: 0;
          transform: translateX(-20px);
        }
      }
    }

    &.middle-right-fade-in {
      #{$item}__content {
        right: grid-width-m(1);
        top: 50%;
        transform: translateY(-50%);

        #{$item}__sub-text {
          transform: translateX(-20px);
          opacity: 0;
        }

        #{$item}__primary-text {
          text-align: center;
          opacity: 0;
          transform: translateX(20px);
        }
      }
    }
    &.middle-left-fade-in {
      #{$item}__content {
        left: grid-width-m(1);
        top: 50%;
        transform: translateY(-50%);

        #{$item}__sub-text {
          transform: translateX(20px);
          opacity: 0;
        }

        #{$item}__primary-text {
          text-align: center;
          opacity: 0;
          transform: translateX(-20px);
        }
      }
    }
    &.middle-center-slide-in,
    &.middle-center-fade-in {
      #{$item}__content {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        #{$item}__sub-text {
          transform: translateX(20px);
          opacity: 0;
        }

        #{$item}__primary-text {
          text-align: center;
          opacity: 0;
          transform: translateX(-20px);
        }
      }
    }
    &.bottom-right-slide-in {
      #{$item}__content {
        right: grid-width-m(1);
        bottom: grid-width-m(1);
        text-align: end;

        #{$item}__sub-text {
          text-align: start;
          transform: translateX(-20px);
          opacity: 0;
        }

        #{$item}__primary-text {
          text-align: center;
          opacity: 0.5;
          transform: translateX(20px);
        }
      }
    }
  }
  @include mobile {
    &__item {
      &__primary-text {
        .__f-h2 {
          font-size: 56px;
        }
      }
    }
  }
  @include tablet-landscape {
    &__item {
      $item: &;
      &__content {
        #{$item}__primary-text {
          font-size: 112px;
        }
      }
      &.middle-center-slide-up {
        #{$item}__brand-logo {
          svg {
            width: 50vw;
          }
        }
      }
      &.top-right-fade-in {
        #{$item}__content {
          right: grid-width(1);
          top: grid-width(2);
        }
      }
      &.bottom-left-fade-in {
        #{$item}__content {
          left: grid-width(0.6);
          bottom: 8vh;
        }
      }
      &.top-left-fade-in {
        #{$item}__content {
          left: grid-width(0.6);
          top: grid-width(1.5);
        }
      }
      &.middle-right-fade-in {
        #{$item}__content {
          right: grid-width(0.6);
        }
      }
      &.middle-center-slide-in {
        #{$item}__content {
          left: grid-width(0.6);
        }
      }
      &.bottom-right-slide-in {
        #{$item}__content {
          right: grid-width(0.6);
          bottom: 8vh;
        }
      }
    }
  }
}
html[lang='ja-JP'] {
  .c-frame-by-frame {
    &__item {
      $item: '.c-frame-by-frame__item';
      &__content {
        #{$item}__primary-text {
          font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
        }
      }
      &.middle-center-slide-up {
        #{$item}__sub-text {
          font-family: lotusFontFamily('Noto Sans JP Light'), sans-serif;
        }
      }
    }
  }
}
html[lang='ko-KR'] {
  .c-frame-by-frame {
    &__item {
      $item: '.c-frame-by-frame__item';
      &__content {
        #{$item}__primary-text {
          font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
        }
      }
      &.middle-center-slide-up {
        #{$item}__sub-text {
          font-family: lotusFontFamily('Noto Sans KR Light'), sans-serif;
        }
      }
    }
  }
}
</style>
