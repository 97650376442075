<template>
  <OverallSettings v-bind="$props">
    <div class="c-icon-content-pro" v-if="fields" ref="iconContentProEl">
      <div class="c-icon-content-pro__content" :class="$settingValue(fields.position) === 'right' ? 'c-icon-content-pro__content-active' : ''">
        <div class="c-icon-content-pro__top">
          <div
            class="c-icon-content-pro__top-title"
            :class="$settingValue(fields.position) === 'right' ? 'c-icon-content-pro__top-active-title' : ''"
            :ref="$settingValue(fields.position) === 'right' ? 'activetitleEl' : 'titleEl'"
          >
            <jss-rich-text class="c-icon-content-pro__top-title-ph" :field="fields.titlePh" tag="div" />
            <jss-rich-text class="c-icon-content-pro__top-title-pc" :field="fields.titlePc" tag="div" />
          </div>
          <div
            class="c-icon-content-pro__left"
            :class="$settingValue(fields.position) === 'right' ? 'c-icon-content-pro__left_active' : ''"
            :ref="$settingValue(fields.position) === 'right' ? 'activeleftEl' : 'leftEl'"
          >
            <div class="c-icon-content-pro__image" ref="imageEl">
              <div class="c-icon-content-pro__mask" ref="maskEl"></div>
              <BackgroundImage :image="fields.image" tag="div" />
            </div>
            <div class="c-icon-content-pro__yellow-title" :ref="$settingValue(fields.position) === 'right' ? 'activeyellowTitleEl' : 'yellowTitleEl'">
              <jss-rich-text class="c-icon-content-pro__yellow-title-ph" :field="fields.titlePh" tag="div" />
              <jss-rich-text class="c-icon-content-pro__yellow-title-pc" :field="fields.titlePc" tag="div" />
            </div>
          </div>
        </div>
        <div
          :class="[
            $settingValue(fields.position) === 'right' ? 'c-icon-content-pro__bottom_active' : '',
            fields?.contentBottom?.value && !isMobile() && !isTablet() ? `c-icon-content-pro__bottom1` : `c-icon-content-pro__bottom`
          ]"
          ref="bottomEl"
        >
          <AnimatedContent type="bottom-fade-in" :delay="100">
            <JssText class="c-icon-content-pro__bottom-subtitle" :field="fields.subtitle" tag="div" />
            <jss-rich-text class="c-icon-content-pro__bottom-body" :field="fields.body" tag="div" />
          </AnimatedContent>
          <AnimatedContent type="bottom-fade-in" :delay="100">
            <SiteButton class="c-icon-content-pro__bottom-btn" v-bind="fields.button" />
          </AnimatedContent>
        </div>
      </div>
    </div>
  </OverallSettings>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from 'vue';
import { settingValue, loadScrollMagic } from '@/utils/site-utils';
import { isMobile, isTablet } from '@/utils/dom-utils';
import { debounce } from 'lodash';
import gsap from 'gsap';
export default {
  name: 'IconContentPro',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let ScrollMagic, controller, scene, leftOffset, tl, rightOffset, gridWidth;
    const state = reactive({
      iconContentProEl: null,
      titleEl: null,
      activetitleEl: null,
      imageEl: null,
      yellowTitleEl: null,
      leftEl: null,
      bottomEl: null,
      maskEl: null,
      activeyellowTitleEl: null,
      activeleftEl: null
    });
    const methods = {
      isMobile,
      isTablet,
      resizePageSet: debounce(async (e) => {
        controller.updateScene(scene, true);
      }, 300)
    };

    onMounted(() => {
      ScrollMagic = loadScrollMagic();
      controller = new ScrollMagic.Controller();
      gridWidth = document.documentElement.clientWidth / 24;
      leftOffset = isMobile() ? gridWidth * 0 : gridWidth * 7; //大标题位移
      rightOffset = isMobile() ? gridWidth * 3 : gridWidth * 8; //大标题位移
      tl = gsap.timeline();
      if (`${settingValue(props.fields.position)}` === 'left') {
        tl.to(state.titleEl, { left: isMobile() ? gridWidth * 3 : 0 }, 'first')
          .to(state.bottomEl, { y: -80, opacity: 1 }, 'first')
          .to(state.maskEl, { opacity: 0 }, 'first')
          .to(state.imageEl, { right: 0, scale: 1 }, 'first')
          .to(state.yellowTitleEl, { left: isMobile() ? gridWidth * 3 : -leftOffset }, 'first')
          .to(state.leftEl, { left: leftOffset }, 'first');
      } else {
        tl.to(state.activetitleEl, { left: isMobile() ? gridWidth * 3 : gridWidth * 8 }, 'first')
          .to(state.bottomEl, { y: -80, opacity: 1 }, 'first')
          .to(state.maskEl, { opacity: 0 }, 'first')
          .to(state.imageEl, { right: 0, scale: 1 }, 'first')
          .to(state.activeleftEl, { left: 0 }, 'first')
          .to(state.activeyellowTitleEl, { left: rightOffset }, 'first');
      }
      scene = new ScrollMagic.Scene({
        triggerElement: state.iconContentProEl,
        offset: isMobile() ? -500 : -600,
        triggerHook: 0,
        duration: 600
      })
        .setTween(tl)
        .addTo(controller);
      window.addEventListener('resize', methods.resizePageSet);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', methods.resizePageSet);

      controller.destroy();
      scene.destroy();
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-icon-content-pro {
  $c: &;
  @include grid-container;
  direction: ltr;
  writing-mode: horizontal-tb;
  color: $black;
  overflow: hidden;
  &__over {
    background: $white;
  }
  &__content {
    width: 100%;
    grid-column-start: 2;
    grid-column-end: 13;
    overflow: hidden;
    position: relative;
    #{$c}__top {
      position: relative;
      height: grid-width-m(8);
      &-title {
        position: absolute;
        left: grid-width-m(-1);
        top: 50%;
        width: 100%;
        z-index: 1;
        transform: translateY(-50%);
        font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
        line-height: 1;
        margin-top: 120px;
        & > div {
          @include h3;
          line-height: 1;
        }
        &-pc {
          display: none;
        }
        &-ph {
          display: block;
          p {
            white-space: nowrap;
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
      }
      &-active-title {
        left: grid-width-m(5);
      }
    }
    #{$c}__left {
      position: absolute;
      left: grid-width-m(1);
      z-index: 2;
      width: grid-width-m(11);
      height: grid-width-m(8);
      overflow: hidden;
      #{$c}__image {
        position: absolute;
        left: 0;
        width: grid-width-m(11);
        height: grid-width-m(8);
        > div {
          width: grid-width-m(11);
          height: grid-width-m(8);
        }
        #{$c}__mask {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.6);
          z-index: 1;
        }
        #{$c}__rubber {
          display: none;
        }
      }
      #{$c}__yellow-title {
        position: absolute;
        top: 0;
        left: grid-width-m(-2);
        width: grid-width-m(8);
        height: grid-width-m(8);
        display: flex;
        align-items: center;
        z-index: 3;
        margin-top: 120px;
        & > div {
          @include h3;
          font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
          line-height: 1;
          color: yellow;
        }
        &-pc {
          display: none;
        }
        &-ph {
          display: block;
          p {
            white-space: nowrap;
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    #{$c}__left_active {
      left: grid-width-m(-1);
      #{$c}__yellow-title {
        left: grid-width-m(6);
      }
    }
    #{$c}__bottom {
      padding-right: 32px;
      margin-top: 160px;
      opacity: 0;
      &-icon {
        display: inline-block;
        transform: rotate(270deg);
        color: $yellow;
      }
      &-subtitle {
        @include h3;
        margin: $space-4 0 $space-16 0;
      }
      &-body {
        @include h7;
        color: $grey-neutral;
      }
      &-btn {
        margin-top: $space-40;
        border: 1px solid #000;
      }
    }
  }
  &__content-active {
    grid-column-start: 1;
    grid-column-end: 12;
    #{$c}__left {
      #{$c}__yellow-title {
        margin-top: 125px;
      }
    }
    #{$c}__top {
      &-title {
        margin-top: 125px;
      }
    }
    #{$c}__bottom {
      padding-left: grid-width-m(1);
    }
  }
  @include tablet-landscape {
    width: 100%;
    &__content {
      width: 100%;
      height: 100%;
      grid-column-start: 5;
      grid-column-end: 25;
      overflow: visible;
      #{$c}__top {
        height: grid-width(11);
        padding-right: grid-width(2);
        &-title {
          top: 100px;
          left: grid-width(-4);
          transform: translateY(0);
          margin-top: 0;
          & > div {
            @include h2;
          }
          &-ph {
            display: none;
          }
          &-pc {
            display: block;
            p {
              white-space: nowrap;
              &:first-child {
                margin-bottom: 20px;
              }
            }
          }
        }
        &-active-title {
          left: grid-width(13);
          .c-icon-content-pro__top-title-pc {
            p {
              &:first-child {
                padding-left: grid-width(5);
              }
            }
          }
        }
      }
      #{$c}__left {
        top: 50%;
        left: grid-width(11);
        transform: translateY(-50%);
        width: grid-width(13);
        height: 100%;
        #{$c}__image {
          position: absolute;
          width: grid-width(13);
          height: 100%;
          transform: scale(1.2);
          > div {
            width: grid-width(13);
            height: 100%;
          }
          #{$c}__rubber {
            display: block;
            width: 100%;
            height: 0;
            background: #fff;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
        #{$c}__yellow-title {
          top: 100px;
          left: grid-width(-15);
          transform: translateY(0);
          width: grid-width(13);
          height: 100vh;
          display: block;
          margin-top: 0;
          & > div {
            @include h2;
          }
          &-ph {
            display: none;
          }
          &-pc {
            display: block;
            p {
              white-space: nowrap;
              &:first-child {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
      #{$c}__left_active {
        left: grid-width(-2);
        #{$c}__yellow-title {
          left: grid-width(15);
        }
      }
      #{$c}__bottom1 {
        margin-top: 160px;
        opacity: 0;
        bottom: -80px;
        position: absolute;
        left: grid-width(-1);
        width: grid-width(6);
      }
      #{$c}__bottom {
        position: absolute;
        padding-right: 0;
        bottom: grid-width(2);
        left: grid-width(-1);
        width: grid-width(6);
        &-subtitle {
          @include h4;
          margin: 0 0 $space-16 0;
        }
        &-body {
          font-size: 18px;
          color: $grey-neutral;
        }
        &-btn {
          margin-top: $space-40;
          border: 1px solid #000;
        }
      }
      #{$c}__bottom_active {
        padding: 0;
        left: auto;
        right: grid-width(1);
      }
    }
    &__content-active {
      grid-column-start: 1;
      grid-column-end: 23;
      #{$c}__yellow-title {
        &-pc {
          p {
            &:first-child {
              padding-left: grid-width(5);
            }
          }
        }
      }
    }
  }
  @include desktop {
    &__content {
      #{$c}__bottom {
        bottom: grid-width(1);
      }
      #{$c}__bottom1 {
        margin-top: 160px;
        opacity: 0;
        bottom: -80px;
        position: absolute;
        left: grid-width(-1);
        width: grid-width(6);
      }
    }
  }
  @include desktop-middle {
    &__content {
      #{$c}__bottom {
        bottom: grid-width(1);
      }
      #{$c}__top {
        &-active-title {
          left: grid-width(13);
          .c-icon-content-pro__top-title-pc {
            p {
              &:first-child {
                padding-left: grid-width(4.7);
              }
            }
          }
        }
      }
    }
    &__content-active {
      #{$c}__yellow-title {
        &-pc {
          p {
            &:first-child {
              padding-left: grid-width(4.7);
            }
          }
        }
      }
    }
  }
  @include desktop-large {
    &__content {
      #{$c}__bottom {
        bottom: grid-width(3);
      }
      #{$c}__top {
        &-active-title {
          left: grid-width(13);
          .c-icon-content-pro__top-title-pc {
            p {
              &:first-child {
                padding-left: grid-width(3.6);
              }
            }
          }
        }
      }
    }
    &__content-active {
      #{$c}__yellow-title {
        &-pc {
          p {
            &:first-child {
              padding-left: grid-width(3.6);
            }
          }
        }
      }
    }
  }
}
html.rtl {
  .c-icon-content-pro {
    &__top {
      padding-left: grid-width(2);
      padding-right: 0;
    }
    &__bottom {
      direction: rtl;
    }
    .e-site-button {
      direction: rtl;
    }
  }
}
html[lang='ja-JP'] {
  .c-icon-content-pro {
    $c: '.c-icon-content-pro';
    &__content {
      #{$c}__top {
        &-title {
          font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
        }
      }
      #{$c}__left {
        #{$c}__yellow-title {
          & > div {
            font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
          }
        }
      }
    }
  }
}
html[lang='ko-KR'] {
  .c-icon-content-pro {
    $c: '.c-icon-content-pro';
    &__content {
      #{$c}__top {
        &-title {
          font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
        }
      }
      #{$c}__left {
        #{$c}__yellow-title {
          & > div {
            font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
          }
        }
      }
    }
  }
}
</style>
