<template>
  <div class="c-featureslider">
    <div class="c-featureslider__left" :class="{ active: leftAnimate == true }">
      <JssRichText class="c-featureslider__left-body" :field="list[activeIndex].fields.body" tag="div" />
      <JssText class="c-featureslider__left-title" :field="list[activeIndex].fields.title" tag="div" />
    </div>
    <div class="c-featureslider__swiper" ref="swiperEl">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in list" :key="index">
          <div class="slide-inner" :style="{ backgroundImage: 'url(' + item.fields.image?.value?.src + ')' }"></div>
        </div>
      </div>
      <div class="c-featureslider__pagination">
        <Icon :svg="fields.prevIcon.value.svgCode" class="c-featureslider__pagination-left" size="small" ref="prevEl" />
        <Icon :svg="fields.nextIcon.value.svgCode" class="c-featureslider__pagination-right" size="small" ref="nextEl" />
      </div>
      <div class="c-featureslider__number">
        <span class="c-featureslider__number-first">{{ activeIndex + 1 }}</span>
        <span class="c-featureslider__number-line">|</span>
        <span class="c-featureslider__number-last">{{ list.length }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, nextTick, toRefs, onMounted } from 'vue';
import gsap from 'gsap';
import Swiper from 'swiper';
export default {
  name: 'FeatureSlider',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let swiper = null;
    const state = reactive({
      swiperEl: null,
      prevEl: null,
      nextEl: null,
      list: props.fields.items,
      activeIndex: 0,
      interleaveOffset: 0.5,
      leftAnimate: false
    });
    const initSwiper = () => {
      swiper = new Swiper(state.swiperEl, {
        loop: true,
        speed: 1000,
        grabCursor: true,
        watchSlidesProgress: true,
        mousewheelControl: true,
        keyboardControl: true,
        navigation: {
          prevEl: state.prevEl.rootEl,
          nextEl: state.nextEl.rootEl
        },
        on: {
          progress: function(swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * state.interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector('.slide-inner').style.transform = 'translate3d(' + innerTranslate + 'px, 0, 0)';
            }
          },
          touchStart: function(swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = '';
            }
          },
          setTransition: function(swiper, speed) {
            console.log(2);
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + 'ms';
              swiper.slides[i].querySelector('.slide-inner').style.transition = speed + 'ms';
            }
          },
          transitionStart: function(swiper, speed) {
            state.activeIndex = swiper.realIndex;
            state.leftAnimate = true;
          }
        }
      });
    };

    onMounted(() => {
      nextTick(() => {
        initSwiper();
      });
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-featureslider {
  @include tablet-landscape {
    display: flex;
    align-items: center;
    height: 100vh;
    color: #000;
    &__left {
      display: flex;
      width: grid-width(10);
      height: 100%;
      padding-top: grid-width(1.5);
      background: #fff200;
      &-title {
        @include h3;
        writing-mode: tb-rl;
        padding-right: grid-width(1);
        animation: leftAnimate 2s;
        animation-fill-mode: forwards;
      }
      &-body {
        @include h9;
        padding: 0 grid-width(2);
        text-align: start;
        animation: leftAnimate 2s;
        animation-fill-mode: forwards;
      }
      &.active {
        .c-featureslider__left-title,
        .c-featureslider__left-body {
        }
      }
    }
    &__swiper {
      width: grid-width(12);
      margin-right: grid-width(2);
      height: grid-width(math.div(12 * 3, 4));
      overflow: hidden;
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        overflow: hidden;
      }
      .slide-inner {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-size: cover;
        background-position: center;
      }
    }
    &__pagination {
      position: absolute;
      bottom: grid-width(0.6);
      left: grid-width(2);
      cursor: pointer;
      &-left {
        margin-right: 20px;
      }
      > svg {
        width: 15px;
        height: 21px;
      }
    }
    &__number {
      position: absolute;
      bottom: 0;
      right: grid-width(2);
      color: #fff;
      &-first {
        @include h3;
      }
      &-line {
        position: relative;
        padding: 0 8px;
        transform: rotate(20deg);
      }
      &-last {
        @include h6;
      }
    }
  }
}

@keyframes leftAnimate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
