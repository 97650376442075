<template>
  <overall-settings v-bind="$props">
    <div class="c-faq" v-if="fields">
      <div class="c-faq__content" :class="$settingValue(fields.layout) !== 'right' ? '' : 'c-faq__content--right'">
        <div class="c-faq_left">
          <jss-rich-text class="c-faq-title" :field="fields.title" tag="div" />
          <jss-rich-text class="c-faq-desc" :field="fields.remarks" tag="div" />
          <SiteButton v-bind="fields.button" />
        </div>

        <div class="c-faq_right">
          <div class="c-faq_List_item" v-for="(item, index) in dataList" :key="'faq_List' + index" :class="item.selected ? 'show' : ''">
            <div class="c-faq_List_title" @click="slideClick(index)">
              <div class="c-faq_List_title-text" v-html="item.Question__c" />
              <span class="c-faq_List_title_icon">{{ item.selected ? '-' : '+' }}</span>
            </div>
            <transition :css="false" @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
              <div class="c-faq_List_content" v-show="item.selected" v-html="item.Answer__c" />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef FaqFields
 * @property {GlobalSettingEntry} theme
 * @property {SimpleField} title
 * @property {GlobalSettingEntry} layout
 * @property {SimpleField} remarks
 * @property {SimpleField} button
 * @property {Array<GlobalSettingEntry>} vehicle
 * @property {GlobalSettingEntry} category
 * */
import { getCurrentInstance, onMounted, reactive, toRefs } from 'vue';
import gsap from 'gsap';
import api from '@/api/index';
import { debounce } from 'lodash';
import { gtmPush } from '@/utils/gtm-utils';
import { getPageRegion, settingValue } from '@/utils/site-utils';
import { appendDataStructure } from '@/utils/dom-utils';
import { sitecoreComponentProps } from '@/utils/constants';

export default {
  name: 'Faq',
  props: {
    /**@type FaqFields*/
    fields: {
      type: Object
    },
    ...sitecoreComponentProps
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const page = $jss.routeData();
    const state = reactive({
      dataList: []
    });
    const _methods = {
      async loadCategories() {}
    };
    const methods = {
      slideClick: debounce(async (index) => {
        const selected = !state.dataList[index].selected;

        state.dataList[index].selected = selected;
        gtmPush({
          event: selected ? 'accordion_open_click' : 'accordion_close_click',
          event_label: state.dataList[index].Question__c
        });
      }, 100),
      buttonClick() {},
      onItemBeforeEnter(el) {
        el.style.height = 0;
        el.style.paddingBottom = 0;
      },
      onItemTransEnter(el, done) {
        gsap.to(el, {
          height: el.scrollHeight + 20,
          paddingBottom: 20,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      onItemTransLeave(el, done) {
        gsap.to(el, {
          height: 0,
          paddingBottom: 0,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      }
    };

    onMounted(async () => {
      const region = getPageRegion(props.page ?? page);
      const body = {
        vehicleList: props.fields?.vehicle.map((x) => settingValue(x)),
        category: settingValue(props.fields?.category),
        country: region?.name
      };
      const [res] = await api.crm.getArticles(null, body);
      state.dataList = res?.records || [];
      const data = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: state.dataList.map((x) => ({
          '@type': 'Question',
          name: x.Question__c,
          acceptedAnswer: {
            '@type': 'Answer',
            text: x.Answer__c
          }
        }))
      };
      appendDataStructure(data, props.rendering.dataSource);
      state.dataList.forEach((val) => {
        val.selected = false;
      });
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-faq {
  width: 100%;
  color: #000;
  @include grid-container;
}

.c-faq__content {
  @include grid-block(2, 10);
  display: block;

  .c-faq_left {
    width: 100%;
    padding-right: 0;
    padding-bottom: 23px;

    .c-faq-title {
      font-style: normal;
      font-size: 56px;
      font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;

      p {
        font-style: normal;
        font-size: 56px;
        font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
      }
    }

    em {
      font-style: normal;
      font-size: 56px;
      font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
    }
    .c-faq-title {
      display: block;
      width: 100%;
      height: auto;
      padding: 20px 0;
      // font-size: 14px;
      line-height: 1.3;
    }
    .e-site-button {
      cursor: pointer;
      font-weight: bold;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 15px;
    }
  }
}

.c-faq_List_item {
  width: 100%;
  border-bottom: 1px solid #ddd;

  .c-faq_List_title {
    display: flex;
    width: 100%;
    font-size: 18px;
    cursor: pointer;
    padding: 16px 0;
    align-items: center;

    &-text {
      font-style: normal;
      flex-basis: 100%;
      padding-right: 20px;
    }
    .c-faq_List_title_icon {
      display: inline-block;
      flex-shrink: 0;
      font-size: 22px;
      font-family: 'simHei', sans-serif;
    }
  }
  .c-faq_List_content {
    width: 100%;
    line-height: 1.5;
    font-size: 16px;
    height: 0;
    overflow: hidden;
    img {
      max-width: 100%;
    }
  }
}

@include tablet-landscape {
  .c-faq__content {
    @include grid-block(3, 20);
    display: flex;
    width: 100%;

    .c-faq_left {
      width: 432px;
      flex-shrink: 0;
      padding-right: 130px;

      em {
        font-size: 80px;
      }
      p {
        padding: 30px 0;
      }
      button {
        padding: 16px 0;
        font-size: 16px;
      }
    }
    .c-faq_right {
      flex-basis: 100%;
    }

    &.c-faq__content--right {
      flex-direction: row-reverse;
      .c-faq_left {
        padding-left: 130px;
        padding-right: 0;
      }
    }
  }

  .c-faq_List_item {
    &.show {
      .c-faq_List_content {
        height: auto;
      }
    }
  }
}
html[lang='ja-JP'] {
  .c-faq {
    $c: '.c-faq';
    &__content {
      #{$c}_left {
        #{$c}-title {
          font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
          p {
            font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
          }
        }
        em {
          font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
        }
      }
    }
  }
}
html[lang='ko-KR'] {
  .c-faq {
    $c: '.c-faq';
    &__content {
      #{$c}_left {
        #{$c}-title {
          font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
          p {
            font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
          }
        }
        em {
          font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
        }
      }
    }
  }
}
</style>
