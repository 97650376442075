<template>
  <overall-settings v-bind="$props">
    <div class="c-imageSlider" ref="imageSlider">
      <div class="c-imageSlider__content">
        <section class="c-imageSlider__nextPrev">
          <Icon :svg="fields.prevIcon.value.svgCode" :style="fields.prevIconColor ? 'color:' + fields.prevIconColor.value : ''" ref="prevEl" />
          <Icon :svg="fields.nextIcon.value.svgCode" :style="fields.nextIconColor ? 'color:' + fields.nextIconColor.value : ''" ref="nextEl" />
        </section>

        <section class="c-imageSlider__swiper" ref="imageSlider__swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" :class="{ reverse: item.fields.reverse?.value }" v-for="(item, index) in fields.list" :key="'imageSlider' + index">
              <div
                class="word-tw__article"
                :class="{
                  'word-tw__article--top': item.fields.articlePosition?.fields?.phrase.value === 'Top',
                  'word-tw__article--center': item.fields.articlePosition?.fields?.phrase.value === 'Center',
                  'word-tw__article--bottom': item.fields.articlePosition?.fields?.phrase.value === 'Bottom'
                }"
              >
                <animated-content type="fade-in-top-left">
                  <div class="word-tw__article__icon" v-if="item.fields.icon && item.fields.icon.value && item.fields.icon.value.svgCode">
                    <Icon :svg="item.fields.icon.value.svgCode" :style="item.fields.iconColor ? 'color:' + item.fields.iconColor.value : ''" />
                  </div>
                </animated-content>
                <animated-content type="skew-in" :hide-content="false">
                  <jss-rich-text v-if="item.fields.remarksTitle" class="word-tw__article__remarksTitle" :field="item.fields.remarksTitle" tag="div" />
                </animated-content>
                <animated-content type="fade-in" :hide-content="false">
                  <jss-rich-text v-if="item.fields.remarksWord" class="word-tw__article__remarksWord" :field="item.fields.remarksWord" tag="div" />
                  <SiteButton class="word-tw__article__btn" v-bind="item.fields.button" />
                </animated-content>
              </div>
              <section class="word-tw__image">
                <adaptive-image class="c-imageSlider__imageOnly" :field="item.fields.adaptiveImage" v-if="item.fields.adaptiveImage" />
                <background-image class="c-imageSlider__imageOnly" :image="item.fields.image" v-else />
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, nextTick, onMounted, onUnmounted, computed, toRefs, ref } from 'vue';
import Swiper from 'swiper';

/**
 * @typedef ContentBlockImageFields
 * @property {GlobalSettingEntry} theme
 * */

export default {
  name: 'ImageSlider',
  props: {
    /**@type ImageSliderFields*/
    fields: {
      type: Object
    }
  },
  setup() {
    let swiper = null;
    const state = reactive({
      /**@type {HTMLElement}*/
      imageSlider__swiper: null,
      /**@type {Icon|null}*/
      prevEl: null,
      /**@type {Icon|null}*/
      nextEl: null
    });

    const buildSwiper = () => {
      swiper = new Swiper(state.imageSlider__swiper, {
        spaceBetween: 16,
        navigation: {
          prevEl: state.prevEl?.rootEl,
          nextEl: state.nextEl?.rootEl
        }
      });
    };

    const methods = {};

    onMounted(() => {
      nextTick(() => {
        buildSwiper();
      });
    });
    onUnmounted(() => {
      swiper.destroy(true, true);
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-imageSlider__imageOnly {
  width: 100%;
  height: 100%;
}
.c-imageSlider {
  width: 100%;
  @include grid-container;

  .c-imageSlider__content {
    @include grid-block(2, 10);
  }
}

.c-imageSlider__swiper {
  width: 100%;
  overflow: hidden;
}

.word-tw__article {
  width: 100%;

  .word-tw__article__icon {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0 0 16px 0;

    .e-icon {
      color: #b8b8b8;
      > svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .word-tw__article__remarksTitle {
    position: relative;
    width: 100%;
    font-size: 24px;
    color: #000;
    line-height: 1.1;
  }
  .word-tw__article__remarksWord {
    font-size: 14px;
    color: #4f4f4f;
    line-height: 1.4;
    padding-top: 16px;
  }
  .word-tw__article__btn {
    margin-top: 16px;
  }
}

.word-tw__image {
  position: relative;
  width: 100%;
  height: 61vw;
  overflow: hidden;
  margin: 44px 0 0 0;
}
.c-imageSlider__nextPrev {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  overflow: hidden;
  padding: 0 0 20px 0;
  .e-icon {
    cursor: pointer;
    > svg {
      width: 40px;
      height: auto;
    }
    &.swiper-button-disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}

@include tablet-landscape {
  .c-imageSlider {
    .c-imageSlider__content {
      position: relative;
      @include grid-block(3, 20);
      overflow: hidden;

      .c-imageSlider__swiper {
        .swiper-slide {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &.reverse {
            flex-direction: row-reverse;
            .word-tw__image {
              margin: 0;
              //   margin-left: 0;
              //   margin-right: 10.5%;
            }
          }
        }
      }
    }
  }

  .word-tw__article {
    float: left;
    width: 29%;
    display: flex;
    flex-direction: column;

    &.word-tw__article--top {
      justify-content: flex-start;
    }
    &.word-tw__article--center {
      justify-content: center;
    }
    &.word-tw__article--bottom {
      justify-content: flex-end;
    }

    .word-tw__article__remarksTitle {
      font-size: 32px;
    }
  }
  .word-tw__image {
    float: left;
    width: 60%;
    height: 38vw;
    margin: 0;
    // margin: 0 0 0 10.5%;
  }
}
html.rtl {
  .c-imageSlider__nextPrev {
    flex-direction: row-reverse;
  }
  .word-tw__article {
    float: right;
  }
  .word-tw__image {
    float: right;
    // @include tablet-landscape {
    //   margin: 0 10.5% 0 0;
    // }
  }
}
</style>
