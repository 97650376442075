<template>
  <div class="c-header__space" v-if="fields && !$isOnApp($route) && theme !== 'transparent'"></div>
  <div class="c-header" :class="rootClasses" v-if="fields" v-show="!$isOnApp($route)">
    <Icon class="c-header__logo" :field="fields.logo" ref="prevEl" @click="onLogoClick" />
    <div class="c-header__login" @mouseenter="onLoginMouseenter" @mouseleave="onLoginMouseleave">
      <site-link class="c-header__login-val" v-bind="loginProps" @click="onLoginClick">
        <!--        <icon class="c-header__login-icon" :field="fields.loginIcon" />-->
        <div class="c-header__login-text" v-html="loginText" />
      </site-link>
      <!--      <transition :css="false" @before-enter="onLoginBeforeEnter" @enter="onLoginEnter" @leave="onLoginLeave">-->
      <!--        <div class="c-header__user-panel" v-if="appStore?.hasLoggedIn && showUserPanel">-->
      <!--          <div class="c-header__user-menus">-->
      <!--            <div class="c-header__user-menu" @click="logout">-->
      <!--              <icon class="c-header__user-menu-icon" :field="fields.logoutIcon" />-->
      <!--              <jss-text class="c-header__user-menu-text" :field="fields.logoutText" tag="div" />-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </transition>-->
    </div>
    <div class="c-header__icon" @click="toggle">
      <MenuTrigger :active="false" />
    </div>
    <header-curtain-menu :fields="{ ...fields.curtainMenu?.fields }" :page="page" :rtl="rtl" ref="curtainMenuRef" @close="onCurtainMenuClose" />
  </div>
</template>
<script>
/**
 * @typedef HeaderFields
 * @property {GlobalSettingEntry} theme
 * @property {GlobalSettingEntry} scrollingTheme
 * @property {ImageField} logo
 * @property {ImageField} loginIcon
 * @property {SimpleField} loginText
 * @property {SimpleField} welcomeText
 * @property {ImageField} logoutIcon
 * @property {SimpleField} logoutText
 * @property {{
 *   fields: HeaderCurtainMenuFields
 * }} curtainMenu
 * */
/**
 * @typedef HeaderCurtainMeu
 * @property {LinkField} link
 * */
/**
 * @typedef HeaderLink
 * @property {LinkField} link
 * */
import { reactive, toRefs, onMounted, computed, onBeforeUnmount, inject, watch } from 'vue';
import { settingVal, settingValue } from '@/utils/site-utils';
import BUS_EVENTS from '@/utils/bus-events';
import { getScrollTop, largeThanTabletLandscape } from '@/utils/dom-utils';
import { S_LANG } from '@/utils/web-storage-keys';
import { webStorage } from '@/utils/web-storage';
import { gtmPush } from '@/utils/gtm-utils';
import { debounce, throttle } from 'lodash';
import useAppStore from '@/store/appStore';
import { ifEmpty } from '@/utils/string-utils';
import { appendQuery, getBetterUrl, getUrls } from '@/utils/uri-utils';
import gsap from 'gsap';
import { useRoute } from 'vue-router';
import { qtUtils } from '@/utils/ali-tracker-utils';
export default {
  name: 'Header',
  props: {
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    },
    page: {
      type: Object
    },
    rtl: {
      type: Boolean
    },
    params: null,
    errorComponent: null
  },

  setup(props) {
    if (!props.fields) return;
    const route = useRoute();
    /**@type AppStore*/
    const appStore = useAppStore();
    const $bus = inject('$bus');
    let loginTimeout = null;
    const state = reactive({
      theme: null,
      comTheme: null,
      isMenuOpen: false,
      curtainMenuRef: null,
      showUserPanel: false,
      visible: true
    });
    const computes = {
      rootClasses: computed(() => [
        {
          [`theme-${state.theme}`]: state.theme,
          hide: !state.visible
        }
      ]),
      loginText: computed(() => {
        const { loginText, welcomeText } = props.fields;
        // if (appStore?.hasLoggedIn) {
        //   let template = welcomeText?.value;
        //   if (isNullOrWhitespace(template)) template = '##name##';
        //   return formatString(template, {
        //     name: appStore.nickName ?? ''
        //   });
        // }
        return loginText?.value;
      }),
      loginProps: computed(() => {
        const { path } = route;
        let [loginUrl, myOrderUrl] = getUrls(props.page, 'loginLink', 'myOrderLink');
        if (new RegExp(path, 'gi').test(loginUrl) || new RegExp(path, 'gi').test(myOrderUrl)) {
          return null;
        }
        if (/\/(payment-history|my-details|login)$/gi.test(path)) return null;
        loginUrl = getBetterUrl(loginUrl);
        myOrderUrl = getBetterUrl(myOrderUrl);
        let href = myOrderUrl;
        if (!appStore?.hasLoggedIn) {
          href = appendQuery(loginUrl, {
            redirect_url: myOrderUrl
          });
        }
        return {
          link: {
            value: {
              isExternal: false,
              href
            }
          }
        };
      })
    };
    const methods = {
      async toggle() {
        state.isMenuOpen = !state.isMenuOpen;
        if (state.isMenuOpen) {
          gtmPush({
            event: 'menu_open'
          });
          await state.curtainMenuRef.open();
        } else {
          gtmPush({
            event: 'menu_close'
          });
          await state.curtainMenuRef.close();
        }
        qtUtils.trackClick('header_clk', {
          page_name: 'public_event',
          button_id: 'menu_trigger',
          button_name: 'menu_trigger'
        });
      },
      onCurtainMenuClose(e) {
        state.isMenuOpen = false;
      },
      onScroll: throttle(() => {
        const scrollTop = getScrollTop();
        const $footer = document.querySelector('.c-footer');
        const footerRect = $footer?.getBoundingClientRect();
        state.visible = scrollTop <= 60 || state.scrollTop > scrollTop || footerRect?.bottom <= window.innerHeight;
        state.scrollTop = scrollTop;
        if (scrollTop <= 50) {
          state.theme = settingVal(props.page.fields.headerTheme, props.fields.theme);
        } else {
          state.theme = ifEmpty(state.comTheme, settingValue(props.fields.scrollingTheme));
        }
      }, 30),
      onLogoClick: debounce(() => {
        const { origin } = window.location;
        const lang = webStorage.get(S_LANG);
        gtmPush({
          event: 'logo_click'
        });
        window.location.href = getBetterUrl('/', props.page.itemLanguage, true);
      }, 100),
      logout() {
        appStore.setLoginInfo(null);
      },
      onLoginMouseenter() {
        if (loginTimeout) {
          clearTimeout(loginTimeout);
        }
        state.showUserPanel = true;
      },
      onLoginMouseleave() {
        if (loginTimeout) {
          clearTimeout(loginTimeout);
        }
        loginTimeout = setTimeout(() => {
          state.showUserPanel = false;
        }, 400);
      },
      onLoginBeforeEnter(el) {
        el.style.opacity = 0;
        const $menus = el.querySelectorAll('.c-header__user-menu');
        for (let $menu of $menus) {
          $menu.style.height = 0;
          $menu.style.opacity = 0;
        }
      },
      onLoginEnter(el, done) {
        let tick = 0;
        const tl = gsap.timeline();
        const $menus = el.querySelectorAll('.c-header__user-menu');
        const tryDone = () => {
          tick += 1;
          if (tick === 2) {
            done();
          }
        };
        tl.to(
          el,
          {
            opacity: 1,
            duration: 0.2,
            onComplete() {
              tryDone();
            }
          },
          0
        );
        $menus.forEach(($menu, index) => {
          const targetHeight = $menu.scrollHeight + 10;
          tl.to(
            $menu,
            {
              opacity: 1,
              height: targetHeight,
              duration: 0.2,
              delay: index * 0.1,
              onComplete() {
                if (index === $menu.length - 1) {
                  tryDone();
                }
              }
            },
            0
          );
        });
      },
      onLoginLeave(el, done) {
        let tick = 0;
        const tl = gsap.timeline();
        const $menus = el.querySelectorAll('.c-header__user-menu');
        const tryDone = () => {
          tick += 1;
          if (tick === 2) {
            done();
          }
        };
        $menus.forEach(($menu, index) => {
          tl.to(
            $menu,
            {
              opacity: 0,
              height: 0,
              duration: 0.2,
              delay: ($menu.length - index - 1) * 0.1,
              onComplete() {
                if (index === 0) {
                  tryDone();
                }
              }
            },
            0
          );
        });
        tl.to(
          el,
          {
            opacity: 0,
            duration: 0.2,
            onComplete() {
              tryDone();
            }
          },
          0
        );
      },
      onLoginClick() {
        if (!largeThanTabletLandscape() && appStore?.hasLoggedIn) {
          $bus.emit(BUS_EVENTS.OPEN_PERSONAL_CENTER_BAR);
        }
      }
    };
    watch(
      () => settingVal(props.page.fields.headerTheme, props.fields.theme),
      (theme) => {
        state.theme = theme;
      },
      {
        deep: true,
        immediate: true
      }
    );
    $bus.on(BUS_EVENTS.UPDATE_HEADER_THEME, (theme) => {
      state.comTheme = theme;
    });
    onMounted(() => {
      window.addEventListener('scroll', methods.onScroll);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', methods.onScroll);
    });
    return {
      ...computes,
      ...toRefs(state),
      ...methods,
      appStore
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-header {
  $c: &;
  --bg-color: #{$black};
  --logo-color: #{$yellow};
  --icon-bg-color: #{$yellow};
  --icon-color: #{$white};
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: mobile-header-height();
  z-index: 11;
  transition: all 600ms cubic-bezier(0, 0, 0.4, 0.2);
  background-color: var(--bg-color);
  &.hide {
    top: -#{mobile-header-height()};
  }
  &__logo {
    height: fit-content;
    position: absolute;
    left: calc(50% - 49px);
    transition: all 600ms ease;
    color: var(--logo-color);
    cursor: pointer;
    svg {
      width: 128px !important;
      height: auto !important;
    }
  }
  &__login {
    margin-right: 24px;
    position: absolute;
    color: $white;
    left: 16px;
    &-text {
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 8px;
    }
    &-val {
      display: inline-flex;
      justify-self: flex-end;
      cursor: pointer;
      color: var(--logo-color);
      line-height: 24px;
      &:visited {
        color: var(--logo-color);
      }
    }
  }
  &__user-panel {
    position: absolute;
    top: mobile-header-height();
    background: $black;
    padding: 16px;
    box-shadow: 0 0 5px rgba($white, 0.1);
    min-width: 100%;
    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: 20px;
      border-bottom: 5px solid $black;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
  &__user-menu {
    display: flex;
    align-items: center;
    color: $white;
    cursor: pointer;
    overflow: hidden;
    &-text {
      margin-left: 8px;
      min-width: 100px;
    }
    &:hover {
      color: $yellow;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 54px;
    background: var(--icon-bg-color);
    transition: all 600ms ease;
    span {
      background: var(--icon-color) !important;
      transition: all 600ms ease;
      &:nth-child(2) {
        width: 13px;
      }
      &:nth-child(3) {
        width: 7px;
      }
    }
  }
  &.theme-black {
    --bg-color: #{$black};
    --logo-color: #{$yellow};
    --icon-color: #{$black};
    --icon-bg-color: #{$yellow};
  }
  &.theme-white {
    --bg-color: #{$white};
    --logo-color: #{$yellow};
    --icon-color: #{$black};
    --icon-bg-color: #{$yellow};
  }
  &.theme-yellow {
    --bg-color: #{$yellow};
    --logo-color: #{$black};
    --icon-color: #{$yellow};
    --icon-bg-color: #{$black};
  }
  &.theme-transparent {
    --bg-color: transparent;
    --logo-color: #{$white};
    --icon-color: #{$white};
    --icon-bg-color: transparent;
  }
  &__space {
    height: mobile-header-height();
  }
  &__font {
    position: fixed;
    top: -100px;
    left: -100px;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    &.headlines {
      font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
    }
    &.headlines-ex-light {
      font-family: lotusFontFamily('Overpass Lotus Headlines Exlight'), sans-serif;
    }
  }
  @include tablet-landscape {
    height: tablet-header-height();
    &__login {
      position: relative;
      left: auto;
      &-text {
        display: block;
      }
    }
    &__user-panel {
      top: tablet-header-height() + 10px;
    }
    &__icon {
      width: grid-width(1);
      .e-menu-trigger {
        width: 22px;
        span {
          &:first-child {
            width: 22px;
          }
          &:nth-child(2) {
            width: 17px;
          }
          &:nth-child(3) {
            width: 12px;
          }
        }
      }
    }
    &__space {
      height: tablet-header-height();
    }
    &.hide {
      top: -#{tablet-header-height()};
    }
  }
  @include desktop {
    height: desktop-header-height();
    &.hide {
      top: -#{desktop-header-height()};
    }
    &__user-panel {
      top: desktop-header-height();
    }
    &__icon {
      .e-menu-trigger {
        width: 22px;
        span {
          &:first-child {
            width: 22px;
          }
          &:nth-child(2) {
            width: 17px;
          }
          &:nth-child(3) {
            width: 12px;
          }
        }
      }
    }
    &__space {
      height: 64px;
    }
  }
}
html.market-detector-open {
  .c-header {
    top: 292px;
  }
  @include desktop {
    .c-header {
      top: 85px;
    }
  }
}
html.rtl {
  .c-header {
    &__login {
      left: auto;
      right: 16px;
      margin-right: 0;
    }
    &__logo {
      left: calc(50% - 57px);
    }
    @include tablet-landscape {
      &__login {
        left: 16px;
        right: auto;
      }
    }
  }
}
</style>
