<template>
  <OverallSettings v-bind="$props">
    <div class="c-image-drawer" v-if="fields">
      <div class="c-image-drawer__left" ref="wrapperEl">
        <div class="c-image-drawer__title" :class="$settingValue(fields.leftTitleLayout)" ref="outLeftTitle">
          <JssRichText :field="fields.leftTitleOut" />
        </div>
        <adaptive-image class="c-image-drawer__image" :field="fields.leftAdaptiveImage" :fallback-image="fields.leftImage">
          <div class="c-image-drawer__image-title" :class="$settingValue(fields.leftTitleLayout)" ref="inLeftTitle">
            <JssRichText :field="fields.leftTitleIn" />
          </div>
          <div class="c-image-drawer__image-btn"><SiteButton v-bind="fields.leftButton" /></div>
        </adaptive-image>
      </div>
      <div class="c-image-drawer__right">
        <div class="c-image-drawer__title" :class="$settingValue(fields.rightTitleLayout)" ref="outRightTitle">
          <JssRichText :field="fields.rightTitleOut" />
        </div>
        <adaptive-image class="c-image-drawer__image" :field="fields.rightAdaptiveImage" :fallback-image="fields.rightImage">
          <div class="c-image-drawer__image-title" :class="$settingValue(fields.rightTitleLayout)" ref="inRightTitle">
            <JssRichText :field="fields.rightTitleIn" />
          </div>
          <div class="c-image-drawer__image-btn"><SiteButton v-bind="fields.rightButton" /></div>
        </adaptive-image>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from 'vue';
import { appVisible, loadScrollMagic } from '@/utils/site-utils';
import { isMobile } from '@/utils/dom-utils';
import gsap from 'gsap';
import { useRoute } from 'vue-router';
export default {
  name: 'ImageBower',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const route = useRoute();
    if (!appVisible(route, props.fields)) return;
    let ScrollMagic, controller, scene, tl;
    const state = reactive({
      rootEl: null,
      wrapperEl: null,
      outLeftTitle: null,
      inLeftTitle: null,
      outRightTitle: null,
      inRightTitle: null
    });
    onMounted(() => {
      ScrollMagic = loadScrollMagic();
      controller = new ScrollMagic.Controller();
      const offset = state.wrapperEl.getBoundingClientRect();
      tl = gsap.timeline();
      if (isMobile()) {
        tl.to(state.outLeftTitle, { y: offset.height - 300 }, 'first')
          .to(state.inLeftTitle, { y: offset.height - 300 }, 'first')
          .to(state.outRightTitle, { y: offset.height - 300 }, 'first')
          .to(state.inRightTitle, { y: offset.height - 300 }, 'first');
      } else {
        tl.to(state.outLeftTitle, { y: offset.height - 420 }, 'first')
          .to(state.inLeftTitle, { y: offset.height - 420 }, 'first')
          .to(state.outRightTitle, { y: offset.height - 420 }, 'first')
          .to(state.inRightTitle, { y: offset.height - 420 }, 'first');
      }
      scene = new ScrollMagic.Scene({
        triggerElement: state.wrapperEl,
        offset: 10,
        triggerHook: 0,
        duration: state.wrapperEl.offsetHeight / 2
      })
        .setTween(tl)
        .addTo(controller);
    });
    onUnmounted(() => {
      scene.destroy(true);
      controller.destroy(true);
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/fontFamily.scss';
.c-image-drawer {
  @include grid-container;
  overflow: hidden;
  &__left {
    @include grid-block(2, 10);
  }
  &__right {
    @include grid-block(2, 10);
    margin-top: $space-40;
  }
  > div {
    height: grid-width-m(math.div(10 * 3, 2));
    position: relative;
    text-align: center;
    .e-background-image {
      height: 100%;
    }
    .c-image-drawer {
      &__title {
        width: 100vw;
        font-size: 100px;
        position: absolute;
        top: 0;
        left: grid-width-m(-1);
        padding-top: $space-60;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      &__image-title {
        width: 100vw;
        font-size: 100px;
        position: absolute;
        top: 0;
        left: grid-width-m(-1);
        padding-top: $space-60;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      &__image-btn {
        width: grid-width-m(8);
        position: absolute;
        bottom: $space-60;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    .e-background-image__content {
      height: 100%;
    }
  }
  .e-background-image__img.background-image + .e-background-image__content {
    overflow: visible;
    height: 100%;
  }
  @include tablet-landscape {
    > div {
      height: grid-width(12);
      .c-image-drawer {
        &__title {
          width: grid-width(11);
          font-size: 180px;
          left: grid-width(-1);
        }
        &__image-title {
          width: grid-width(11);
          font-size: 180px;
          left: grid-width(-1);
        }
        &__image-btn {
          width: fit-content;
        }
      }
    }
    &__left {
      @include grid-block(3, 9);
      height: grid-width(math.div(9 * 4, 3));
    }
    &__right {
      @include grid-block(14, 9);
      height: grid-width(math.div(9 * 4, 3));
      margin-top: $space-300;
    }
  }
  @include desktop-large {
    &__title {
      font-size: 220px;
    }
    &__image-title {
      font-size: 220px;
    }
  }
}
</style>
