<template>
  <overall-settings v-bind="$props">
    <div class="c-hero-landing" :class="[$deviceState.browser.toLowerCase(), $deviceState.os.toLowerCase()]">
      <div class="swiper" ref="swiperEl">
        <div class="swiper-wrapper">
          <hero-landing-slide class="swiper-slide" v-for="slide in fields.slides" :key="slide.id" v-bind="slide" />
        </div>
        <div class="swiper-pagination" ref="paginationEl" v-show="fields.slides?.length > 1" />
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { defineComponent, nextTick, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import Swiper from 'swiper';
import { destroySwiper } from '@/utils/site-utils';
/**
 * @typedef HeroLandingFields
 * @property {Array<{fields: HeroLandingSlideFields}>} slides
 * */
export default defineComponent({
  name: 'HeroLanding',
  props: {
    /**@type {HeroLandingFields}*/
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  setup() {
    let /**@type Swiper*/ swiper;
    const state = reactive({
      swiperEl: null,
      paginationEl: null
    });
    const computes = {};
    const methods = {
      buildSwiper() {
        destroySwiper(swiper, true, true);
        swiper = new Swiper(state.swiperEl, {
          slidesPerView: 1,
          observer: true,
          pagination: {
            el: state.paginationEl,
            clickable: true,
            bulletElement: 'div'
            // renderBullet(index, className) {
            //   return renderBullet(index, className, props.fields.slides?.length ?? 1);
            // }
          }
        });
      }
    };
    onMounted(() => {
      nextTick(() => {
        methods.buildSwiper();
      });
    });
    onBeforeUnmount(() => {
      destroySwiper(swiper, true, true);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
});
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-hero-landing {
  .swiper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    color: $white;
    .swiper-pagination {
      position: absolute;
      bottom: 24px;
      z-index: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 $space-12;
      gap: $space-12;
      &-bullet {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: $white;
        cursor: pointer;
        &-active {
          background: $yellow;
        }
      }
    }
  }
  &__slide {
    width: 100%;
    height: 100%;
  }
  &.safari.ios {
    .swiper {
      height: calc(100vh - 100px);
    }
  }
}
</style>
