<template>
  <OverallSettings v-bind="$props">
    <div class="c-home-range" v-if="fields && resize" ref="rootEl">
      <AdaptiveMedia class="c-home-range__background" :class="{ show: index === activeIndex }" v-for="(item, index) in fields.tabs" :key="item.id" :field="item.fields.media">
        <div class="c-home-range__main">
          <div class="c-home-range__tabs" :class="`valign-${settingValue(fields.tabsValign) ?? 'top'}`">
            <JssRichText class="c-home-range__des" :field="fields.description" />
            <div class="c-home-range__tabs-item" :class="{ active: index === activeIndex }" v-for="(item, index) in fields.tabs" :key="item.id" @click="changeTab(index)">
              <JssRichText :field="item.fields.title" />
            </div>
          </div>
          <div class="c-home-range__details" :class="[gridLayoutClass(fields.gridLayout, '2-20'), { reverse: fields.detailsReverse.value }]" v-if="!changeTabs">
            <AnimatedContent class="c-home-range__details-des" type="fade-in" :delay="200" offset="110%">
              <JssRichText :field="item.fields.description" />
            </AnimatedContent>
            <AnimatedContent class="c-home-range__details-btn" type="bottom-fade-in" :delay="400" offset="110%" v-if="largeThanMobile()">
              <SiteButton v-bind="item.fields.button" />
            </AnimatedContent>
          </div>
          <AnimatedContent class="c-home-range__details-btn mobile" type="bottom-fade-in" :delay="400" offset="110%" v-if="!largeThanMobile()">
            <SiteButton v-bind="item.fields.button" />
          </AnimatedContent>
        </div>
      </AdaptiveMedia>
    </div>
  </OverallSettings>
</template>
<script>
import { toRefs, reactive, onMounted, onUnmounted } from 'vue';
import { gridLayoutClass, settingValue } from '@/utils/site-utils';
import { largeThanMobile } from '@/utils/dom-utils';
import useDevice from '@/hooks/useDevice';
import { debounce } from 'lodash';
export default {
  name: 'HomeRange',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const { deviceState } = useDevice();
    const state = reactive({
      rootEl: null,
      activeIndex: 0,
      changeTabs: false,
      resize: true
    });

    const methods = {
      largeThanMobile,
      gridLayoutClass,
      settingValue,
      changeTab: debounce((i) => {
        state.changeTabs = true;
        state.activeIndex = i;
        setTimeout(() => {
          state.changeTabs = false;
        }, 10);
      }, 100),
      onResize: () => {
        state.resize = false;
        setTimeout(() => {
          state.resize = true;
        }, 20);
      }
    };
    onMounted(() => {
      document.addEventListener('resize', methods.onResize);
    });
    onUnmounted(() => {
      document.removeEventListener('resize', methods.onResize);
    });
    return {
      ...toRefs(state),
      deviceState,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-home-range {
  position: relative;
  height: 100vh;
  .e-adaptive-media {
    .e-adaptive-media__image {
      background-color: transparent;
    }
    .e-adaptive-media__main {
      background-color: transparent;
    }
  }
  &__des {
    padding-left: 8px;
  }
  &__main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    margin-top: grid-width-m(1);
  }
  &__background {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    display: none;
    img {
      width: 100%;
      height: 100%;
    }
    &.show {
      animation: fade-in 1s forwards;
      display: block;
    }
    .e-adaptive-media__content {
      display: flex;
      flex-direction: column;
    }
  }
  &__tabs {
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    margin-left: grid-width-m(1);
    &-item {
      opacity: 0.35;
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
      &.active {
        opacity: 1;
      }
    }
    &.valign {
      &-top {
        justify-content: flex-start;
      }
      &-middle {
        justify-content: center;
      }
      &-bottom {
        justify-content: flex-end;
      }
    }
  }
  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: grid-width-m(1);
    @each $item in $gridLayouts {
      $st: nth($item, 1);
      $len: nth($item, 2);
      &.grid-layout-#{$st}-#{$len} {
        margin-left: grid-width-m(math.div($st, 2));
        max-width: grid-width-m(math.div($len, 2));
      }
    }
    &-btn {
      margin: 0 auto;
      width: grid-width-m(10);
      .e-site-button {
        width: 100%;
      }
      &.mobile {
        margin-top: grid-width-m(-1);
        padding-bottom: grid-width-m(1);
      }
    }
  }
  @include tablet {
    &__tabs {
      margin-left: grid-width(2);
      &.center {
        top: unset;
        bottom: 35%;
      }
    }
    &__details {
      &-btn {
        width: fit-content;
        margin: 0;
      }
    }
  }
  @include tablet-landscape {
    &__main {
      margin-top: grid-width(1);
    }
    &__des {
      padding-left: 16;
    }
    &__details {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      margin-bottom: grid-width(1);
      @each $item in $gridLayouts {
        $st: nth($item, 1);
        $len: nth($item, 2);
        &.grid-layout-#{$st}-#{$len} {
          margin-left: grid-width($st);
          max-width: grid-width($len);
        }
      }
      &.reverse {
        flex-direction: row;
      }
      &-des {
        max-width: grid-width(7);
      }
      &-btn {
        margin: 0;
      }
    }
  }
}
</style>
