<template>
  <div class="c-footer" :class="rootClasses" v-if="fields" v-show="!$isOnApp($route)">
    <div class="c-footer__header">
      <Icon class="c-footer__header-image" :svg="fields.image.value.svgCode" ref="prevEl" />
    </div>
    <div class="c-footer__subscribe">
      <jss-rich-text class="c-footer__subscribe-title" :field="fields.subscribeTitle" tag="div" />
      <jss-rich-text class="c-footer__subscribe-body" :field="fields.subscribeBody" tag="div" />
    </div>
    <div class="c-footer__btn-wrapper">
      <site-button v-bind="fields.subscribeButton" />
    </div>
    <div class="c-footer__categories">
      <div class="c-footer__category" v-for="cate in fields.categories" :key="cate.id">
        <div class="c-footer__category-header">
          <jss-text class="c-footer__category-title" :field="cate.fields.title" tag="div" />
          <accordion-toggle :active="openedIds?.includes(cate.id)" />
        </div>
        <div class="c-footer__menus" :class="[{ 'two-columns': cate.fields.towColumns.value, active: openedIds?.includes(cate.id) }]">
          <div class="c-footer__menu" v-for="menu in cate.fields.menus" :key="menu.id">
            <span @click="onManageCookie" v-if="menu.fields.manageCookie?.value">{{ menu.fields.link.value?.text }}</span>
            <site-link :link="menu.fields.link" data-event_name="footer_link_click" v-else />
          </div>
        </div>
      </div>
    </div>
    <div class="c-footer__social-medias">
      <social-medias :items="fields.socialMedias" link-event-name="footer_social_link_click" />
    </div>
    <div class="c-footer__bottom">
      <div class="c-footer__links">
        <template v-for="bottomLink in fields.bottomLinks" :key="bottomLink.id">
          <span class="c-footer__link" @click="onManageCookie" v-if="bottomLink.fields.manageCookie?.value">{{ bottomLink.fields.link.value?.text }}</span>
          <site-link class="c-footer__link" :link="bottomLink.fields.link" data-event_name="footer_bottom_link_click" v-else />
        </template>
      </div>
      <div class="c-footer__region">
        <span class="c-footer__region-name" @click="openRegionModal">{{ regionName }}</span>
        <icon class="c-footer__region-icon" name="down" @click="openRegionModal" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @typedef FooterFields
 * @property {GlobalSettingEntry} theme
 * @property {Array<FooterCategory>} categories
 * @property {Array<SocialMedia>} socialMedias
 * @property {SimpleField} subscribeTitle
 * @property {SimpleField} subscribeBody
 * @property {ButtonField} subscribeButton
 * @property {Array<FooterMenu>} bottomLinks
 * */
/**
 * @typedef FooterCategory
 * @property {{
 *   title: SimpleField,
 *   menus: Array<FooterMenu>,
 *   towColumns: CheckField
 * }} fields
 * */
/**
 * @typedef FooterMenu
 * @property {{
 *   link: LinkField
 * }} fields
 * */
import { computed, inject, reactive, toRefs } from 'vue';
import { getRegionName, themeClass } from '@/utils/site-utils';
import BUS_EVENTS from '@/utils/bus-events';
export default {
  name: 'FooterPro',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    const $bus = inject('$bus');

    const regionName = getRegionName(props.page);
    const state = reactive({
      openedIds: [],
      regionName
    });
    const computes = {
      rootClasses: computed(() => [themeClass(props, 'night-blue')])
    };
    const methods = {
      onManageCookie() {
        global.OneTrust.ToggleInfoDisplay();
      },
      openRegionModal() {
        $bus.emit(BUS_EVENTS.OPEN_REGION_MODAL);
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-footer {
  $c: &;
  @include component-themes;
  padding: grid-width-m(1);
  position: relative;
  z-index: 1;
  margin-top: -1px;
  &__header {
    text-align: center;
    margin-bottom: grid-width-m(1);
    padding: grid-width-m(1) 0 grid-width-m(1);
    border-bottom: 2px solid yellow;
    &-image {
      svg {
        width: 64px !important;
        height: 64px !important;
      }
    }
  }
  &__subscribe {
    &-title {
      @include h4;
      color: yellow;
    }
    &-body {
      @include h7;
      margin-top: 20px;
    }
    .e-site-button {
      width: 100%;
      margin-top: 30px;
      a {
        width: 100%;
      }
    }
  }
  &__btn-wrapper {
    margin-top: $space-16;
    .e-site-button {
      border: 1px solid $yellow !important;
      background-color: $black !important;
      color: $yellow !important;
      padding: 0 24px 0 24px !important;
      &:hover {
        color: $black !important;
        background-color: $yellow !important;
      }
    }
  }
  &__categories {
    margin-left: -$space-16;
  }
  &__category {
    margin-top: 40px;
    margin-left: $space-16;
    &-header {
      .e-accordion-toggle {
        display: none;
      }
    }
    &-title {
      @include h7;
      color: yellow;
    }
  }
  &__menus {
    margin-top: 26px;
  }
  &__menu {
    cursor: pointer;
    + #{$c}__menu {
      margin-top: 10px;
    }
  }
  &__social-medias {
    margin-top: grid-width-m(1);
    color: $yellow;
  }
  &__bottom {
    border-top: 1px solid yellow;
    padding: $space-20 0;
    font-size: 12px;
    text-align: center;
    margin-top: 60px;
  }
  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: -$space-16;
    margin-top: -$space-8;
  }
  &__link {
    display: inline-block;
    margin-left: $space-16;
    margin-top: $space-8;
    text-align: start;
  }
  &__region {
    padding: 8px 0 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__region-name {
    margin-right: $space-16;
    hyphens: auto;
    cursor: pointer;
  }
  &__region-icon {
    border: 1px solid $yellow;
    color: $yellow;
    padding: 4px;
    cursor: pointer;
    > svg {
      width: 10px;
      height: 10px;
    }
  }
  @include tablet {
    &__header {
      &-image {
        svg {
          width: 70px !important;
          height: 70px !important;
        }
      }
    }
    &__categories {
      display: flex;
      flex-wrap: wrap;
      margin-left: -$space-40;
    }
    &__category {
      margin-left: $space-40;
    }
    &__bottom {
      padding: 25px 0 60px;
    }
    &__region {
      width: auto;
      margin-top: -$space-16;
    }
  }
  @include tablet-landscape {
    @include grid-container;
    padding: 0;
    #{$c}__header {
      @include grid-block(3, 20, 1);
      margin: 0 0 $space-40 0;
      padding: grid-width(1.4) 0;
      &-image.size-small {
        svg {
          width: grid-width(1.3);
          height: grid-width(1.3);
        }
      }
    }
    &__subscribe {
      @include grid-block(3, 6, 2);
    }
    &__btn-wrapper {
      @include grid-block(3, 6, 3);
      margin-top: $space-20;
    }
    &__categories {
      @include grid-block(10, 13, 3);
      margin-top: -6px;
      display: flex;
      flex-wrap: wrap;
    }
    &__menus {
      width: toRem(225);
      overflow: visible;
      max-height: unset;
      transition: none;
      &.active {
        max-height: unset;
      }
      &.two-columns {
        width: toRem(400);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: toRem(20);
        grid-row-gap: 10px;
        #{$c}__menu {
          + #{$c}__menu {
            margin-top: 0;
          }
        }
      }
    }
    &__social-medias {
      @include grid-block(3, 6, 4);
      margin-top: $space-20;
    }
    &__bottom {
      @include grid-block(3, 20, 5);
      text-align: start;
      padding: 25px 0 60px;
      display: flex;
    }
    &__links {
      flex-grow: 1;
      margin-left: -$space-40;
      margin-top: -$space-16;
    }
    &__link {
      display: inline-block;
      margin-left: $space-40;
      margin-top: $space-16;
      cursor: pointer;
    }
    &__region {
      flex-shrink: 0;
      width: grid-width(5);
      margin: 0 0 0 grid-width(1);
      padding: 0 0 0 $space-16;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
    &__region-name {
      margin-right: $space-16;
      hyphens: auto;
      cursor: pointer;
    }
    &__region-icon {
      border: 1px solid $yellow;
      color: $yellow;
      padding: 4px;
      cursor: pointer;
      > svg {
        width: 10px;
        height: 10px;
      }
    }
  }
  @include desktop {
    #{$c}__header {
      @include grid-block(3, 20, 1);
      margin: 0 0 56px 0;
      padding: 56px 0;
    }
    &__btn-wrapper {
      .e-site-button {
        &__icon {
          display: none;
        }
        &:hover {
          background: $yellow !important;
          color: $black !important;
        }
      }
    }
    &__links {
      display: block;
    }
  }
  @include desktop-large {
    &__categories {
      @include grid-block(11, 12, 3);
      margin-top: -6px;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
html.rtl {
  .c-footer {
    &__categories,
    &__category,
    &__links,
    &__link {
      margin-left: 0;
      margin-right: 16px;
    }
    &__region {
      padding: 16px 16px 0 0;
      &-name {
        margin-left: 16px;
        margin-right: 0;
      }
    }
    @include tablet-landscape {
      &__links {
        margin-right: 0;
        margin-left: 16px;
      }
      &__link {
        margin-right: 0;
        margin-left: 16px;
      }
      &__region {
        padding-top: 0;
        margin-left: 0;
      }
      &__bottom {
        padding: 25px 0 60px 0;
      }
    }
  }
}
</style>
