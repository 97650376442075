<template>
  <overall-settings v-bind="$props">
    <div class="c-file-download">
      <heading class="c-file-download__title" :field="fields.title" :type="fields.titleType" rich />
      <jss-rich-text class="c-file-download__body" :field="fields.body" />
      <div class="c-file-download__file">
        <jss-image class="c-file-download__file-img" :media="fields.poster" />
        <div class="c-file-download__file-info">
          <jss-rich-text class="c-file-download__file-name" :field="fields.fileName" />
          <div class="c-file-download__file-extra" v-if="fields.file?.value">
            {{ fields.file?.value?.extension?.toUpperCase() }}&nbsp;&nbsp;/&nbsp;&nbsp;{{ formatFileSize(fields.file.value.size) }}&nbsp;&nbsp;/&nbsp;&nbsp;
            <a :href="fields.file.value.src" target="_blank" :download="`${fields.fileName.value}`"><i class="fal fa-lg fa-arrow-down-to-line"/></a>
          </div>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs } from 'vue';

export default {
  name: 'FileDownload',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const methods = {
      formatFileSize(size) {
        size = Number(size);
        return size > 1000000 ? Math.floor((size * 10) / 1000000) / 10 + 'MB' : size > 1000 ? Math.floor((size * 10) / 1000) / 10 + 'KB' : size + 'bytes';
      }
    };
    const state = reactive({
      ext: null,
      size: null
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-file-download {
  padding: 0 24px;
  color: $grey-neutral;
  &__title {
    margin-bottom: 24px;
  }
  &__body {
    margin-bottom: 60px;
  }
  &__file {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &-extra {
      font-size: 12px;
    }
    &-img {
      width: 75px;
      height: auto;
      margin-right: 24px;
      box-shadow: 0 3px 10px rgb(0 0 0 / 15%);
    }
    &-name {
      font-size: 16px;
      max-width: 120px;
      margin-top: 2px;
    }
    &:hover {
      background: $grey-light;
    }
  }
  @include tablet-landscape {
    @include grid-container;
    &__title {
      @include grid-block(3, 20, 1);
      margin-bottom: 100px;
    }
    &__body {
      @include grid-block(3, 10, 2);
      margin-bottom: 0;
    }
    &__file {
      @include grid-block(17, 6, 2);
      justify-content: flex-start;
    }
  }
  @include desktop-large {
    &__title {
      @include grid-block(5, 14, 1);
    }
    &__body {
      @include grid-block(5, 10, 2);
    }
    &__file {
      @include grid-block(17, 4, 2);
    }
  }
}
.c-uk-cards {
  $cd: '.c-uk-cards';
  &_column {
    .c-file-download {
      padding: 0;
    }
  }
}
</style>
