<template>
  <overall-settings v-bind="$props" v-if="fields?.title?.value || fields?.body?.value || fields?.items?.length || fields?.button">
    <div class="c-hero-support" :class="[...rootClasses]">
      <jss-rich-text :field="fields.title" class="c-hero-support__title" />
      <jss-rich-text :field="fields.body" class="c-hero-support__body" />
      <div class="c-hero-support__list">
        <hero-support-item v-for="item in fields.items" :key="item.id" v-bind="item" :class="itemClasses" />
      </div>
      <site-button class="c-hero-support__btn" v-bind="fields.button" />
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef HeroSupportFields
 * @property {GlobalSettingEntry} theme
 * @property {SimpleField} title
 * @property {GlobalSettingEntry} titleType
 * @property {SimpleField} body
 * @property {GlobalSettingEntry} contentAlign
 * @property {GlobalSettingEntry} itemAlign
 * @property {Array<HeroSupportItem>} items
 * @property {GlobalSettingEntry} theme
 * @extends OverallSettingFields
 * */
import { computed } from 'vue';
import { settingValue, themeClass } from '@/utils/site-utils';

/**
 * @typedef HeroSupportItem
 * @property {HeroSupportItemFields} fields
 */
export default {
  name: 'HeroSupport',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      rootClasses: computed(() => {
        const contentAlignVal = settingValue(props.fields?.contentAlign);
        return [
          themeClass(props),
          {
            [`content-align-${contentAlignVal}`]: contentAlignVal
          }
        ];
      }),
      itemClasses: computed(() => {
        const itemAlign = settingValue(props.fields.itemAlign);
        return [
          {
            [`item-align-${itemAlign}`]: itemAlign
          }
        ];
      })
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-hero-support {
  $c: &;
  @include component-themes;
  padding: grid-width-m(1);
  &__title {
    @include h2;
    letter-spacing: -10px;
    font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
    margin-bottom: $space-20;
  }
  &__body {
    @include h7;
    color: $grey-next;
    margin-bottom: $space-40;
  }
  &.content-align- {
    &center {
      #{$c}__title {
        text-align: center;
      }
      #{$c}__body {
        text-align: center;
      }
      #{$c}__list {
        justify-content: center;
      }
    }
    &right {
      #{$c}__title {
        text-align: end;
      }
      #{$c}__body {
        text-align: end;
      }
      #{$c}__list {
        justify-content: flex-end;
      }
    }
  }
  @include tablet-landscape {
    padding: grid-width(1) grid-width(2);
    &__title {
      margin-bottom: $space-56;
    }
    &__body {
      margin-bottom: $space-56;
    }
    &__list {
      display: flex;
      grid-template-columns: repeat(3, 1fr);
      flex-wrap: wrap;
      gap: 40px;
      .s-hero-support-item {
        width: calc((100% - 80px) / 3);
      }
    }
    &__btn {
      margin-top: $space-40;
    }
  }
}
html[lang='ja-JP'] {
  .c-hero-support {
    &__title {
      font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
    }
  }
}
html[lang='ko-KR'] {
  .c-hero-support {
    &__title {
      font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
    }
  }
}
</style>
