<template>
  <overall-settings v-bind="$props">
    <!-- <div class="c-image-swiper" :class="[{ 'turn-off-image-animation': fields.turnOffImageAnimation?.value }]" ref="rootEl"> -->
    <div class="c-image-swiper" ref="rootEl">
      <div class="c-image-swiper__content">
        <div class="c-image-swiper__swiper-grid">
          <div class="c-image-swiper__swiper-container" :class="[`grid-layout-${$settingValue(fields.slideLayout, '0-24')}`]" @mouseleave="onSwiperMouseleave">
            <div class="swiper" ref="swiperEl">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="slide in fields.slides" :key="slide.id">
                  <div class="swiper-slide-image">
                    <animated-content class="c-image-swiper__animated-content" :type="null" :duration="2000">
                      <adaptive-image class="c-image-swiper__image" is-background :lazy-load="false" :field="slide.fields.image" />
                    </animated-content>
                  </div>
                </div>
              </div>
            </div>
            <icon class="c-image-swiper__nav-left" name="left" />
            <icon class="c-image-swiper__nav-right" name="right" />
          </div>
        </div>
        <div class="c-image-swiper__pagination">
          <div class="c-image-swiper__bullet" :class="[{ active: activeIndex === index }]" v-for="(slide, index) in fields.slides" :key="slide.id" @click="(e) => changeSlide(e, index)">
            {{ $padding(index + 1) }}
          </div>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef ImageSwiperFields
 * @property {Array<ImageSwiperSlide>} slides
 * @property {GlobalSettingEntry} slideLayout
 * */
/**
 * @typedef ImageSwiperSlide
 * @property {{
 *   image: ImageField
 * }} fields
 * */
import { nextTick, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import { debounce } from 'lodash';
import Swiper from 'swiper';
import { getCursorPosition, largeThanTabletLandscape } from '@/utils/dom-utils';
import { gridWidth, gridWidthMobile, loadScrollMagic } from '@/utils/site-utils';
export default {
  name: 'ImageSwiper',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let swiper = null,
      scrollMagic,
      controller,
      scene;
    const state = reactive({
      /**@type HTMLElement*/
      rootEl: null,
      /**@type HTMLElement*/
      swiperEl: null,
      /**@type HTMLElement*/
      leftIconEl: null,
      /**@type HTMLElement*/
      rightIconEl: null,
      activeIndex: 0,
      clientX: 0,
      clientY: 0
    });
    const methods = {
      changeSlide(e, index) {
        if (swiper) {
          swiper.slideToLoop(index, 1000, false);
        }
      },
      hideArrows() {
        const leftIconEl = state.rootEl.querySelector('.c-image-swiper__nav-left');
        const rightIconEl = state.rootEl.querySelector('.c-image-swiper__nav-right');
        leftIconEl.style.display = 'none';
        rightIconEl.style.display = 'none';
      },
      onMousemove(e) {
        if (largeThanTabletLandscape()) {
          [state.clientX, state.clientY] = getCursorPosition(e);
          methods.checkIconVisible();
        }
      },
      checkIconVisible() {
        if (!state.rootEl) return;
        const containerEl = state.rootEl.querySelector('.c-image-swiper__swiper-container .swiper');
        const leftIconEl = state.rootEl.querySelector('.c-image-swiper__nav-left');
        const rightIconEl = state.rootEl.querySelector('.c-image-swiper__nav-right');
        const rect = containerEl.getBoundingClientRect();
        const { clientX, clientY } = state;
        const leftGap = clientX - rect.left,
          rightGap = rect.right - clientX;
        if (clientY < rect.top + 36 || clientY > rect.bottom - 36) {
          leftIconEl.style.display = 'none';
          rightIconEl.style.display = 'none';
          return;
        }
        if (leftGap > 36 && leftGap < rect.width / 2 && clientY + 36 < rect.bottom) {
          leftIconEl.style.display = 'block';
          const leftIconRect = leftIconEl.getBoundingClientRect();
          leftIconEl.style.left = clientX - leftIconRect.width / 2 + 'px';
          leftIconEl.style.top = clientY - leftIconRect.height / 2 + 'px';
        } else {
          leftIconEl.style.display = 'none';
        }
        if (rightGap > 36 && rightGap < rect.width / 2 && clientY + 36 < rect.bottom) {
          rightIconEl.style.display = 'block';
          const rightIconRect = rightIconEl.getBoundingClientRect();
          rightIconEl.style.left = clientX - rightIconRect.width / 2 + 'px';
          rightIconEl.style.top = clientY - rightIconRect.height / 2 + 'px';
        } else {
          rightIconEl.style.display = 'none';
        }
      },
      onSwiperMouseleave: debounce((e) => {
        methods.hideArrows();
      }, 100),
      onScroll: debounce(() => {
        methods.checkIconVisible();
      }, 100)
    };
    onMounted(async () => {
      window.addEventListener('mousemove', methods.onMousemove);
      window.addEventListener('scroll', methods.onScroll);
      if (state.rootEl) {
        scrollMagic = loadScrollMagic();
        controller = new scrollMagic.Controller();
        scene = new scrollMagic.Scene({
          triggerElement: state.rootEl,
          triggerHook: 0,
          duration: state.rootEl.offsetHeight
        });
        scene.addTo(controller);

        await nextTick();
        swiper = new Swiper(state.swiperEl, {
          observer: true,
          speed: 1000,
          slidesPerView: 1,
          loop: true,
          loopAdditionalSlides: 1,
          spaceBetween: gridWidthMobile(1),
          on: {
            slideChange() {
              state.activeIndex = swiper?.realIndex ?? 0;
            }
          },
          navigation: {
            prevEl: state.rootEl.querySelector('.c-image-swiper__nav-left'),
            nextEl: state.rootEl.querySelector('.c-image-swiper__nav-right')
          },
          breakpoints: {
            768: {
              spaceBetween: gridWidth(1)
            }
          }
        });
      }
    });
    onBeforeUnmount(() => {
      window.removeEventListener('mousemove', methods.onMousemove);
      window.removeEventListener('scroll', methods.onScroll);
      if (swiper) {
        swiper.destroy(true, true);
      }
      if (scene) {
        scene.destroy();
        scene = null;
        controller.destroy();
        controller = null;
      }
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-image-swiper {
  $c: &;
  overflow: hidden;
  &__swiper-container {
    margin-left: grid-width-m(1);
    overflow: hidden;
  }
  .swiper {
    width: grid-width-m(10);
    overflow: visible;
    margin-left: 0;
    &-slide {
      height: calc(70vh - 80px);
      overflow: hidden;
      .swiper-slide-image {
        height: 100%;
      }
      .e-background-image {
        transition: all 400ms ease;
        transform: scale(1);
      }
      &.swiper-slide-duplicate {
        .e-animated-content {
          &__main {
            &:before {
              display: none;
            }
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
  &__animated-content {
    width: 100%;
    height: 100%;
    .e-animated-content__main {
      width: 100%;
      height: 100%;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
  }
  &__pagination {
    display: flex;
    justify-content: flex-end;
    padding: $space-24 0 $space-24 $space-24;
    margin-left: grid-width-m(1);
    margin-right: grid-width-m(1);
    gap: $space-20;
  }
  &__bullet {
    font-size: 16px;
    color: $grey-neutral;
    cursor: pointer;
    user-select: none;
    &.active {
      color: $black;
    }
  }
  &__nav-left,
  &__nav-right {
    &.e-icon {
      display: none;
    }
  }
  @include tablet-landscape {
    &__nav-left,
    &__nav-right {
      position: fixed;
      z-index: 1;
      padding: 10px;
      border: 1px solid $yellow;
      color: $white;
      display: none;
      cursor: pointer;
      > svg {
        width: 28px !important;
        height: 28px !important;
      }
      &.swiper-button-disabled {
        opacity: 0;
        z-index: -1;
        cursor: default;
      }
      &.nav-ani- {
        &enter-active,
        &leave-active {
          transition: 600ms ease;
        }
        &enter-to,
        &leave-from {
          transform: scale(1);
        }

        &enter-from,
        &leave-to {
          transform: scale(0.5);
        }
      }
    }
    .swiper-slide {
      height: grid-width(11);
    }
    &__pagination {
      margin-left: grid-width(1);
      margin-right: grid-width(1);
    }
    &__swiper-container {
      &.grid-layout-0-24 {
        width: 100%;
        .swiper {
          width: 100%;
        }
        //.swiper-slide {
        //  height: grid-width(7);
        //}
      }
      &.grid-layout-1-22 {
        margin-left: grid-width(1);
        .swiper {
          width: grid-width(22);
        }
        //.swiper-slide {
        //  height: grid-width(7);
        //}
      }
      &.grid-layout-2-14 {
        margin-left: grid-width(2);
        .swiper {
          width: grid-width(14);
        }
        //.swiper-slide {
        //  height: grid-width(7);
        //}
      }
      &.grid-layout-2-20 {
        margin-left: grid-width(2);
        .swiper {
          width: grid-width(20);
        }
        //.swiper-slide {
        //  height: grid-width(7);
        //}
      }
      &.grid-layout-2-22 {
        margin-left: grid-width(2);
        .swiper {
          width: grid-width(22);
        }
        //.swiper-slide {
        //  height: grid-width(7);
        //}
      }
      &.grid-layout-6-12 {
        margin-left: grid-width(6);
        .swiper {
          width: grid-width(12);
        }
        //.swiper-slide {
        //  height: grid-width(7);
        //}
      }
      &.grid-layout-8-14 {
        margin-left: grid-width(8);
        .swiper {
          width: grid-width(14);
        }
        //.swiper-slide {
        //  height: grid-width(7);
        //}
      }
    }
  }
  @include desktop {
    .swiper {
      &-slide {
        .e-background-image {
          transition: all 400ms ease;
          transform: scale(1);
        }
      }
    }
    &__swiper-container {
      &:hover {
        .swiper {
          &-slide {
            .e-background-image {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    &.turn-off-image-animation {
      #{$c}__swiper-container {
        &:hover {
          .swiper {
            &-slide {
              .e-background-image {
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }
}
</style>
