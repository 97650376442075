<template>
  <OverallSettings v-bind="$props">
    <div class="c-image-cards" v-if="fields">
      <div class="c-image-cards__content">
        <AdaptiveBackImage class="c-image-cards__content-item" v-for="item in fields.items" :key="item.id" :pc-image="item.fields.backgroudPC" :mobile-image="item.fields.backgroudMobile">
          <div class="c-image-cards__content-item-title">
            <JssRichText :field="item.fields.title" />
          </div>
          <div class="c-image-cards__content-item-button">
            <SiteButton v-bind="item.fields.button" />
          </div>
        </AdaptiveBackImage>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
export default {
  name: 'ImageCards',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/color.scss';
@import '../styles/rte/fontSize.scss';
@import '../styles/rte/fontFamily.scss';
.c-image-cards {
  @include grid-container;
  &__content {
    @include grid-block(2, 11);
    padding-bottom: $space-100;
    display: flex;
    flex-direction: column;
    &-item {
      width: 100%;
      height: grid-width-m(11);
      .e-background-image__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &-title {
        font-weight: 250;
        font-size: 64px;
        line-height: 1.5;
      }
      &-button {
        margin-top: $space-24;
      }
    }
  }
  @include tablet-landscape {
    &__content {
      @include grid-block(4, 21);
      flex-direction: row;
      &-item {
        height: 640px;
        .e-background-image__content {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        &-title {
          font-size: 80px;
          transform: translateY(50%);
        }
        &-button {
          margin-top: 0;
          transform: translateY(100%);
          opacity: 0;
        }
        &:hover {
          .c-image-cards__content-item-title {
            animation: title-up 0.8s forwards;
          }
          .c-image-cards__content-item-button {
            animation: bottom-fade-in 0.8s forwards;
          }
        }
      }
    }
  }
  @keyframes title-up {
    0% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes bottom-fade-in {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
