<template>
  <overall-settings v-bind="$props">
    <div class="c-ImageWithMarqueeText" ref="imageWithMarqueeText" :class="{ 'c-ImageWithMarqueeText--pc': contentStatus == 2 }">
      <div class="trigger-content" ref="trigger_content"></div>

      <div class="kv-slide-bg--page" ref="kvslidebgpage"><kv-slide-bg :image="fields.image" /></div>

      <section class="c-ImageWithMarqueeText__outside" :class="{ 'c-ImageWithMarqueeText__outside--animate': addStatus == 1 }" ref="cic_mt_content">
        <span class="c-ImageWithMarqueeText__topTitle" v-if="fields.topTitle" ref="topTitle" :class="{ 'title-center': step1, 'title-right': step2 }">
          <jss-rich-text v-if="fields.topTitle" :field="fields.topTitle" tag="div" />
        </span>

        <span class="c-ImageWithMarqueeText__bottomTitle" v-if="fields.bottomTitle" ref="bottomTitle" :class="{ 'title-center': step1, 'title-left': step2 }">
          <jss-rich-text v-if="fields.bottomTitle" :field="fields.bottomTitle" tag="div" />
        </span>

        <section class="c-ImageWithMarqueeText__content">
          <section class="c-ImageWithMarqueeText__main">
            <div class="c-ImageWithMarqueeText__article" ref="iconTitleContent">
              <animated-content type="fade-in-top-left">
                <div class="c-ImageWithMarqueeText__article__icon" v-if="fields.icon && fields.icon.value">
                  <Icon :svg="fields.icon.value.svgCode" :style="fields.iconColor ? 'color:' + fields.iconColor.value : ''" />
                </div>
              </animated-content>

              <animated-content type="skew-in" :hide-content="false">
                <jss-rich-text v-if="fields.remarksTitle" class="c-ImageWithMarqueeText__remarksTitle" :field="fields.remarksTitle" tag="div" />
              </animated-content>

              <animated-content type="fade-in" :hide-content="false">
                <jss-rich-text v-if="fields.remarksWord" class="c-ImageWithMarqueeText__remarksWord" :field="fields.remarksWord" tag="div" />
              </animated-content>
            </div>
          </section>
        </section>
      </section>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef ImageWithMarqueeTextFields
 * @property {GlobalSettingEntry} theme
 * */

import { reactive, toRefs, onMounted, onUnmounted, ref, nextTick } from 'vue';
import { isDesktop, isDesktopLarge, isTabletLandscape } from '@/utils/dom-utils';
import { loadScrollMagic, getGlobalConfigs } from '@/utils/site-utils';
import { debounce } from 'lodash';

export default {
  name: 'ImageWithMarqueeText',
  props: {
    /**@type ImageWithMarqueeTextFields*/
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    const [rtl] = getGlobalConfigs(props.page, 'rtl');
    let ScrollMagic, controller, scene;
    const state = reactive({
      trigger_content: null,
      imageWithMarqueeText: null,
      iconTitleContent: null,
      cic_mt_content: null,
      topTitle: null,
      bottomTitle: null,

      addStatus: 0,
      kvslidebgpage: null,

      step1: false,
      step2: false,

      contentStatus: 1
    });

    const methods = {
      isDesktop: isDesktop,
      isDesktopLarge: isDesktopLarge,
      isTabletLandscape: isTabletLandscape,

      resizePageSet: debounce(async (e) => {
        methods.pageClassInit();

        await nextTick(() => {
          controller?.updateScene(scene, true);
        });
      }, 300),

      pageClassInit() {
        let compHeight = state.imageWithMarqueeText?.clientHeight,
          windowHeight = window.innerHeight;

        if (compHeight <= windowHeight) {
          state.contentStatus = 2;
        } else {
          state.contentStatus = 1;
        }
      },

      pageInit() {
        if (!state.imageWithMarqueeText) return;
        ScrollMagic = loadScrollMagic();
        controller = new ScrollMagic.Controller();

        scene = new ScrollMagic.Scene({
          triggerElement: state.imageWithMarqueeText,
          offset: isDesktop() || isDesktopLarge() || isTabletLandscape() ? -64 : -50,
          triggerHook: 0,
          duration: state.imageWithMarqueeText.offsetHeight * 0.7
          // duration: state.imageWithMarqueeText.offsetHeight
        })
          .setPin(state.imageWithMarqueeText)
          .addTo(controller);

        scene.on('progress', (e) => {
          if (isDesktop() || isDesktopLarge() || isTabletLandscape()) {
            const docWidth = state.imageWithMarqueeText.clientWidth,
              topTitle_width = state.topTitle.clientWidth,
              topTitle_left = docWidth * e.progress;

            if (rtl?.value) {
              if (topTitle_width + topTitle_left <= docWidth) {
                state.topTitle.style['margin-right'] = e.progress * 100 + '%';
                state.bottomTitle.style['margin-left'] = e.progress * 100 + '%';
              } else {
                state.topTitle.style['margin-right'] = docWidth - topTitle_width + 'px';
                state.bottomTitle.style['margin-left'] = docWidth - topTitle_width + 'px';
              }
            } else {
              if (topTitle_width + topTitle_left <= docWidth) {
                state.topTitle.style['margin-left'] = e.progress * 100 + '%';
                state.bottomTitle.style['margin-right'] = e.progress * 100 + '%';
              } else {
                state.topTitle.style['margin-left'] = docWidth - topTitle_width + 'px';
                state.bottomTitle.style['margin-right'] = docWidth - topTitle_width + 'px';
              }
            }
          } else {
            if (rtl?.value) {
              state.topTitle.style['margin-right'] = e.progress * 100 + '%';
              state.bottomTitle.style['margin-left'] = e.progress * 100 + '%';
            } else {
              state.topTitle.style['margin-left'] = e.progress * 100 + '%';
              state.bottomTitle.style['margin-right'] = e.progress * 100 + '%';
            }
          }
        });
      }
    };

    onMounted(() => {
      methods.pageClassInit();

      nextTick(() => {
        methods.pageInit();
        window.addEventListener('resize', methods.resizePageSet);
      });
    });

    onUnmounted(() => {
      window.removeEventListener('resize', methods.resizePageSet);

      controller && controller.destroy();
      scene && scene.destroy();
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.trigger-content {
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 110%;
  overflow: hidden;
  opacity: 0;
}
.kv-slide-bg--page {
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.c-ImageWithMarqueeText {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;

  &.c-ImageWithMarqueeText--pc {
    height: 100vh;
  }
}
.c-ImageWithMarqueeText__outside {
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 3;
  //transform: translateY(100%);
  //transform-origin: top center;
  //transition: transform 1s ease;

  &.c-ImageWithMarqueeText__outside--animate {
    transform: translateY(0);
  }
}
.c-ImageWithMarqueeText__topTitle {
  display: block;
  position: relative;
  font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
  padding: 60px 0 0 0;
  font-size: 90px;
  color: #fff200;
  line-height: 1;
  white-space: nowrap;
  letter-spacing: -10px;
  transform: translateX(-50%);
  clear: both;
  float: left;
  left: 0;

  &.title-center {
    transform: translateX(-50%);
  }
  &.title-right {
    transform: none;
  }
}

.c-ImageWithMarqueeText__bottomTitle {
  display: block;
  position: relative;
  font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
  padding: 10px 0 0 0;
  transform: translateX(50%);
  font-size: 90px;
  color: #fff200;
  line-height: 1;
  letter-spacing: -10px;
  clear: both;
  float: right;
  right: 0;

  &.title-center {
    transform: translateX(50%);
  }
  &.title-left {
    transform: none;
  }
}
.c-ImageWithMarqueeText__content {
  position: relative;
  width: 100%;
  padding: 50px 0 40px 0;
  @include grid-container;
  clear: both;

  .c-ImageWithMarqueeText__main {
    @include grid-block(2, 10);
  }
}
.c-ImageWithMarqueeText__article {
  position: relative;
  width: 100%;

  .c-ImageWithMarqueeText__article__icon {
    position: relative;
    width: 20px;
    height: 20px;
    margin-bottom: 16px;

    .e-icon {
      color: #fff;
      > svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .c-ImageWithMarqueeText__remarksTitle {
    width: 100%;
    font-size: 32px;
    color: #fff200;
    line-height: 1.1;
    padding-left: 20px;
  }
  .c-ImageWithMarqueeText__remarksWord {
    font-size: 14px;
    color: #fff;
    line-height: 1.4;
    padding-top: 16px;
    padding-left: 20px;
  }
}

@include tablet-landscape {
  .c-ImageWithMarqueeText {
    height: 100vh;
    min-height: 1000px;
  }

  .trigger-content {
    height: 130%;
  }

  .c-ImageWithMarqueeText__outside {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 3;
  }

  .c-ImageWithMarqueeText__topTitle {
    padding: 60px 0 0 0;
    transform: none;
    font-size: 220px;
  }
  .c-ImageWithMarqueeText__bottomTitle {
    transform: none;
    font-size: 220px;
  }
  .c-ImageWithMarqueeText__content {
    width: 100%;
    @include grid-container;
    // transition: all 1.4s ease;
    padding: 60px 0 50px 0;
    float: none;

    .c-ImageWithMarqueeText__main {
      @include grid-block(3, 20);
    }

    &.c-ImageWithMarqueeText__content--center {
      bottom: 50%;
      transform: translateY(50%);
    }
    &.c-ImageWithMarqueeText__content--top {
      bottom: 94%;
      transform: translateY(100%);
    }
  }
  .c-ImageWithMarqueeText__article {
    width: grid-width(6);
  }
}
html.rtl {
  .c-ImageWithMarqueeText {
    &__topTitle {
      float: right;
      transform: translateX(50%);
    }
    &__bottomTitle {
      float: left;
      transform: translateX(-50%);
    }
    @include tablet-landscape {
      &__topTitle {
        transform: translateX(0);
      }
      &__bottomTitle {
        transform: translateX(0);
      }
    }
  }
}
html[lang='ja-JP'] {
  .c-ImageWithMarqueeText {
    &__topTitle {
      font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
    }

    &__bottomTitle {
      font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
    }
  }
}
html[lang='ko-KR'] {
  .c-ImageWithMarqueeText {
    &__topTitle {
      font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
    }

    &__bottomTitle {
      font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
    }
  }
}
</style>
