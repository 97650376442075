<script setup>
/**
 * @typedef {NewsletterFields} KmiUsFields
 * */
import { ref, computed, reactive, nextTick, inject, onMounted, onUnmounted } from 'vue';
import services from '@/services';
import { getGlobalConfigs, getPageAlpha2Code, loadGMap, scrollElToTop, settingValue } from '@/utils/site-utils';
import api from '@/api';
import { merge, debounce } from 'lodash';
import { countryNeedDoubleOptin } from '@/services/siteService';
import { formatDate } from '@/utils/date-utils';
import { gtmFormView, gtmFormStart, gtmFormSubmit } from '@/utils/gtm-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { qtUtils } from '@/utils/ali-tracker-utils';
import { scrollToFirstFormError } from '@/utils/dom-utils';
import { sitecoreComponentProps } from '@/utils/constants';
import useAppStore from '@/store/appStore';
import { equalString } from '@/utils/string-utils';

const props = defineProps({
  /**@type KmiUsFields*/
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
const now = formatDate(new Date());
const loading = inject('loading');
const toast = inject('toast');
const overallRef = ref();
const result = ref(null);
const submitted = ref(false);
const submittedFormData = ref(null);
const needDoubleOptin = ref(false);
/**@type Ref<DynamicForm>*/
const formRef = ref();
const formConfig = reactive({
  email: {
    trigger: 'blur'
  }
});
const countryCode = ref(getPageAlpha2Code(props.page));
const zipcode = ref(null);
const selectedDealer = ref(null);
const formChanged = ref(false);
const [gmapKey, contactUsLink] = getGlobalConfigs(props.page, 'gmapKey', 'contactUsLink');
const { formType, leadsName, newsletterName } = props.page.fields;
const layoutFields = computed(() => (submitted.value ? props.fields.successLayout?.fields : props.fields.layout?.fields) ?? props.fields.layout?.fields);
const appStore = useAppStore();
const selectDealerDate = ref(null);
const selectDealerTime = ref(null);
const { itemLanguage } = props.page;

const _qt = {
  trackSubmit(isSucceed, isSubscribed, formData) {
    const alpha2Code = getPageAlpha2Code(props.page);
    const eventParams = {
      is_success: isSucceed,
      is_subscribe: isSubscribed
    };
    if (!isNullOrEmpty(formData)) {
      const { firstName, lastName, email } = formData;
      merge(eventParams, {
        email,
        first_name: firstName,
        last_name: lastName,
        country: alpha2Code
      });
    }
    qtUtils.trackBtnClick(props.page, props.fields.submitButton, eventParams);
  }
};
const onFormChange = async (name, value) => {
  if (name === 'zipCode') {
    zipcode.value = value;
    const gmaps = await loadGMap(props.page);
    if (!gmaps) {
      return;
    }
    const geocoder = new gmaps.Geocoder();
    geocoder.geocode(
      {
        componentRestrictions: {
          postalCode: value,
          country: countryCode.value
        }
      },
      async function(results, status) {
        if (status === gmaps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          const options = await services.agent.getAgentOptions(props.page, countryCode.value, {
            latitude: lat,
            longitude: lng,
            storeType: null
          });
          if (options?.length) {
            const selectedOption = options.find((x) => x.code === selectedDealer.value?.code);
            selectedDealer.value = selectedOption;
            formRef?.value?.updateItem('dealer', {
              controlProps: {
                options,
                selectedOption
              }
            });
          }
        }
      }
    );
  }
  if (name === 'dealer') {
    selectDealerDate.value = formatDate(new Date(), 'dd/mm/yyyy');
    selectDealerTime.value = new Intl.DateTimeFormat(itemLanguage, { timeStyle: 'short' }).format(new Date());
    selectedDealer.value = value;
  }
  if (!formChanged.value) {
    formChanged.value = true;
    gtmFormStart(formType?.value, props.fields.form.id);
  }
};
const onSubmit = debounce(async () => {
  const { channel } = props.fields;
  loading.show();
  const [formValid, formData] = await formRef.value.validate();
  submittedFormData.value = formData;
  if (formValid) {
    const { carSeries, firstName, middleName, lastName, email, mobile, country, dateOfBirth, zipCode, dealer, isAboveTwenty, externalData } = formData;
    const alpha2Code = getPageAlpha2Code(props.page);
    const consentNos = formRef.value.getVerifiedConsents();
    const leadTypes = formRef.value.getLeadTypes();
    needDoubleOptin.value = await countryNeedDoubleOptin(alpha2Code);
    const { vehicleModelOfInterest, ...restExternalData } = externalData || {};
    const body = merge(
      {
        vehicleModelOfInterest: carSeries?.code ?? vehicleModelOfInterest ?? '',
        firstName: firstName,
        middleName: middleName ?? '',
        lastName: lastName,
        email: email,
        phone: `${mobile.area.code} ${mobile.number}`,
        company: '',
        title: '',
        countryRegion: alpha2Code,
        zipCode: zipCode,
        storeCode: dealer.data.storeCode,
        birthday: dateOfBirth,
        needDoubleOptin: false,
        termsAndConditions: consentNos.map((x) => ({
          revisionNumber: x,
          title: window.document.title,
          formUrl: window.location.href,
          effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
        })),
        language: itemLanguage,
        channel: settingValue(channel, 'Official Website'),
        formScId: formData.formScId,
        gRecaptchaToken: formData.gRecaptchaToken ?? null
      },
      restExternalData
    );
    if (!isNullOrEmpty(isAboveTwenty)) {
      merge(body, { isAboveTwenty });
    }
    const [res, ex] = await api.lcms.crm.newsletter.subscribe(null, body);
    loading.hide();
    if (ex) {
      await toast.showEx(ex);
      _qt.trackSubmit(false, formData.checkbox, formData);
      return;
    }
    if (formData?.CPTC) {
      const cptcBody = merge(
        {
          firstName: formData.firstName,
          middleName: formData.middleName ?? '',
          lastName: formData.lastName,
          email: formData.email,
          countryRegion: alpha2Code,
          channel: 'Official Website'
        },
        formData?.externalData
      );
      if (!isNullOrEmpty(formData?.isAboveTwenty)) {
        merge(cptcBody, { isAboveTwenty: formData?.isAboveTwenty });
      }
      const [cptcRes] = await api.lcms.crm.cptc.create(null, cptcBody);

      if (leadTypes?.length && cptcRes) {
        leadTypes.forEach((l) => {
          if (equalString(l.consentType, 'CPTC')) {
            leadsDetails[l.leadType] = cptcRes.leadId ?? null;
          }
        });
      }
    }
    const formDetails = {
      selected_model: carSeries?.code ?? vehicleModelOfInterest ?? null,
      selected_location_name: 'agent',
      selected_location_type: null,
      selected_location_date: null,
      selected_location_time: null,
      customer_type: null,
      dealer_name: dealer?.data?.storeName
    };
    const leadsDetails = {};
    if (leadTypes?.length) {
      leadTypes.forEach((l) => {
        leadsDetails[l.leadType] = res ?? null;
      });
    }
    gtmFormSubmit(
      formType?.value,
      props.fields.form.id,
      {
        email: email,
        mobileAreaCode: mobile.area.code,
        phone: mobile.number
      },
      formDetails,
      leadsDetails
    );

    result.value = res;
    submitted.value = true;
    await nextTick();
    scrollElToTop(overallRef.value?.rootEl);
    _qt.trackSubmit(true, formData.checkbox, formData);
  } else {
    await scrollToFirstFormError();
    loading.hide();
  }
}, 100);

const onscroll = debounce(async () => {
  const rect = formRef?.value?.$el.getBoundingClientRect();
  if (rect.top < window.innerHeight) {
    document.removeEventListener('scroll', onscroll);
    gtmFormView(formType?.value, props.fields.form.id);
  }
}, 100);
onMounted(async () => {
  let options = await services.agent.getAgentOptions(props.page, countryCode.value, {
    storeType: null
  });
  if (options?.length > 0) {
    formRef?.value?.updateItem('dealer', {
      controlProps: {
        options
      }
    });
  }
  document.addEventListener('scroll', onscroll);
});

onUnmounted(() => {
  document.removeEventListener('scroll', onscroll);
});
</script>

<template>
  <overall-settings :fields="fields" ref="overallRef">
    <form-layout :fields="layoutFields" :class="['c-finance-quotation-form__layout', { submitted }]" ref="layoutRef">
      <template #image-content>
        <div class="c-finance-quotation-form__image-content">
          <jss-rich-text class="c-finance-quotation-form__image-title" :field="layoutFields?.title" tag="div" />
        </div>
      </template>
      <div class="c-finance-quotation-form" v-if="!submitted">
        <div>
          <heading class="c-finance-quotation-form__title" :field="fields.title" :type="fields.titleType" font-family="overpass" rich />
          <jss-rich-text class="c-finance-quotation-form__body" :field="fields.body" tag="div" />
          <dynamic-form :form="fields.form" :data="formConfig" @change="onFormChange" ref="formRef">
            <template #dealerOption="scope">
              <div class="c-finance-quotation-form__dealer-option" :class="[{ 'with-mile': !!scope.option.data.metre }]">
                <div class="c-finance-quotation-form__dealer-option-name">{{ scope.option.text }}</div>
                <div class="c-finance-quotation-form__dealer-option-metre" v-if="scope.option.data.metre">
                  {{ Math.floor(scope.option.data.metre / (scope.option.data.metre > 1000 ? 1000 : 1)) }}{{ scope.option.data.metre > 1000 ? 'km' : 'm' }}
                </div>
              </div>
            </template>
          </dynamic-form>
        </div>
        <div class="c-finance-quotation-form__toolbar">
          <site-button class="c-finance-quotation-form__submit-btn" v-bind="fields.submitButton" @click="onSubmit" />
        </div>
        <help-content class="mg-b-80" v-bind="fields.helpContent" />
      </div>
      <div class="c-finance-quotation-form__result" v-if="submitted">
        <heading class="c-finance-quotation-form__result-title" :field="fields.successTitle" rich />
        <div class="c-finance-quotation-form__result-body mg-b-32">
          <icon :field="fields.successIcon" size="tiny" />
          <jss-rich-text class="c-finance-quotation-form__result-text" :field="fields.successBody" tag="div" />
        </div>
        <jss-rich-text class="c-finance-quotation-form__result-subtitle" :field="fields.summaryTitle" tag="div" />
        <div class="c-finance-quotation-form__result-sections">
          <div class="c-finance-quotation-form__result-section">
            <jss-rich-text class="fs-26 fw-500" :field="fields.locationTitle" tag="div" />
            <div class="c-finance-quotation-form__result-store">
              <div class="c-finance-quotation-form__result-store-name">{{ submittedFormData?.dealer.text }}</div>
              <div class="c-finance-quotation-form__result-store-info">
                <div class="c-finance-quotation-form__result-store-item">
                  <icon name="pin" size="tiny" />
                  <span>{{ submittedFormData?.dealer?.data.addressDetail }}</span>
                </div>
                <div class="c-finance-quotation-form__result-store-item" v-if="!$isNullOrEmpty(submittedFormData?.dealer?.data.extra?.salesLine)">
                  <icon name="phone-l" size="tiny" />
                  <span>{{ submittedFormData?.dealer?.data.extra?.salesLine }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="c-finance-quotation-form__result-section">
            <jss-rich-text class="fs-26 fw-500" :field="fields.detailsTitle" tag="div" />
            <div class="c-finance-quotation-form__result-details">
              <p>{{ submittedFormData?.firstName }} {{ submittedFormData?.lastName }}</p>
              <p>{{ submittedFormData?.email }}</p>
              <p>+{{ submittedFormData?.mobile?.area?.code }} {{ submittedFormData?.mobile?.number }}</p>
              <p>{{ submittedFormData?.dateOfBirth }}</p>
            </div>
          </div>
        </div>
        <site-button class="c-finance-quotation-form__result-btn" v-bind="fields.successButton" />
      </div>
    </form-layout>
  </overall-settings>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-finance-quotation-form {
  $c: &;
  $l: '.s-form-layout';
  &__layout {
    #{$l}__side-image {
      display: block;
      color: $white;
    }
  }
  &__image {
    &-content {
      height: 100%;
      padding: grid-width-m(1);
      display: flex;
      align-items: flex-end;
    }
    &-title {
      @include h5;
    }
  }
  &__title {
    font-size: 32px;
    margin-bottom: 16px;
  }
  &__body {
    margin-bottom: 16px;
    color: $grey-next;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $space-40;
  }
  &__return {
    text-transform: uppercase;
    font-weight: bold;
  }
  &__toolbar {
    height: 60px;
    margin-top: $space-40;
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .e-site-button {
      width: 100%;
    }
  }
  &__consent-title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 8px;
  }
  &__result {
    &-body {
      display: flex;
      gap: 16px;
      .e-icon {
        margin-top: 3px;
      }
    }
    &-title {
      @include h5;
      margin-bottom: 48px;
    }
    &-subtitle {
      @include h6;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $grey-89;
    }
    &-btn {
      margin-bottom: 80px;
    }
    &-sections {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 48px;
    }
    &-section {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &-store {
      display: flex;
      flex-direction: column;
      color: $grey-neutral;
      gap: 24px;
      &-name {
        font-size: 18px;
        font-weight: 500;
      }
      &-info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-size: 12px;
      }
      &-item {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    &-details {
      display: flex;
      flex-direction: column;
      gap: 16px;
      color: $grey-neutral;
      font-size: 12px;
    }
    &-btn {
      margin-top: 40px;
    }
  }
  &__dealer-option {
    padding: 0 16px;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: center;
    gap: 24px;
    &-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-metre {
      font-size: 12px;
      color: $grey-neutral;
    }
    &.with-mile {
      justify-content: space-between;
    }
    &:hover {
      font-weight: bold;
    }
  }
  .selected {
    #{$c}__dealer-option {
      &-metre {
        color: $white;
      }
    }
  }
  @include tablet-landscape {
    &__image-content {
      height: 100%;
      padding: grid-width(1);
      display: flex;
      align-items: flex-end;
    }
    &__image-title {
      font-size: 44px;
    }
    &__layout {
      &.submitted.s-form-layout {
        position: static;
        .s-form-layout__side-image {
          position: static;
          height: auto;
        }
      }
    }
    &__toolbar {
      margin-bottom: 80px;
      .e-site-button {
        width: fit-content;
      }
    }
  }
  @include desktop-normal {
    &__image-title {
      font-size: 56px;
    }
  }
}
</style>
