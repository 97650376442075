<template>
  <OverallSettings v-bind="$props">
    <div class="c-icon-content" v-if="fields">
      <div class="c-icon-content__content" :class="contentClasses" v-grid-layout="fields.gridLayout" data-layout-default="2-20">
        <adaptive-image class="c-icon-content__image" :class="imageClasses" :field="fields.adaptiveImage" v-aspect-ratio="ratio" :isBackground="!$isNullOrEmpty(ratio)" v-if="fields.adaptiveImage" />
        <AdaptiveBackImage
          class="c-icon-content__image"
          :class="imageClasses"
          :pcImage="fields.image"
          :mobileImage="fields.mobileImage"
          v-aspect-ratio="ratio"
          :isBackground="!$isNullOrEmpty(ratio)"
          v-else
        />
        <div class="c-icon-content__text-group" :class="[...textGroupClasses]" :style="backgroundStyle">
          <div class="c-icon-content__text-group-icon" :class="`__c-${$settingValue(fields.iconColor, 'black')}`">
            <AnimatedContent type="fade-in-top-left" ease="cubic-bezier(0.055, 0.215, 0.000, 1.000)" :duration="500" :delay="470">
              <Icon :svg="fields.icon?.value.svgCode" />
            </AnimatedContent>
          </div>
          <div class="c-icon-content__text-group-title" v-if="fields.title">
            <AnimatedContent type="skew-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)" :duration="1000" :delay="600">
              <JssRichText :field="fields.title" />
            </AnimatedContent>
          </div>
          <div class="c-icon-content__text-group-body" v-if="fields.body">
            <AnimatedContent type="fade-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)" :duration="1000" :delay="600">
              <JssRichText :field="fields.body" />
            </AnimatedContent>
          </div>
          <div class="c-icon-content__btns" v-if="fields.buttons?.length">
            <AnimatedContent type="bottom-fade-in" v-for="(btn, i) in fields.buttons" :key="btn.id" :delay="600 + 200 * i">
              <SiteButton class="c-icon-content__btn" v-bind="btn" />
            </AnimatedContent>
          </div>
          <div class="c-icon-content__text-group-title" v-if="fields.title2">
            <AnimatedContent type="skew-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)" :duration="1000" :delay="600">
              <JssRichText :field="fields.title2" />
            </AnimatedContent>
          </div>
          <div class="c-icon-content__text-group-body" v-if="fields.body2">
            <AnimatedContent type="fade-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)" :duration="1000" :delay="600">
              <JssRichText :field="fields.body2" />
            </AnimatedContent>
          </div>
          <div class="c-icon-content__btns" v-if="fields.buttons2?.length">
            <AnimatedContent type="bottom-fade-in" v-for="(btn, i) in fields.buttons2" :key="btn.id" :delay="600 + 200 * i">
              <SiteButton class="c-icon-content__btn" v-bind="btn" />
            </AnimatedContent>
          </div>
          <div class="c-icon-content__text-group-des">
            <AnimatedContent type="skew-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)" :duration="1000" :delay="600">
              <JssRichText :field="fields.description" />
            </AnimatedContent>
          </div>
        </div>
      </div>
    </div>
  </OverallSettings>
</template>

<script>
import { computed, inject } from 'vue';
import { getAdaptiveField, settingValue } from '@/utils/site-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
export default {
  name: 'IconContent',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const { deviceState } = inject('device-common');
    const computes = {
      contentClasses: computed(() => [{ reverse: props.fields.reverse.value }]),
      ratio: computed(() => settingValue(getAdaptiveField(deviceState, props.fields.desktopImageRatio, props.fields.mobileImageRatio, props.fields.tabletImageRatio))),
      imageClasses: computed(() => {
        const ratioVal = computes.ratio.value;
        return [
          {
            [`ratio-${ratioVal}`]: !isNullOrEmpty(ratioVal),
            cover: props.fields.cover.value,
            'mobile-image-full': props.fields.mobileImageFull?.value
          }
        ];
      }),
      textGroupClasses: computed(() => [
        `valign-${props.fields.valign?.fields.phrase.value ?? 'middle'}`,
        { reverse: props.fields.reverse?.value, cover: props.fields.cover?.value, background: props.fields.background?.value }
      ]),
      backgroundStyle: computed(() =>
        props.fields.textBackgroundColor
          ? { backgroundColor: `rgba(${methods.colorHex(settingValue(props.fields.textBackgroundColor))}, ${settingValue(props.fields.textBackgroundOpacity, '0.2')})` }
          : { backgroundColor: null }
      )
    };
    const methods = {
      colorHex: (c) => {
        const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
        let sColor = c.toLowerCase();
        if (sColor && reg.test(sColor)) {
          if (sColor.length === 4) {
            let sColorNew = '#';
            for (let i = 1; i < 4; i += 1) {
              sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
            }
            sColor = sColorNew;
          }
          let sColorChange = [];
          for (let i = 1; i < 7; i += 2) {
            sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)));
          }
          return sColorChange.join(',');
        } else {
          return sColor;
        }
      }
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-icon-content {
  &__text-group {
    width: grid-width-m(10);
    margin: 0 auto;
    &.cover {
      position: absolute;
      top: 0;
      width: 100%;
      padding: 0 grid-width-m(1);
    }
    &-icon {
      margin-left: $space-20;
    }
    &-title {
      font-weight: 500;
      font-size: 32px;
      line-height: 32px;
      width: 100%;
      margin: $space-8 auto 0;
    }
    &-body {
      width: 100%;
      margin: $space-16 auto 0;
    }
    &-des {
      margin-top: $space-16;
    }
  }
  &__btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: $space-16 auto 0;
    > .e-animated-content {
      margin-top: $space-10;
    }
  }
  &__image {
    width: grid-width-m(10);
    margin: 0 auto $space-40;

    &.mobile-image-full {
      width: grid-width-m(12);
    }
    &.ratio-1_1 {
      aspect-ratio: 1/1;
    }
    &.ratio-3_2 {
      aspect-ratio: 3/2;
    }
    &.ratio-3_4 {
      aspect-ratio: 3/4;
    }
    &.ratio-4_3 {
      aspect-ratio: 4/3;
    }
    &.ratio-9_16 {
      aspect-ratio: 9/16;
    }
    &.ratio-16_9 {
      aspect-ratio: 16/9;
    }
    &.cover {
      height: 100vh;
      width: grid-width-m(12);
      z-index: -1;
    }
  }
  @include tablet-landscape {
    @include grid-container;
    &__content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: grid-width(2);
      &.reverse {
        flex-direction: row;
      }
    }
    &__text-group {
      display: flex;
      flex-direction: column;
      width: grid-width(6);
      flex-shrink: 0;
      padding: 0;
      margin: 0;
      &.background {
        width: grid-width(6);
        padding: $space-20 0;
      }
      &.cover {
        width: grid-width(9);
        height: 100%;
        padding-left: grid-width(2);
        padding-right: grid-width(1);
        left: 0;
      }
      &.reverse {
        &.background {
          padding: $space-20 0;
        }
        &.cover {
          width: grid-width(9);
          height: 100%;
          padding-left: grid-width(1);
          padding-right: grid-width(2);
          margin: 0;
          left: unset;
          right: 0;
        }
      }
      &.valign {
        &-top {
          justify-content: flex-start;
        }
        &-middle {
          justify-content: center;
        }
        &-bottom {
          justify-content: flex-end;
        }
      }
    }
    &__btns {
      margin: $space-16 0 0;
      flex-direction: row;
      flex-wrap: wrap;
      .e-site-button {
        min-width: grid-width(3);
        width: fit-content;
      }
      > .e-animated-content {
        margin-right: $space-30;
      }
    }
    &__image {
      width: auto;
      flex-grow: 1;
      &.mobile-image-full {
        width: auto;
      }
      margin: 0;
      &.cover {
        height: 100vh;
        width: grid-width(24);
        z-index: -1;
      }
    }
  }
}
</style>
