<template>
  <overall-settings v-bind="$props">
    <image-slider-vertical-mobile v-bind="$props" />
    <image-slider-vertical-pc v-bind="$props" />
  </overall-settings>
</template>

<script>
/**
 * @typedef ImageSliderVerticalFields
 * @property {Array<ImageSliderVerticalSlideFields>} slides
 * @property {Boolean} imageOnLeft
 * */
/**
 * @typedef ImageSliderVerticalSlideFields
 * @property {SimpleField} title
 * @property {GlobalSettingEntry} titleType
 * @property {SimpleField} body
 * @property {ButtonField} button
 * @property {ImageField} image
 * */
import { computed, onMounted, reactive, toRefs } from 'vue';
import { isDesktop } from '@/utils/dom-utils';
import { loadScrollMagic } from '@/utils/site-utils';

export default {
  name: 'ImageSliderVertical',
  props: {
    /**@type ImageSliderVerticalFields*/
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  }
};
</script>

<style lang="scss"></style>
