<template>
  <overall-settings v-bind="$props">
    <div class="c-imageGallerySlider" ref="pageContent" v-if="fields.list && fields.list.length > 0">
      <section class="c-imageGallerySlider__swiper" ref="imageSlider__swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in fields.list" :key="'imageGallerySliderItem' + index">
            <div class="swiper-slide__img">
              <kv-slide-bg :image="item.fields.image" animate="none" />
            </div>
          </div>
        </div>

        <section class="c-imageGallerySlider__content">
          <div class="c-imageGallerySlider__content__main">
            <section class="c-imageGallerySlider__prevNext">
              <span class="c-imageGallerySlider__icon" ref="prevEl" v-show="swiperActiveIndex > 0">
                &lt;
              </span>

              <span class="c-imageGallerySlider__icon" ref="nextEl" v-show="swiperActiveIndex < fields.list.length - 1">
                &gt;
              </span>
            </section>

            <section class="c-imageGallerySlider__pagenation" ref="paginationEl"></section>
          </div>
        </section>
      </section>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { isDesktop, isDesktopLarge, isTabletLandscape } from '@/utils/dom-utils';
import Swiper from 'swiper';

export default {
  name: 'ImageGallerySlider',
  props: {
    /**@type ImageGallerySliderFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const pageContent = ref(null);

    let swiper = null;

    const state = reactive({
      /**@type {HTMLElement}*/
      imageSlider__swiper: null,
      /**@type {HTMLElement}*/
      prevEl: null,
      /**@type {HTMLElement}*/
      nextEl: null,
      /**@type {HTMLElement}*/
      paginationEl: null,
      swiperActiveIndex: 0
    });

    const buildSwiper = () => {
      swiper = new Swiper(state.imageSlider__swiper, {
        slidesPerView: isDesktop() || isDesktopLarge() || isTabletLandscape() ? 1.3 : 1.1,
        spaceBetween: isDesktop() || isDesktopLarge() || isTabletLandscape() ? 68 : 20,
        navigation: {
          prevEl: state.prevEl,
          nextEl: state.nextEl
        },
        pagination: {
          el: state.paginationEl,
          bulletElement: 'div',
          clickable: true,
          renderBullet: function(index, className) {
            return '<span class="' + className + '">' + (index < 9 ? '0' + (index + 1) : index + 1) + '</span>';
          }
        },
        on: {
          slideChange: function() {
            state.swiperActiveIndex = swiper.activeIndex;
          }
        }
      });
    };

    const methods = {
      isDesktop: isDesktop,
      isDesktopLarge: isDesktopLarge,
      isTabletLandscape: isTabletLandscape
    };

    onMounted(() => {
      nextTick(() => {
        buildSwiper();
      });
    });
    onUnmounted(() => {
      swiper.destroy(true, true);
    });

    return {
      pageContent,
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-imageGallerySlider {
  width: 100%;
}
.c-imageGallerySlider__swiper {
  width: 100%;
  overflow: hidden;
}
.c-imageGallerySlider__content {
  position: relative;
  width: 100%;
  @include grid-container;
  z-index: 1;
}
.c-imageGallerySlider__content__main {
  position: relative;
  @include grid-block(2, 10);
}
.c-imageGallerySlider__prevNext {
  position: absolute;
  width: 100%;
  color: #c4c4c4;
  left: 0;
  top: -66vw;
  font-family: simHei;
  overflow: hidden;

  .c-imageGallerySlider__icon {
    display: flex;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    border: 1px solid yellow;
    font-size: 18px;
    opacity: 0.5;
    cursor: pointer;
    float: left;

    &:last-child {
      float: right;
    }
  }
}
.swiper-slide__img {
  position: relative;
  width: 100%;
  height: 133vw;
  overflow: hidden;
}
.c-imageGallerySlider__pagenation {
  position: relative;
  width: 100%;
  padding: 30px 0 0 0;
  text-align: end;

  .swiper-pagination-bullet {
    display: inline-block;
    font-size: 16px;
    opacity: 0.3;
    color: #000;
    padding: 0 10px;
    cursor: pointer;

    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}

@include tablet-landscape {
  .c-imageGallerySlider__prevNext {
    top: -26vw;
    transform: translateY(-50%);

    .c-imageGallerySlider__icon {
      font-size: 20px;
    }
  }
  .c-imageGallerySlider__content__main {
    @include grid-block(3, 20);
  }
  .swiper-slide__img {
    height: 52vw;
  }
}
html.rtl {
  .c-imageGallerySlider {
    &__icon {
      float: right;
      &:last-child {
        float: left;
      }
    }
  }
}
</style>
